import _isNumber from 'lodash-es/isNumber';

type Rgb = TrykApi.Catalog.IRgb;
type Lab = {
  L: number;
  a: number;
  b: number;
};

export function deserializeRgbs(input: string): Rgb[] {
  if (!input || input.length === 0 || input.indexOf('|') === -1) {
    return [];
  }

  const parseTriplet = (str: string): Rgb => {
    if (str.toLowerCase() === 'auto' || str.toLowerCase() === 'x') {
      return { auto: true, r: 0, g: 0, b: 0 };
    }

    const trip = str.split(',')
      .map(x => +x)
      .filter(x => _isNumber(x));
    if (trip.length === 3) {
      return { auto: false, r: trip[0], g: trip[1], b: trip[2] };
    } else {
      return null;
    }
  };

  return input.split('|')
    .map(x => parseTriplet(x))
    .filter(x => x);
}

export function serializeRgbs(input: Rgb[] = []): string {
  return input.map(({ auto, r, g, b }) => auto ? 'x' : `${Math.floor(r)},${Math.floor(g)},${Math.floor(b)}`).join('|');
}

export function computePaletteChange(rgbPal: Rgb[], idx: number, newValue: Rgb): Rgb[] {
  const labPal = rgbPal.map(x => rgbToLab(x));
  const newLab = rgbToLab(newValue);

  const curr_L = labPal[idx].L;
  const delta = curr_L - newLab.L;

  for (let i = 0; i < labPal.length; i++) {
    if (i === idx) {
      continue;
    }
    if (labPal[i].L < curr_L) {
      labPal[i].L = newLab.L - smoothL(delta, labPal[idx].L - labPal[i].L);
    } else {
      labPal[i].L = newLab.L + smoothL(-delta, labPal[i].L - labPal[idx].L);
    }
  }

  labPal[idx] = newLab;

  return labPal.map(x => clampRgb(labToRgb(x)));
}

function smoothL(delta: number, luminance: number) {
  const lambda = 0.2 * Math.log(2);
  return Math.log(Math.exp(lambda * delta) + Math.exp(lambda * luminance) - 1) / lambda - delta;
}

function clampRgb({ auto, r, g, b }: Rgb): Rgb {
  return {
    auto,
    r: Math.min(255, Math.max(r, 0)),
    g: Math.min(255, Math.max(g, 0)),
    b: Math.min(255, Math.max(b, 0))
  };
}

function rgbToLab(input: Rgb): Lab {
  let var_R = input.r / 255;
  let var_G = input.g / 255;
  let var_B = input.b / 255;

  if (var_R > 0.04045) var_R = Math.pow((var_R + 0.055) / 1.055, 2.4);
  else var_R = var_R / 12.92;

  if (var_G > 0.04045) var_G = Math.pow((var_G + 0.055) / 1.055, 2.4);
  else var_G = var_G / 12.92;

  if (var_B > 0.04045) var_B = Math.pow((var_B + 0.055) / 1.055, 2.4);
  else var_B = var_B / 12.92;

  var_R = var_R * 100;
  var_G = var_G * 100;
  var_B = var_B * 100;

  // Observer. = 2°, Illuminant = D65
  const X = var_R * 0.4124 + var_G * 0.3576 + var_B * 0.1805;
  const Y = var_R * 0.2126 + var_G * 0.7152 + var_B * 0.0722;
  const Z = var_R * 0.0193 + var_G * 0.1192 + var_B * 0.9505;

  let var_X = X / 95.047;
  let var_Y = Y / 100;
  let var_Z = Z / 108.883;

  if (var_X > 0.008856) var_X = Math.pow(var_X, 1 / 3);
  else var_X = (7.787 * var_X) + (16 / 116);

  if (var_Y > 0.008856) var_Y = Math.pow(var_Y, 1 / 3);
  else var_Y = (7.787 * var_Y) + (16 / 116);

  if (var_Z > 0.008856) var_Z = Math.pow(var_Z, 1 / 3);
  else var_Z = (7.787 * var_Z) + (16 / 116);

  return {
    L: (116 * var_Y) - 16,
    a: 500 * (var_X - var_Y),
    b: 200 * (var_Y - var_Z)
  };
}

/**
 * Lab to XYZ to rgb from easyRGB.com
 */
function labToRgb(input: Lab): Rgb {
  let var_Y = (input.L + 16) / 116;
  let var_X = input.a / 500 + var_Y;
  let var_Z = var_Y - input.b / 200;

  if (var_Y > 0.206893034422) var_Y = Math.pow(var_Y, 3);
  else var_Y = (var_Y - 16 / 116) / 7.787;
  if (var_X > 0.206893034422) var_X = Math.pow(var_X, 3);
  else var_X = (var_X - 16 / 116) / 7.787;
  if (var_Z > 0.206893034422) var_Z = Math.pow(var_Z, 3);
  else var_Z = (var_Z - 16 / 116) / 7.787;

  const X = 95.047 * var_X;
  const Y = 100 * var_Y;
  const Z = 108.883 * var_Z;

  var_X = X / 100;
  var_Y = Y / 100;
  var_Z = Z / 100;

  let var_R = var_X * 3.2406 + var_Y * -1.5372 + var_Z * -0.4986;
  let var_G = var_X * -0.9689 + var_Y * 1.8758 + var_Z * 0.0415;
  let var_B = var_X * 0.0557 + var_Y * -0.2040 + var_Z * 1.0570;

  if (var_R > 0.0031308) var_R = 1.055 * Math.pow(var_R, 1 / 2.4) - 0.055;
  else var_R = 12.92 * var_R;
  if (var_G > 0.0031308) var_G = 1.055 * Math.pow(var_G, 1 / 2.4) - 0.055;
  else var_G = 12.92 * var_G;
  if (var_B > 0.0031308) var_B = 1.055 * Math.pow(var_B, 1 / 2.4) - 0.055;
  else var_B = 12.92 * var_B;

  return {
    auto: false,
    r: Math.floor(var_R * 255),
    g: Math.floor(var_G * 255),
    b: Math.floor(var_B * 255)
  };
}
