import { createSelector } from 'reselect';

import { Urls as ImageUrls } from 'Clients/Images';
import { Urls as StudioUrls } from 'Clients/Studio';

import RootState from 'Store/Root';
import { getValidRugRooms } from 'Store/Rooms/Utils';

import { ThumbnailOptionItem } from 'Types/Common';
import { QueryArgs } from 'Types/Rugs';

export const roomOptionsSelector = createSelector(
  (state: RootState, query: QueryArgs) => getValidRugRooms(state.rooms),
  (state: RootState, query: QueryArgs) => query,
  (items, query) => {
    if (!items || items.length === 0) {
      return [];
    }

    return items.map(x => mapRoomToOptionItem(x, query));
  }
);

export const roomIdSelector = createSelector(
  (state: RootState, query: QueryArgs) => getValidRugRooms(state.rooms),
  (state: RootState, query: QueryArgs) => query,
  (items, query): number => {
    if (!items || items.length === 0) {
      return -1;
    }

    const room = items.find(x => x.roomId === query.roomId) ||
      items.find(x => x.isDefault) || items[0];

    if (room) {
      return room.roomId;
    } else {
      return -1;
    }
  }
);

function mapRoomToOptionItem(room: TrykApi.Catalog.IRoomScene, query: QueryArgs): ThumbnailOptionItem {
  return {
    id: room.roomId,
    label: room.name,
    imageUrl: ImageUrls.Thumbnail.room(room.roomId),
    isActive: query.roomId === room.roomId,
    href: StudioUrls.rug({
      ...query,
      roomId: room.roomId,
      viewMode: '3d'
    })
  };
}
