import {
  LOADED_COLLECTIONS,
  LOADING_COLLECTIONS,
  LOAD_COLLECTIONS_FAILED,
  CollectionsAction
} from './Actions';

export interface CollectionsState {
  items: TrykApi.Catalog.ICollection[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: CollectionsState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false
};

export function collectionsReducer(state = initialState, action: CollectionsAction): CollectionsState {
  switch (action.type) {
    case LOADED_COLLECTIONS:
      return {
        items: action.collections.concat(),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_COLLECTIONS:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_COLLECTIONS_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
