import _keyBy from 'lodash-es/keyBy';

import { ThunkAction } from 'redux-thunk';

import Config from 'Config';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_NEW_DESIGNS = 'LOADING_NEW_DESIGNS';
export const LOADED_NEW_DESIGNS = 'LOADED_NEW_DESIGNS';
export const LOAD_NEW_DESIGNS_FAILED = 'LOAD_NEW_DESIGNS_FAILED';

export interface NewDesignsAction {
  type: string;
  items?: { [id: number]: TrykApi.Catalog.ISearchResult };
}

function loading(): NewDesignsAction {
  return {
    type: LOADING_NEW_DESIGNS
  };
}

function loadFailed(): NewDesignsAction {
  return {
    type: LOAD_NEW_DESIGNS_FAILED
  };
}

function loaded(items: TrykApi.Catalog.ISearchResult[]): NewDesignsAction {
  return {
    type: LOADED_NEW_DESIGNS,
    items: _keyBy(items, x => x.designId)
  };
}

export function loadNewDesigns(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.newDesigns.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    if (Config.studio.newCategoryId > 0) {
      return CatalogClient.Product.getSearchPage({
        categoryIds: [Config.studio.newCategoryId],
        page: 0,
        pageSize: 1000
      }).then(
        x => dispatch(loaded(x.items)),
        e => dispatch(loadFailed())
        );
    } else {
      dispatch(loaded([]));
      return Promise.resolve([]);
    }
  };
}
