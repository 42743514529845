import _flatMap from 'lodash-es/flatMap';
import _groupBy from 'lodash-es/groupBy';
import _mapKeys from 'lodash-es/mapKeys';
import _mapValues from 'lodash-es/mapValues';
import _uniqBy from 'lodash-es/uniqBy';

import { SectionMap } from 'Types/Rooms';

import {
  LOADED_ROOMS,
  LOADING_ROOMS,
  LOAD_ROOMS_FAILED,
  RoomsAction
} from './Actions';

export interface RoomsState {
  itemsById: _.Dictionary<TrykApi.Catalog.IRoomScene>;
  brandDefaultIdMap: _.Dictionary<number>;
  brandMap: _.Dictionary<number[]>;
  sectionMap: SectionMap;
  categories: TrykApi.Catalog.IRoomSceneCategory[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: RoomsState = {
  itemsById: {},
  brandDefaultIdMap: {},
  brandMap: {},
  sectionMap: {},
  categories: null,
  loading: false,
  loaded: false,
  failed: false
};

function roomsReducer(state = initialState, action: RoomsAction): RoomsState {
  switch (action.type) {
    case LOADED_ROOMS:
      return {
        itemsById: _mapKeys(
          _uniqBy(
            _flatMap(action.items, x => x.roomScenes),
            x => x.roomId
          ),
          x => x.roomId
        ),
        brandDefaultIdMap: _mapValues(
          _groupBy(action.items, 'brandId'),
          grp => _flatMap(grp, x => x.roomScenes.map(y => y)).filter(x => x.isDefault).map(x => x.roomId)[0] ||
            (grp[0].roomScenes[0] || { roomId: 0 }).roomId
        ),
        brandMap: _mapValues(
          _groupBy(action.items, 'brandId'),
          grp => _flatMap(grp, x => x.roomScenes.map(y => y.roomId))
        ),
        sectionMap: action.sectionMap,
        loaded: true,
        loading: false,
        failed: false,
        categories: action.categories
      };
    case LOADING_ROOMS:
      return {
        itemsById: {},
        brandDefaultIdMap: {},
        brandMap: {},
        sectionMap: {},
        categories: null,
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_ROOMS_FAILED:
      return {
        itemsById: {},
        brandDefaultIdMap: {},
        brandMap: {},
        sectionMap: {},
        categories: null,
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}

export default roomsReducer;
