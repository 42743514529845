import _flatten from 'lodash-es/flatten';
import _get from 'lodash-es/get';
import _keyBy from 'lodash-es/keyBy';
import _groupBy from 'lodash-es/groupBy';
import _mapValues from 'lodash-es/mapValues';
import _omit from 'lodash-es/omit';

import {
  ADDED_PROJECT,
  ADDING_PROJECT_USER,
  ADDED_PROJECT_USER,
  ADD_PROJECT_USER_FAILED,
  ADDED_FOLDER,
  CLEAR_PROJECTS,
  DELETED_FOLDER,
  DELETED_PROJECT,
  DELETING_PROJECT_USER,
  DELETED_PROJECT_USER,
  DELETE_PROJECT_USER_FAILED,
  LOADED_PROJECTS,
  LOADING_PROJECTS,
  LOAD_PROJECTS_FAILED,
  INCREMENT_ITEM_COUNT,
  DECREMENT_ITEM_COUNT,
  ProjectsAction
} from './Actions';

export type FolderStateItem = TrykApi.Catalog.Favorites.IFolder;
export type FolderDictionary = _.Dictionary<FolderStateItem>;

export type ProjectStateItem = {
  projectId: number;
  name: string;
  client: string;
  yardage: number;
  description: string;
  private: boolean;

  createdBy: TrykApi.Catalog.IAccount;
  users: TrykApi.Catalog.IAccount[];
  addingUser: boolean;
  addUserFailed: boolean;
  deletingUser: boolean;
  deleteUserFailed: boolean;

  dateCreated: Date;
  dateModified: Date;
};
export type ProjectDictionary = _.Dictionary<ProjectStateItem>;
export type ProjectFoldersMap = _.Dictionary<number[]>;

export interface ProjectsState {
  foldersById: FolderDictionary;
  projectsById: ProjectDictionary;
  projectFoldersMap: ProjectFoldersMap;
  projectsList: number[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: ProjectsState = {
  foldersById: {},
  projectsById: {},
  projectFoldersMap: {},
  projectsList: [],
  loading: false,
  loaded: false,
  failed: false
};

export default function projectsReducer(state = initialState, action: ProjectsAction): ProjectsState {
  switch (action.type) {
    case CLEAR_PROJECTS:
      return {
        ...initialState
      };
    case LOADED_PROJECTS:
      return {
        ...initialState,
        foldersById: _keyBy(
          _flatten(action.projects.map(x => x.folders)),
          x => x.folderId
        ),
        projectsById: _keyBy(
          action.projects.map(x => mapProject(x)),
          x => x.projectId
        ),
        projectFoldersMap: _mapValues(
          _groupBy(_flatten(action.projects.map(x => x.folders)), 'projectId'),
          grp => grp.map(x => x.folderId)
        ),
        projectsList: action.projects.map(x => x.projectId),
        loaded: true
      };
    case LOADING_PROJECTS:
      return {
        ...initialState,
        loading: true
      };
    case LOAD_PROJECTS_FAILED:
      return {
        ...initialState,
        failed: true
      };
    case ADDED_PROJECT:
      return {
        ...state,
        projectsList: [action.projectId].concat(
          state.projectsList
        ),
        projectsById: {
          ...state.projectsById,
          [action.projectId]: action.project
        },
        projectFoldersMap: {
          ...state.projectFoldersMap,
          [action.projectId]: action.project.folders.map(f => f.folderId)
        },
        foldersById: {
          ...state.foldersById,
          ..._keyBy(action.project.folders, f => f.folderId)
        }
      };
    case DELETED_PROJECT:
      return {
        ...state,
        projectsById: _omit(state.projectsById, action.projectId),
        projectFoldersMap: _omit(state.projectFoldersMap, action.projectId),
        projectsList: state.projectsList.filter(x => x !== action.projectId)
      };
    case ADDED_FOLDER:
      return {
        ...state,
        foldersById: {
          ...state.foldersById,
          [action.folderId]: action.folder
        },
        projectFoldersMap: {
          ...state.projectFoldersMap,
          [action.projectId]: (state.projectFoldersMap[action.projectId] || []).concat(
            [action.folderId]
          )
        }
      };
    case DELETED_FOLDER:
      return {
        ...state,
        foldersById: _omit(state.foldersById, action.folderId),
        projectFoldersMap: {
          ...state.projectFoldersMap,
          [action.projectId]: (state.projectFoldersMap[action.projectId] || []).filter(
            x => x !== action.folderId
          )
        }
      };
    case DECREMENT_ITEM_COUNT:
      return {
        ...state,
        foldersById: {
          ...state.foldersById,
          [action.folderId]: {
            ...state.foldersById[action.folderId],
            itemCount: (
              (_get(state.foldersById, `${action.folderId}.itemCount`, 1) as number) - 1
            )
          }
        }
      };
    case INCREMENT_ITEM_COUNT:
      return {
        ...state,
        foldersById: {
          ...state.foldersById,
          [action.folderId]: {
            ...state.foldersById[action.folderId],
            itemCount: (
              (_get(state.foldersById, `${action.folderId}.itemCount`, 0) as number) + 1
            )
          }
        }
      };
    case ADDED_PROJECT_USER:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            users: action.project.users,
            addingUser: false,
            addUserFailed: false
          }
        }
      };
    case ADDING_PROJECT_USER:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            addingUser: true,
            addUserFailed: false
          }
        }
      };
    case ADD_PROJECT_USER_FAILED:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            addingUser: false,
            addUserFailed: true
          }
        }
      };
    case DELETED_PROJECT_USER:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            users: action.project.users,
            deletingUser: false,
            deleteUserFailed: false
          }
        }
      };
    case DELETING_PROJECT_USER:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            deletingUser: true,
            deleteUserFailed: false
          }
        }
      };
    case DELETE_PROJECT_USER_FAILED:
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.projectId]: {
            ...state.projectsById[action.projectId],
            deletingUser: false,
            deleteUserFailed: true
          }
        }
      };
    default:
      return state;
  }
}

function mapProject(project: TrykApi.Catalog.Favorites.IProject): ProjectStateItem {
  return {
    projectId: project.projectId,
    name: project.name,
    client: project.client,
    yardage: project.yardage,
    description: project.description,
    private: project.private,
    createdBy: project.createdBy,
    users: project.users,
    addingUser: false,
    addUserFailed: false,
    deletingUser: false,
    deleteUserFailed: false,
    dateCreated: project.dateCreated,
    dateModified: project.dateModified
  };
}
