import _sortBy from 'lodash-es/sortBy';

export function mapAndSortWithTileSize(installs: TrykApi.Catalog.IDesignInstall[]): TrykApi.Catalog.IDesignInstall[] {
  const sorted = sortInstallsByArea(installs);

  return sorted.map(x => ({
    ...x,
    name: getNameWithTileSize(x)
  }));
}

export function sortInstallsByArea(installs: TrykApi.Catalog.IDesignInstall[]): TrykApi.Catalog.IDesignInstall[] {
  const byName = _sortBy(installs, 'name');

  return _sortBy(byName,
    x => x.tileSizes.map(
      y => y.widthInches * y.heightInches
    ).reduce((a, b) => a + b, 0)
  );
}

export function getNameWithTileSize(item: TrykApi.Catalog.IDesignInstall): string {


  return `${item.displayTileSize} ${item.name}`;
}
