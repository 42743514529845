import { createSelector } from 'reselect';

import { overviewSelector } from './PatternInfo';

export const titleSelector = createSelector(
  overviewSelector,
  (overview): string => {
    if (!overview) {
      return '';
    }

    const designName = (overview.name || '').trim();
    const designCode = (overview.code || '').trim();

    if (designName && designName !== designCode) {
      return designName;
    } else {
      return designCode;
    }
  }
);
