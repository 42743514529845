import cloneDeep from 'lodash-es/cloneDeep';

import {
  REGISTERING,
  REGISTRATION_FAILED
} from '../Actions/Register';

import { UserAction } from '../Actions';

import UserState, { initialState as initialUser } from '../State';

export default function register(state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case REGISTERING:
      return {
        account: { ...initialUser.account },
        orders: cloneDeep(initialUser.orders),
        password: { ...initialUser.password },
        shippingHistory: { ...initialUser.shippingHistory },
        status: {
          loggedIn: false,
          isRegistering: true,
          isLoggingIn: false,
          isLoggingOut: false,
          didRegisterError: false,
          didLoginError: false,
          didLogoutError: false
        },
        tryks: cloneDeep(initialUser.tryks)
      };
    case REGISTRATION_FAILED:
      return {
        account: { ...initialUser.account },
        orders: cloneDeep(initialUser.orders),
        password: { ...initialUser.password },
        shippingHistory: { ...initialUser.shippingHistory },
        status: {
          loggedIn: false,
          isRegistering: false,
          isLoggingIn: false,
          isLoggingOut: false,
          didRegisterError: true,
          didLoginError: false,
          didLogoutError: false
        },
        tryks: cloneDeep(initialUser.tryks)
      };
    default:
      return state;
  }
}
