import cloneDeep from 'lodash-es/cloneDeep';

import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_FAILED
} from '../Actions/Login';

import {
  LOADING_ACCOUNT,
  LOAD_ACCOUNT_FAILED,
  LOADED_ACCOUNT
} from '../Actions/Account';

import { UserAction } from '../Actions';

import UserState, { initialState as initialUser } from '../State';

export default function login(state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case LOADING_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.account,
          data: state.account.data,
          loading: true
        }
      };
    case LOGGING_IN:
      return {
        account: { ...initialUser.account },
        orders: cloneDeep(initialUser.orders),
        password: { ...initialUser.password },
        shippingHistory: { ...initialUser.shippingHistory },
        status: {
          loggedIn: false,
          isRegistering: false,
          isLoggingIn: true,
          isLoggingOut: false,
          didRegisterError: false,
          didLoginError: false,
          didLogoutError: false
        },
        tryks: cloneDeep(initialUser.tryks)
      };
    case LOADED_ACCOUNT:
    case LOGIN_SUCCESS:
      return {
        account: {
          data: action.account,
          loading: false,
          loadFailed: false,
          updating: false,
          updateFailed: false,
          didUpdate: false
        },
        orders: cloneDeep(initialUser.orders),
        password: { ...initialUser.password },
        shippingHistory: { ...initialUser.shippingHistory },
        status: {
          loggedIn: true,
          isRegistering: false,
          isLoggingIn: false,
          isLoggingOut: false,
          didRegisterError: false,
          didLoginError: false,
          didLogoutError: false
        },
        tryks: cloneDeep(initialUser.tryks)
      };
    case LOAD_ACCOUNT_FAILED:
    case LOGIN_FAILED:
      return {
        account: {
          ...initialUser.account,
          loadFailed: true
        },
        orders: cloneDeep(initialUser.orders),
        password: { ...initialUser.password },
        shippingHistory: { ...initialUser.shippingHistory },
        status: {
          loggedIn: false,
          isRegistering: false,
          isLoggingIn: false,
          isLoggingOut: false,
          didRegisterError: false,
          didLoginError: true,
          didLogoutError: false
        },
        tryks: cloneDeep(initialUser.tryks)
      };
    default:
      return state;
  }
}
