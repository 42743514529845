import {
  LOADING_TRYK,
  LOADED_TRYK,
  LOAD_TRYK_FAILED
} from '../../Actions/Tryks';

import { TrykDetailPayload } from '../../Actions';
import {
  DesignsByTrykMap,
  DestinationsByTrykMap,
  TryksState,
  TrykMap,
  FlagMap
} from '../../State';

export default function tryk(state: TryksState, type: string, payload: TrykDetailPayload): TryksState {
  switch (type) {
    case LOADING_TRYK:
    case LOADED_TRYK:
    case LOAD_TRYK_FAILED:
      return {
        ...state,
        itemsById: getTrykMap(state.itemsById, type, payload),
        designsById: getDesignsByTrykMap(state.designsById, type, payload),
        destinationsById: getDestinationsByTrykMap(state.destinationsById, type, payload),
        loadingById: getLoadingMap(state.loadingById, type, payload),
        errorsById: getErrorMap(state.errorsById, type, payload)
      };
    default:
      return state;
  }
}

function getTrykMap(map: TrykMap, type: string, payload: TrykDetailPayload): TrykMap {
  if (!payload.tryk) {
    return map;
  }

  return {
    ...map,
    [payload.tryk.trykId.toString()]: payload.tryk
  };
}

function getDesignsByTrykMap(map: DesignsByTrykMap, type: string, payload: TrykDetailPayload): DesignsByTrykMap {
  if (!payload.designs) {
    return map;
  }

  return {
    ...map,
    [payload.trykId.toString()]: payload.designs
  };
}

function getDestinationsByTrykMap(map: DestinationsByTrykMap, type: string, payload: TrykDetailPayload): DestinationsByTrykMap {
  if (!payload.destinations) {
    return map;
  }

  return {
    ...map,
    [payload.trykId.toString()]: payload.destinations
  };
}

function getLoadingMap(map: FlagMap, type: string, payload: TrykDetailPayload): FlagMap {
  return {
    ...map,
    [payload.trykId.toString()]: type === LOADING_TRYK
  };
}

function getErrorMap(map: FlagMap, type: string, payload: TrykDetailPayload): FlagMap {
  return {
    ...map,
    [payload.trykId.toString()]: type === LOAD_TRYK_FAILED
  };
}
