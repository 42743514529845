export namespace Engines {
  export enum Id {
    Tufted = 1,
    Karaloc,
    Wilton,
    Manual,
    Cyp,
    Photography,
    Print = 9,
    Recolorable,
    Axminster
  }

  export function getName(engineId: number): string {
    switch (engineId) {
      case Id.Tufted:
        return 'Tufted';
      case Id.Karaloc:
        return 'Karaloc';
      case Id.Wilton:
        return 'Wilton';
      case Id.Manual:
        return 'Manual';
      case Id.Cyp:
        return 'CYP';
      case Id.Photography:
        return 'Photography';
      case Id.Print:
        return 'Print';
      case Id.Recolorable:
        return 'Recol';
      case Id.Axminster:
        return 'Axminster';
      default:
        return 'Unknown';
    }
  }

  export function isValidRug(engineId: number): boolean {
    const VALID_ENGINES = [
      Id.Cyp,
      Id.Print
    ];

    return VALID_ENGINES.indexOf(engineId) !== -1;
  }
}

export namespace Sections {
  export enum Id {
    Fill = 1,
    Infill,
    Outfill,
    Corner,
    Border,
    Runner,
    Rug,
    Medallion,
    Crown,
    Floor
  }

  export function getName(sectionId: number): string {
    switch (sectionId) {
      case Id.Fill:
      case Id.Outfill:
        return 'Fill';
      case Id.Infill:
        return 'Field';
      case Id.Corner:
        return 'Corner';
      case Id.Border:
        return 'Border';
      case Id.Runner:
        return 'Runner';
      case Id.Rug:
        return 'Rug';
      case Id.Medallion:
        return 'Medallion';
      case Id.Crown:
        return 'Crown';
      case Id.Floor:
        return 'Floor';
      default:
        return 'Unknown';
    }
  }

  export function isValidRugComponent(sections: number | number[]): boolean {
    const INVALID_SECTIONS = [
      Id.Runner,
      Id.Rug
    ];

    if (!Array.isArray(sections)) {
      sections = [sections];
    }

    return sections.length === 0 || sections.every(id => INVALID_SECTIONS.indexOf(id) === -1);
  }

  export function filterByValidRugComponents(sections: number[]): number[] {
    const INVALID_SECTIONS = [
      Id.Runner,
      Id.Rug,
      Id.Fill,
      Id.Floor
    ];

    return sections.filter(id => INVALID_SECTIONS.indexOf(id) === -1);
  }
}
