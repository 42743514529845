import {CustomColorwayArgs} from '../Actions';

export function getColorwayMapKey(designCode: string): string {
  if (designCode)
    return designCode.toUpperCase();
}

export function getCustomColorwayMapKey({
  refColor,
  rgbs,
  yarns,
  clutColorwayId
}: CustomColorwayArgs): string {
  const itemsKey = (): string => {
    if (clutColorwayId && clutColorwayId !== '') {
      return `CLUT${clutColorwayId}`;
    } else if (yarns && yarns.length > 0) {
      return yarns.toUpperCase();
    } else if (rgbs && rgbs.length > 0) {
      return `${(rgbs || '').toUpperCase()}`;
    } else {
      throw new Error('No yarns or rgbs provided. Cannot create store key.');
    }
  };

  if (refColor) {
    return `[${refColor}]:${itemsKey()}`;
  } else {
    return itemsKey();
  }
}
