import { createSelector } from 'reselect';

import { Urls as ImageUrls } from 'Clients/Images';
import { Urls as StudioUrls } from 'Clients/Studio';

import { ThumbnailOptionItem } from 'Types/Common';
import { Params } from 'Types/Designs';

import fixedColorwaysSelector from '../FixedColorways';
import paramsSelector from '../Params';

const mapColorwayToOptionItem = (colorway: TrykApi.Catalog.IColorway, params: Params): ThumbnailOptionItem => {
  return {
    id: colorway.colorwayId,
    label: colorway.colorName,
    imageUrl: ImageUrls.Thumbnail.colorway(colorway.designCode, colorway.colorCode),
    href: StudioUrls.designDetail({
      designCode: colorway.designCode,
      colorCode: colorway.colorCode,
      roomId: params.room && params.room.roomId,
      installId: params.install && params.install.installId || 0,
      textureId: params.texture && params.texture.textureId || 0,
      viewMode: params.viewMode,
      customRug: params.customRug,
      rotation: params.rotation,
      position: params.position
    }),
    isActive: params && params.colorway &&
      params.colorway.colorwayId === colorway.colorwayId
  };
};

const colorwayOptionsSelector = createSelector(
  fixedColorwaysSelector,
  paramsSelector,
  (colorways, params) =>
    colorways.map(x => mapColorwayToOptionItem(x, params))
);

export default colorwayOptionsSelector;
