import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';
import { Data as StudioData } from 'Clients/Studio';

import RootState from 'Store/Root';
import {
  EngineLibrariesMap,
  EnginePalettesMap
} from 'Types/Yarns';

export const LOADING_YARN_LIBRARIES = 'LOADING_YARN_LIBRARIES';
export const LOADED_YARN_LIBRARIES = 'LOADED_YARN_LIBRARIES';
export const LOAD_YARN_LIBRARIES_FAILED = 'LOAD_YARN_LIBRARIES_FAILED';

export interface YarnLibrariesAction {
  type: string;
  items?: TrykApi.Yarns.IYarnLibrary[];
  libraryMap?: EngineLibrariesMap;
  paletteMap?: EnginePalettesMap;
}

function loadingYarnLibraries(): YarnLibrariesAction {
  return {
    type: LOADING_YARN_LIBRARIES
  };
}

function yarnLibrariesLoadFailed(): YarnLibrariesAction {
  return {
    type: LOAD_YARN_LIBRARIES_FAILED
  };
}

function yarnLibrariesLoaded(items: TrykApi.Yarns.IYarnLibrary[], libraryMap: EngineLibrariesMap, paletteMap: EnginePalettesMap): YarnLibrariesAction {
  return {
    type: LOADED_YARN_LIBRARIES,
    items: items,
    libraryMap,
    paletteMap
  };
}

export function loadYarnLibraries(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.yarnLibraries.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loadingYarnLibraries());

    return Promise.all([
      CatalogClient.Global.getYarnLibraries(),
      StudioData.getEngineLibrariesMap(),
      StudioData.getEnginePalettesMap()
    ]).then(
      x => dispatch(yarnLibrariesLoaded(x[0], x[1], x[2])),
      e => dispatch(yarnLibrariesLoadFailed())
    );
  };
}
