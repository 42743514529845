import React from 'react';

declare const TENANT: string;

const SiteLayout = require(`./${TENANT}`).default as React.ComponentClass<{}>;

const TenantLayout: React.SFC<{}> = (props) => {
  return (
    <SiteLayout>
      {props.children}
    </SiteLayout>
  );
};

export default TenantLayout;
