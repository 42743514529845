interface RecentlyUsedDesignsState {
  items: TrykApi.Catalog.ISearchResult[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

export default RecentlyUsedDesignsState;

export const initialState: RecentlyUsedDesignsState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false,
};
