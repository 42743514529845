import _flow from 'lodash-es/flow';
import _partialRight from 'lodash-es/partialRight';

import { UserAction } from '../Actions';

import UserState, { initialState } from '../State';

import contact from './Account/Contact';
import login from './Login';
import logout from './Logout';
import orders from './Orders';
import password from './Password';
import register from './Register';
import shippingHistory from './ShippingHistory';
import tryks from './Tryks';

type ReducerFn = (s: UserState, a: UserAction) => UserState;

const reducers: ReducerFn[] = [
  contact,
  login,
  logout,
  orders,
  password,
  register,
  shippingHistory,
  tryks
];

export default function userReducer(state = initialState, action: UserAction): UserState {
  const partials = reducers.map(x => _partialRight(x, action));

  return _flow(...partials)(state);
}
