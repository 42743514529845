import * as CatalogClient from '@shawfloors/catalog-client';

import * as Storage from 'Utils/Storage';

import { CACHE_KEY_PREFIX, CACHE_KEY_SEP } from './CacheKey';

export function clearWebStorage() {
  const keys = Storage.getKeys().filter(x => {
    return x.split(CACHE_KEY_SEP)[0] === CACHE_KEY_PREFIX;
  });
  keys.forEach(x => Storage.removeItem(x));
}

export async function resetServerCache(): Promise<boolean> {
  const result = await CatalogClient.Util.resetCache();
  if (result) {
    clearWebStorage();
  }
  return result;
}
