import omit from 'lodash-es/omit';

import {
  ADD_COMMENT_FAILED,
  ADDING_COMMENT,
  ADDED_COMMENT,
  CLEAR_COMMENTS,
  LOADED_COMMENTS,
  LOADING_COMMENTS,
  LOAD_COMMENTS_FAILED,
  ProjectItemCommentsAction
} from './Actions';

type FlagLookup = _.Dictionary<boolean>;

export interface ProjectItemCommentsState {
  commentsById: _.Dictionary<TrykApi.Catalog.Favorites.IComment[]>;
  addingById: FlagLookup;
  addFailedById: FlagLookup;
  loadingById: FlagLookup;
  loadFailedById: FlagLookup;
}

const initialState: ProjectItemCommentsState = {
  commentsById: {},
  addingById: {},
  addFailedById: {},
  loadingById: {},
  loadFailedById: {}
};

export default function projectItemCommentsReducer(state = initialState, action: ProjectItemCommentsAction): ProjectItemCommentsState {
  switch (action.type) {
    case CLEAR_COMMENTS:
      return {
        ...initialState
      };
    case ADDING_COMMENT:
      return {
        ...state,
        addingById: {
          ...state.addingById,
          [action.itemId]: true
        }
      };
    case ADD_COMMENT_FAILED:
      return {
        ...state,
        addingById: omit(state.addingById, action.itemId),
        addFailedById: {
          ...state.addFailedById,
          [action.itemId]: true
        }
      };
    case ADDED_COMMENT:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.itemId]: (state.commentsById[action.itemId] || [])
            .concat([action.comment])
        },
        addingById: omit(state.addingById, action.itemId),
        addFailedById: omit(state.addFailedById, action.itemId)
      };
    case LOADED_COMMENTS:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.itemId]: action.comments
        },
        loadingById: omit(state.loadingById, action.itemId),
        loadFailedById: omit(state.loadFailedById, action.itemId)
      };
    case LOADING_COMMENTS:
      return {
        ...state,
        loadingById: {
          ...state.loadingById,
          [action.itemId]: true
        }
      };
    case LOAD_COMMENTS_FAILED:
      return {
        ...state,
        loadFailedById: {
          ...state.loadFailedById,
          [action.itemId]: true
        }
      };
    default:
      return state;
  }
}
