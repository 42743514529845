import _isObject from 'lodash-es/isObject';

export function pushAnalytics(eventName: string, payload?: Object): void {
  let analytics;
  if (_isObject(payload)) {
    analytics = {
      event: eventName,
      ...payload
    };
  }
  else {
    analytics = {event: eventName};
  }

  ((window as any).dataLayer || []).push(analytics);
}
