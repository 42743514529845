import _uniq from 'lodash-es/uniq';

import { createSelector } from 'reselect';

import { loadingSelector } from './Loading';
import { overviewsSelector } from './Overviews';

export const engineIdSelector = createSelector(
  loadingSelector,
  overviewsSelector,
  (status, overviews): number => {
    if (!status.loaded || overviews.length === 0) {
      return -1;
    }

    const engineIds = _uniq(
      overviews.map(x => x.engineId)
    );

    if (engineIds.length === 0) {
      console.log('Error: Unexpected number of engineIds encountered', engineIds);
      return -1;
    }

    return engineIds[0];
  }
);
