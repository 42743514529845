import { createSelector } from "reselect";

import RootState from "Store/Root";
import { getValidRooms } from "Store/Rooms/Utils";

import { Params } from "Types/Designs";

import { Props } from "./Props";

import colorwaySelector from "./Colorway";
import customRugSelector, { customRugEnabledSelector } from './CustomRugs';
import designInstallsSelector from "./DesignInstalls";
import textureSelector from "./Texture";
import viewModeSelector from "./ViewMode";

const installSelector = createSelector(
  designInstallsSelector,
  (state: RootState, props: Props) => props.installId,
  (designInstalls, installId): TrykApi.Catalog.IDesignInstall => {
    if (designInstalls.length === 0) {
      return null;
    }
    const install = designInstalls.find(x => x.installId === installId);
    return (
      install || designInstalls.find(x => x.isDefault) || designInstalls[0]
    );
  }
);

const roomSelector = (
  state: RootState,
  props: Props
): TrykApi.Catalog.IRoomScene => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return null;
  }

  const overview = designState.overview;
  const validRooms = getValidRooms(
    state.rooms,
    overview.brandId,
    overview.sectionIds,
    props.rugWidth > 0 && props.rugHeight > 0,
    undefined // TODO: is this correct behavior?
  );

  if (validRooms.length === 0) {
    return null;
  }

  const room = validRooms.find(x => x.roomId === props.roomId);

  return room || validRooms.find(x => x.isDefault) || validRooms[0];
};

const clutColorwaySelector = (state: RootState, props: Props): string => {
  if (props.clutColorwayId && props.clutColorwayId !== '')
    return props.clutColorwayId;

  return null;
};

const mutedIndicesSelector = (state: RootState, props: Props): number[] => {
  if (props.mutedIndices && props.mutedIndices.length > 0) {
    return props.mutedIndices;
  }

  return [];
};

const rotationSelector = (state: RootState, props: Props): number => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return 0;
  } else if (!designState.overview.sectionIds.some(x => x === 7) && (props.rugWidth <= 0 || props.rugHeight <= 0)) {
    return 0;
  }

  return props.rotation || 0;
}

const sectionIdsSelector = (state: RootState, props: Props): number[] => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return [];
  }

  return designState.overview.sectionIds;
}

const paramsSelector = createSelector(
  colorwaySelector,
  clutColorwaySelector,
  customRugEnabledSelector,
  customRugSelector,
  mutedIndicesSelector,
  installSelector,
  roomSelector,
  textureSelector,
  viewModeSelector,
  rotationSelector,
  sectionIdsSelector,
  (state: RootState, props: Props) => props,
  (colorway, clutColorwayId, customRugVisible, customRug, mutedIndices, install, room, texture, viewMode, rotation, sectionIds, props): Params => {
    return {
      colorway: colorway,
      clutColorwayId: clutColorwayId ? clutColorwayId : '',
      customRugVisible,
      customRug: customRug,
      mutedIndices: mutedIndices,
      install: install,
      refLibs: props?.refLibs?.length > 0 ? props.refLibs : '',
      room: room,
      texture: texture,
      viewMode: room ? viewMode : "2d",
      rotation,
      position: props.position,
      sectionIds
    };
  }
);

export default paramsSelector;
