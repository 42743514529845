import RootState from 'Store/Root';
import { ViewMode } from 'Types/Designs';

import { Props } from './Props';

const viewModeSelector = (state: RootState, props: Props): ViewMode => {
  const v = props.viewMode;

  if (v && v.toLowerCase() === '3d') {
    return '3d';
  } else if (v && v.toLowerCase() === '2d') {
    return '2d';
  } else if (props.roomId > 0) {
    return '3d';
  } else {
    return '3d';
  }
};

export default viewModeSelector;
