import { PrintScaleOption } from 'Types/Basket';

import {
  LOADED_PRINT_SCALES,
  LOADING_PRINT_SCALES,
  LOAD_PRINT_SCALES_FAILED,
  PrintScalesAction
} from './Actions';

export interface PrintScalesState {
  items: PrintScaleOption[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: PrintScalesState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false
};

export function printScalesReducer(state = initialState, action: PrintScalesAction): PrintScalesState {
  switch (action.type) {
    case LOADED_PRINT_SCALES:
      return {
        items: action.items.concat(),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_PRINT_SCALES:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_PRINT_SCALES_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
