import URI from 'urijs';
import _groupBy from 'lodash-es/groupBy';
import _isEmpty from 'lodash-es/isEmpty';
import _values from 'lodash-es/values';

import { Urls as StudioUrls } from 'Clients/Studio';

import Config from 'Config';

import RootState from 'Store/Root';
import { EmailDestinationOption } from 'Types/Basket';
import * as Guid from 'Utils/Guid';
import * as TrykUtils from 'Utils/Tryk';

const SECTION_RUG = 7;

export function makeOrder(state: RootState): TrykApi.Catalog.IOrderRequest {
  return {
    projectName: state.basket.projectName,
    trykColorways: makeTrykColorways(state),
    trykGrids: [],
    trykRugs: makeTrykRugs(state)
  };
}

function makeTrykColorways(state: RootState): TrykApi.Catalog.ITrykColorwayRequest[] {
  const { basket } = state;
  const products = _values(state.trykLayouts.itemsById);

  const items = basket.itemsList
    .map(x => basket.colorwaysById[x])
    .filter(x => !_isEmpty(x));

  const physicals = basket.physicalsList.map(x => basket.physicalsById[x]);
  const emails = basket.emailsList.map(x => basket.emailsById[x]);

  return items.map((x): TrykApi.Catalog.ITrykColorwayRequest => {
    const prod = products.find(p => p.productId === x.productId);

    const uri = URI(StudioUrls.colorway(x.colorway, {
      textureId: x.textureId,
      roomId: x.roomId,
      installId: x.installId,
      viewMode: x.roomId > 0 ? '3d' : '2d',
      rotation: x.rotation,
      position: x.position
    })).absoluteTo(Config.studioBaseUrl);

    let trykEmails = emails || [];

    if (!prod.enablePhysicalDelivery && trykEmails.length === 0) {
      // If a product is digital only and we don't have any emails then
      // map the physical addresses to an email.
      trykEmails = _values(_groupBy(physicals, p => p.emailAddress))
        .map(x => x[0])
        .map((p): EmailDestinationOption => ({
          id: Guid.create(),
          emailDestinationId: 0,
          emailAddress: p.emailAddress,
          fullName: p.fullName
        }));
    }

    return {
      url: uri.toString(),
      colorway: x.colorway,
      printScale: x.printScale,
      swatchOffset: x.swatchOffset || { x: 0, y: 0 },
      installId: x.installId,
      roomId: x.roomId,
      surfaceRotation: x.rotation,
      surfacePosition: x.position || null,
      textureId: x.textureId,
      areaRug: x.areaRug,
      layoutBrandId: x.layoutBrandId,
      productId: x.productId,
      emailDestinations: trykEmails,
      physicalDestinations: prod.enablePhysicalDelivery ?
        physicals : []
    };
  });
}

function makeTrykRugs(state: RootState): TrykApi.Catalog.ITrykRugRequest[] {
  const { basket } = state;
  const products = _values(state.trykLayouts.itemsById);

  const items = basket.itemsList
    .map(x => basket.rugsById[x])
    .filter(x => !_isEmpty(x));

  const emails = basket.emailsList.map(x => basket.emailsById[x]);
  const physicals = basket.physicalsList.map(x => basket.physicalsById[x]);

  return items.map((x): TrykApi.Catalog.ITrykRugRequest => {
    const prod = products.find(p => p.productId === x.productId);

    const uri = URI(StudioUrls.rug({
      ...x.rug,
      roomId: x.roomId,
      viewMode: x.roomId > 0 ? '3d' : '2d'
    })).absoluteTo(Config.studioBaseUrl);

    let trykEmails = emails || [];

    if (!prod.enablePhysicalDelivery && trykEmails.length === 0) {
      // If a product is digital only and we don't have any emails then
      // map the physical addresses to an email.
      trykEmails = _values(_groupBy(physicals, p => p.emailAddress))
        .map(x => x[0])
        .map((p): EmailDestinationOption => ({
          id: Guid.create(),
          emailDestinationId: 0,
          emailAddress: p.emailAddress,
          fullName: p.fullName
        }));
    }

    return {
      url: uri.toString(),
      rug: x.rug,
      printScale: x.printScale,
      roomId: x.roomId,
      layoutBrandId: x.layoutBrandId,
      productId: x.productId,
      emailDestinations: trykEmails,
      physicalDestinations: prod.enablePhysicalDelivery ?
        physicals : []
    };
  });
}

export function initializeSwatchOffset(state: RootState, productId: number, colorway: TrykApi.Catalog.IColorway, printScale: number, defaultOffset?: { x: number; y: number }) {

  const sectionIds = TrykUtils.getSections(state, colorway.designCode);

  let swatchOffset = typeof (defaultOffset) === 'undefined' ? { x: 0, y: 0 } : defaultOffset;

  if (sectionIds.indexOf(SECTION_RUG) !== -1) {
    const trykProducts = TrykUtils.filterColorwayProducts(state.trykLayouts, colorway.brandId, true, sectionIds);
    const product = trykProducts.find(x => x.productId === productId);
    const productFrames = (product && product.frames) || [];
    const swatchFrame = productFrames.find(x => x.productId === productId && x.frameContentTypeId === 1);

    // Centers offset on Rug
    if (swatchFrame) {
      swatchOffset.x = (colorway.repeatSize.widthInches / 2) - (swatchFrame.width * printScale) / 2;
      swatchOffset.y = (colorway.repeatSize.heightInches / 2) - (swatchFrame.height * printScale) / 2;
    }
  }

  return swatchOffset;
}

export function centeredSwatchOffsetByPrintScale(product: TrykApi.Catalog.ITrykProduct, sectionIds: number[], colorway: TrykApi.Catalog.IColorway, printScales: number[]): any {

  const dict: { [id: string]: { x: number, y: number } } = {};

  if (sectionIds.indexOf(SECTION_RUG) !== -1 && product) {
    const productFrames = (product && product.frames) || [];
    const swatchFrame = productFrames.find(x => x.productId === product.productId && x.frameContentTypeId === 1);

    // Centers offset on Rug
    for (const index in printScales) {
      let swatchOffset: { x: number, y: number } = { x: 0, y: 0 };

      if (swatchFrame) {
        swatchOffset.x = (colorway.repeatSize.widthInches / 2) - (swatchFrame.width * printScales[index]) / 2;
        swatchOffset.y = (colorway.repeatSize.heightInches / 2) - (swatchFrame.height * printScales[index]) / 2;
      }

      dict[printScales[index]] = swatchOffset;
    }
    return dict;
  }
  else return null;
}
