import { ThunkAction } from 'redux-thunk';

import RootState from 'Store/Root';

import {
  ItemType,
  isSavedColorway,
  isSavedRug
} from 'Types/Projects';

import { mapItem } from '../../Utils';

import { ProjectItemsAction } from '../Action';
import { loadProjectItem } from '../Load';

import { addColorwayToBasket } from './Colorway';
import { addRugToBasket } from './Rug';

export const ADDING_PROJECT_ITEM_TO_BASKET = 'ADDING_PROJECT_ITEM_TO_BASKET';
export const ADDED_PROJECT_ITEM_TO_BASKET = 'ADDED_PROJECT_ITEM_TO_BASKET';
export const ADD_PROJECT_ITEM_TO_BASKET_FAILED = 'ADD_PROJECT_ITEM_TO_BASKET_FAILED';

export function addItemToBasket(itemId: number): ThunkAction<Promise<boolean>, RootState, ProjectItemsAction> {
  return (dispatch, getState) => {
    dispatch({
      type: ADDING_PROJECT_ITEM_TO_BASKET,
      itemId
    });

    const addItemByType = (item: ItemType): Promise<boolean> => {
      const itemId = item.folderItemId;

      if (isSavedColorway(item)) {
        return dispatch(addColorwayToBasket(item));
      } else if (isSavedRug(item)) {
        return dispatch(addRugToBasket(item));
      } else {
        throw `Error: The saved item type (id:${itemId}) is not implemented.`;
      }
    };

    return new Promise<boolean>(
      (resolve, reject) => {
        dispatch(loadProjectItem(itemId)).then(
          result => {
            const { item } = mapItem(result, {
              isOwner: false,
              isDeleted: false
            });

            addItemByType(item).then(
              success => resolve(success),
              err => reject(err)
            );
          },
          err => {
            dispatch({
              type: ADD_PROJECT_ITEM_TO_BASKET_FAILED,
              itemId
            });

            reject(err);
          }
        );
      }
    );
  };
}
