import _flatMap from 'lodash-es/flatMap';
import _groupBy from 'lodash-es/groupBy';
import _mapKeys from 'lodash-es/mapKeys';
import _mapValues from 'lodash-es/mapValues';
import _uniqBy from 'lodash-es/uniqBy';

import {
  LOADED_TRYK_LAYOUTS,
  LOADING_TRYK_LAYOUTS,
  LOAD_TRYK_LAYOUTS_FAILED,
  TrykLayoutsAction
} from './Actions';

export interface TrykLayoutsState {
  itemsById: _.Dictionary<TrykApi.Catalog.ITrykProduct>;
  brandMap: _.Dictionary<number[]>;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: TrykLayoutsState = {
  itemsById: {},
  brandMap: {},
  loading: false,
  loaded: false,
  failed: false
};

export function trykLayoutsReducer(state = initialState, action: TrykLayoutsAction): TrykLayoutsState {
  switch (action.type) {
    case LOADED_TRYK_LAYOUTS:
      return {
        itemsById: _mapKeys(
          _uniqBy(
            _flatMap(action.items, x => x.products),
            x => x.productId
          ),
          x => x.productId
        ),
        brandMap: _mapValues(
          _groupBy(action.items, 'brandId'),
          grp => _flatMap(grp, x => x.products.map(y => y.productId))
        ),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_TRYK_LAYOUTS:
      return {
        itemsById: {},
        brandMap: {},
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_TRYK_LAYOUTS_FAILED:
      return {
        itemsById: {},
        brandMap: {},
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
