import * as CatalogClient from '@shawfloors/catalog-client';

import { defer } from 'Utils/Promise';
import * as Storage from 'Utils/Storage';

import cacheKey from './CacheKey';

export function getCategories(): Promise<TrykApi.Catalog.ICategory[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'categories'),
    () => {
      const dfd = defer<TrykApi.Catalog.ICategory[]>();

      CatalogClient.Global.getCategories()
        .then((res) => dfd.resolve(res.length === 1 ? res[0].children : res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getCollections(): Promise<TrykApi.Catalog.ICollection[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'collections'),
    () => {
      const dfd = defer<TrykApi.Catalog.ICollection[]>();

      CatalogClient.Global.getCollections()
        .then((res) => dfd.resolve(res[0].collections), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getCountries(): Promise<TrykApi.Catalog.ICountry[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'countries'),
    () => CatalogClient.Global.getCountries());
}

export function getInstallTemplates(): Promise<TrykApi.Catalog.IBrandInstallTemplates[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'install-templates'),
    () => {
      const dfd = defer<TrykApi.Catalog.IBrandInstallTemplates[]>();

      CatalogClient.Global.getInstallTemplates()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getRooms(): Promise<TrykApi.Catalog.IBrandRoomScenes[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'rooms'),
    () => {
      const dfd = defer<TrykApi.Catalog.IBrandRoomScenes[]>();

      CatalogClient.Global.getRooms()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getRoomSceneCategories(): Promise<TrykApi.Catalog.IRoomSceneCategory[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'rooms', 'categories'),
    () => {
      const dfd = defer<TrykApi.Catalog.IRoomSceneCategory[]>();

      CatalogClient.Global.getRoomSceneCategories()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getSite(): Promise<TrykApi.Catalog.ISite> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'site'),
    () => {
      const dfd = defer<TrykApi.Catalog.ISite>();

      CatalogClient.Global.getSite()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getTextures(): Promise<TrykApi.Catalog.ITexture[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'textures'),
    () => {
      const dfd = defer<TrykApi.Catalog.ITexture[]>();

      CatalogClient.Global.getTextures()
        .then((res) => dfd.resolve(res[0].textures), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getTrykProducts(): Promise<TrykApi.Catalog.IBrandTrykProducts[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'tryk-products'),
    () => {
      const dfd = defer<TrykApi.Catalog.IBrandTrykProducts[]>();

      CatalogClient.Global.getTrykProducts()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}

export function getYarnLibraries(): Promise<TrykApi.Yarns.IYarnLibrary[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('global', 'yarn-libraries'),
    () => {
      const dfd = defer<TrykApi.Yarns.IYarnLibrary[]>();

      CatalogClient.Global.getYarnLibraries()
        .then((res) => dfd.resolve(res), (err) => dfd.reject(err))
        .catch((err) => dfd.reject(err));

      return dfd.promise;
    });
}
