interface UserState {
  account: AccountState;
  orders: OrdersState;
  password: PasswordState;
  shippingHistory: ShippingHistoryState;
  status: StatusState;
  tryks: TryksState;
}

export default UserState;

export const initialState: UserState = {
  account: {
    data: null,
    loading: false,
    loadFailed: false,
    updating: false,
    updateFailed: false,
    didUpdate: false
  },
  orders: {
    byId: {}
  },
  password: {
    changing: false,
    changeFailed: false,
    didChange: false,
    requestingReset: false,
    requestResetFailed: false,
    didRequestReset: false
  },
  shippingHistory: {
    loading: false,
    loaded: false,
    loadFailed: false,
    data: null
  },
  status: {
    loggedIn: false,
    isRegistering: false,
    isLoggingIn: false,
    isLoggingOut: false,
    didRegisterError: false,
    didLoginError: false,
    didLogoutError: false,
  },
  tryks: {
    itemsById: {},
    designsById: {},
    destinationsById: {},
    loadingById: {},
    errorsById: {},
    orderMap: {}
  }
};

export interface StatusState {
  loggedIn: boolean;
  isRegistering: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  didRegisterError: boolean;
  didLoginError: boolean;
  didLogoutError: boolean;
}

export interface AccountState {
  data: TrykApi.Catalog.IAccount;
  loading: boolean;
  loadFailed: boolean;
  updating: boolean;
  updateFailed: boolean;
  didUpdate: boolean;
}

export interface ContactState {
  data: TrykApi.Catalog.IAccount;
  loading: boolean;
  loadFailed: boolean;
  updating: boolean;
  updateFailed: boolean;
  didUpdate: boolean;
}

export interface PasswordState {
  changing: boolean;
  changeFailed: boolean;
  didChange: boolean;

  requestingReset: boolean;
  requestResetFailed: boolean;
  didRequestReset: boolean;
}

export interface ShippingHistoryState {
  loading: boolean;
  loaded: boolean;
  loadFailed: boolean;
  data: TrykApi.Catalog.IShippingHistory;
}

export interface OrderMap {
  [orderId: string]: TrykApi.Catalog.OrderHistory.IOrderOverview;
}

export interface OrdersState {
  byId: OrderMap;
}

export interface TrykMap {
  [trykId: string]: TrykApi.Catalog.OrderHistory.ITrykOverview;
}

export interface TryksByOrderState {
  loading: boolean;
  failed: boolean;
  trykIds: number[];
}

export interface TryksByOrderMap {
  [orderId: string]: TryksByOrderState;
}

export interface DesignsByTrykMap {
  [trykId: string]: TrykApi.Catalog.OrderHistory.IDesign[];
}

export interface DestinationsByTrykMap {
  [trykId: string]: TrykApi.Catalog.OrderHistory.ITrykDestinationDetail;
}

export interface FlagMap {
  [id: string]: boolean;
}

export interface TryksState {
  itemsById: TrykMap;
  designsById: DesignsByTrykMap;
  destinationsById: DestinationsByTrykMap;
  loadingById: FlagMap;
  errorsById: FlagMap;
  orderMap: TryksByOrderMap;
}
