import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';
import { Data as StudioData } from 'Clients/Studio';

import RootState from 'Store/Root';
import { SectionMap } from 'Types/Rooms';

export const LOADING_ROOMS = 'LOADING_ROOMS';
export const LOADED_ROOMS = 'LOADED_ROOMS';
export const LOAD_ROOMS_FAILED = 'LOAD_ROOMS_FAILED';

export interface RoomsAction {
  type: string;
  items?: TrykApi.Catalog.IBrandRoomScenes[];
  sectionMap?: SectionMap;
  categories?: TrykApi.Catalog.IRoomSceneCategory[];
}

function loadingRooms(): RoomsAction {
  return {
    type: LOADING_ROOMS
  };
}

function roomsLoadFailed(): RoomsAction {
  return {
    type: LOAD_ROOMS_FAILED
  };
}

function roomsLoaded(items: TrykApi.Catalog.IBrandRoomScenes[], map: SectionMap, categories: TrykApi.Catalog.IRoomSceneCategory[]): RoomsAction {
  return {
    type: LOADED_ROOMS,
    items: items,
    sectionMap: map,
    categories: categories
  };
}

export function loadRooms(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.rooms.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loadingRooms());

    return Promise.all([
      CatalogClient.Global.getRooms(),
      StudioData.getSectionRoomScenesMap(),
      CatalogClient.Global.getRoomSceneCategories()
    ]).then(
      x => dispatch(roomsLoaded(x[0], x[1], x[2])),
      e => dispatch(roomsLoadFailed())
    );
  };
}
