import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_TEXTURES = 'LOADING_TEXTURES';
export const LOADED_TEXTURES = 'LOADED_TEXTURES';
export const LOAD_TEXTURES_FAILED = 'LOAD_TEXTURES_FAILED';

export interface TexturesAction {
  type: string;
  items?: TrykApi.Catalog.ITexture[];
}

function loadingTextures(): TexturesAction {
  return {
    type: LOADING_TEXTURES
  };
}

function texturesLoadFailed(): TexturesAction {
  return {
    type: LOAD_TEXTURES_FAILED
  };
}

function texturesLoaded(items: TrykApi.Catalog.ITexture[]): TexturesAction {
  return {
    type: LOADED_TEXTURES,
    items: items
  };
}

export function loadTextures(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.textures.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loadingTextures());

    return CatalogClient.Global.getTextures()
      .then(
      x => dispatch(texturesLoaded(x)),
      e => dispatch(texturesLoadFailed())
      );
  };
}
