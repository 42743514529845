import {
  LOADING_FIXED_COLORWAYS,
  LOAD_FIXED_COLORWAYS_FAILED,
  LOADED_FIXED_COLORWAYS,
  LOADING_CUSTOM_COLORWAY,
  LOAD_CUSTOM_COLORWAY_FAILED,
  LOADED_CUSTOM_COLORWAY,
  ColorwayAction
} from '../Actions';

import ColorwayState, { colorway } from './Colorway';
import { getColorwayMapKey } from './Utils';

export interface ColorwayMap {
  [code: string]: ColorwayState;
}

function colorwaysReducer(store: ColorwayMap = {}, action: ColorwayAction): ColorwayMap {
  switch (action.type) {
    case LOADING_FIXED_COLORWAYS:
    case LOADED_FIXED_COLORWAYS:
    case LOAD_FIXED_COLORWAYS_FAILED:
    case LOADING_CUSTOM_COLORWAY:
    case LOADED_CUSTOM_COLORWAY:
    case LOAD_CUSTOM_COLORWAY_FAILED:
      return {
        ...store,
        [getColorwayMapKey(action.designCode)]: colorway(store[getColorwayMapKey(action.designCode)], action)
      };
    default:
      return store;
  }
}

export default colorwaysReducer;
