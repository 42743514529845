import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

export const CHANGING_PASSWORD = 'USER_CHANGING_PASSWORD';
export const CHANGE_PASSWORD_FAILED = 'USER_CHANGE_PASSWORD_FAILED';
export const CHANGED_PASSWORD = 'USER_PASSWORD_CHANGED';

export default function changePassword(currentPass: string, newPass: string): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(changingPassword());

    const request = CatalogClient.User.changePassword({
      currentPassword: currentPass,
      newPassword: newPass,
      confirmNewPassword: newPass
    });

    request.then(
      x => dispatch(passwordChanged()),
      e => dispatch(changePasswordFailed())
    );

    return request;
  };
}

function changingPassword(): UserAction {
  return {
    type: CHANGING_PASSWORD
  };
}

function changePasswordFailed(): UserAction {
  return {
    type: CHANGE_PASSWORD_FAILED
  };
}

function passwordChanged(): UserAction {
  return {
    type: CHANGED_PASSWORD
  };
}
