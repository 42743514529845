import _cloneDeep from 'lodash-es/cloneDeep';
import _concat from 'lodash-es/concat';
import _mapKeys from 'lodash-es/mapKeys';
import _omit from 'lodash-es/omit';
import _uniq from 'lodash-es/uniq';
import _without from 'lodash-es/without';

import {
  LOAD_STORED_BASKET,
  REMOVE_ITEM,
  ADD_COLORWAY_ITEM,
  CHANGE_COLORWAY_LAYOUT_BRAND,
  CHANGE_COLORWAY_INSTALL,
  CHANGE_COLORWAY_POSITION,
  CHANGE_COLORWAY_PRINT_SCALE,
  CHANGE_COLORWAY_PRODUCT,
  CHANGE_COLORWAY_ROOM,
  ADD_RUG_ITEM,
  CHANGE_RUG_LAYOUT_BRAND,
  CHANGE_RUG_PRINT_SCALE,
  CHANGE_RUG_PRODUCT,
  CHANGE_RUG_ROOM,
  CHANGE_PROJECT_NAME,
  CLEAR_SHIPPING_OPTIONS,
  LOADED_SHIPPING_OPTIONS,
  LOADING_SHIPPING_OPTIONS,
  ADD_EMAIL_SHIPPING,
  ADD_NEW_EMAIL_SHIPPING,
  REMOVE_EMAIL_SHIPPING,
  ADD_PHYSICAL_SHIPPING,
  ADD_NEW_PHYSICAL_SHIPPING,
  REMOVE_PHYSICAL_SHIPPING,
  RESET_BASKET,
  PLACING_ORDER,
  ORDER_PLACED,
  PLACE_ORDER_FAILED,
  BasketAction
} from './Actions';

import BasketState, {
  initialState
} from './State';

function basketReducer(state = initialState, action: BasketAction): BasketState {
  switch (action.type) {
    case ADD_COLORWAY_ITEM:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.colorway.id]: _cloneDeep(action.colorway)
        },
        itemsList: _concat(state.itemsList, [action.colorway.id])
      };
    case ADD_RUG_ITEM:
      return {
        ...state,
        rugsById: {
          ...state.rugsById,
          [action.rug.id]: _cloneDeep(action.rug)
        },
        itemsList: _concat(state.itemsList, [action.rug.id])
      };
    case REMOVE_ITEM:
      return {
        ...state,
        colorwaysById: _omit(state.colorwaysById, action.id),
        rugsById: _omit(state.rugsById, action.id),
        itemsList: _without(state.itemsList, action.id)
      };
    case LOAD_STORED_BASKET:
      return {
        ...state,
        ...action.storage
      };
    case CHANGE_COLORWAY_POSITION:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            swatchOffset: action.swatchOffset
          }
        }
      };
    case CHANGE_COLORWAY_PRINT_SCALE:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            printScale: action.printScale
          }
        }
      };
    case CHANGE_COLORWAY_LAYOUT_BRAND:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            layoutBrandId: action.layoutBrandId
          }
        }
      };
    case CHANGE_COLORWAY_PRODUCT:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            productId: action.productId
          }
        }
      };
    case CHANGE_COLORWAY_ROOM:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            roomId: action.roomId
          }
        }
      };
    case CHANGE_COLORWAY_INSTALL:
      return {
        ...state,
        colorwaysById: {
          ...state.colorwaysById,
          [action.id]: {
            ...state.colorwaysById[action.id],
            installId: action.installId
          }
        }
      };
    case CHANGE_RUG_PRINT_SCALE:
      return {
        ...state,
        rugsById: {
          ...state.rugsById,
          [action.id]: {
            ...state.rugsById[action.id],
            printScale: action.printScale
          }
        }
      };
    case CHANGE_RUG_LAYOUT_BRAND:
      return {
        ...state,
        rugsById: {
          ...state.rugsById,
          [action.id]: {
            ...state.rugsById[action.id],
            layoutBrandId: action.layoutBrandId
          }
        }
      };
    case CHANGE_RUG_PRODUCT:
      return {
        ...state,
        rugsById: {
          ...state.rugsById,
          [action.id]: {
            ...state.rugsById[action.id],
            productId: action.productId
          }
        }
      };
    case CHANGE_RUG_ROOM:
      return {
        ...state,
        rugsById: {
          ...state.rugsById,
          [action.id]: {
            ...state.rugsById[action.id],
            roomId: action.roomId
          }
        }
      };
    case CHANGE_PROJECT_NAME:
      return {
        ...state,
        projectName: action.projectName || ''
      };
    case CLEAR_SHIPPING_OPTIONS:
      return {
        ...state,
        loadingShipping: false,
        loadedShipping: false,
        emailsById: {},
        emailsList: [],
        physicalsById: {},
        physicalsList: []
      };
    case LOADING_SHIPPING_OPTIONS:
      return {
        ...state,
        loadingShipping: true,
        loadedShipping: false,
        emailsById: {},
        emailsList: [],
        physicalsById: {},
        physicalsList: []
      };
    case LOADED_SHIPPING_OPTIONS:
      return {
        ...state,
        loadingShipping: false,
        loadedShipping: true,
        emailsById: _mapKeys(action.shippingOptions.emailDestinations, x => x.id),
        emailsList: [],
        physicalsById: _mapKeys(action.shippingOptions.physicalDestinations, x => x.id),
        physicalsList: []
      };
    case ADD_EMAIL_SHIPPING:
      return {
        ...state,
        emailsList: _uniq(
          _concat(state.emailsList, [action.id])
        )
      };
    case ADD_NEW_EMAIL_SHIPPING:
      return {
        ...state,
        emailsById: {
          ...state.emailsById,
          [action.email.id]: action.email
        },
        emailsList: _concat(state.emailsList, action.email.id)
      };
    case REMOVE_EMAIL_SHIPPING:
      return {
        ...state,
        emailsList: _without(state.emailsList, action.id)
      };
    case ADD_PHYSICAL_SHIPPING:
      return {
        ...state,
        physicalsList: _uniq(
          _concat(state.physicalsList, [action.id])
        )
      };
    case ADD_NEW_PHYSICAL_SHIPPING:
      return {
        ...state,
        physicalsById: {
          ...state.physicalsById,
          [action.physical.id]: action.physical
        },
        physicalsList: _concat(state.physicalsList, action.physical.id)
      };
    case REMOVE_PHYSICAL_SHIPPING:
      return {
        ...state,
        physicalsList: _without(state.physicalsList, action.id)
      };
    case RESET_BASKET:
      return _cloneDeep(initialState);
    case PLACING_ORDER:
      return {
        ...state,
        placingOrder: true,
        placeOrderError: false
      };
    case ORDER_PLACED:
      return {
        ...state,
        placingOrder: false,
        placeOrderError: false
      };
    case PLACE_ORDER_FAILED:
      return {
        ...state,
        placingOrder: false,
        placeOrderError: true
      };
    default:
      return state;
  }
}

export default basketReducer;
