import _has from 'lodash-es/has';
import _keys from 'lodash-es/keys';

import Config from 'Config';

import { SectionMap } from 'Types/Rooms';

type RoomState = {
  itemsById: _.Dictionary<TrykApi.Catalog.IRoomScene>;
  brandDefaultIdMap: _.Dictionary<number>;
  brandMap: _.Dictionary<number[]>;
  sectionMap: SectionMap;
};

export function getValidRooms(state: RoomState, brandId: number, sectionIds: number[], hasAreaRug: boolean, hasBroadloom: boolean): TrykApi.Catalog.IRoomScene[] {
  if (!state || !state.itemsById || !state.sectionMap) {
    return [];
  }
  const targetId = Config.studio.roomsBrandId > 0 ? Config.studio.roomsBrandId : brandId;
  let brandRooms = getRoomsForBrand(state, targetId);

  if (typeof (hasBroadloom) !== 'undefined' && !hasBroadloom) {
    brandRooms = brandRooms.filter(x => x.surfaces.some(s => s.name !== 'Stair'));
  }

  let sectionId = '';

  if (hasAreaRug) {
    sectionId = '7';
  } else if (sectionIds && sectionIds.length === 1 && _has(state.sectionMap, sectionIds[0].toString())) {
    sectionId = sectionIds[0].toString();
  }

  if (!sectionId) {
    return brandRooms;
  }

  const roomItems = state.sectionMap[sectionId].filter(
    x => brandRooms.find(y => y.roomId === x.roomId)
  );

  return roomItems.map(x => {
    const room = brandRooms.find(y => y.roomId === x.roomId);

    return {
      ...room,
      isDefault: x.isDefault
    };
  });
}

export function getValidRugRooms(state: RoomState): TrykApi.Catalog.IRoomScene[] {
  if (!state || !state.itemsById) {
    return [];
  }

  if (Config.studio.rugBrandId > 0) {
    return getRoomsForBrand(state, Config.studio.rugBrandId);
  } else if (Config.studio.roomsBrandId > 0) {
    return getRoomsForBrand(state, Config.studio.roomsBrandId);
  } else {
    return getRoomsForBrand(state, +_keys(state.brandMap)[0]);
  }
}

function getRoomsForBrand(state: RoomState, brandId: number): TrykApi.Catalog.IRoomScene[] {
  const brandKey = brandId.toString();
  if (!_has(state.brandMap, brandKey)) {
    return [];
  }

  const brandRooms = state.brandMap[brandKey].map(x => state.itemsById[x.toString()]);
  const brandDefaultId = state.brandDefaultIdMap[brandKey];

  return brandRooms.map(x => ({
    ...x,
    isDefault: x.roomId == brandDefaultId
  }));
}
