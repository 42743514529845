import { ThunkAction } from 'redux-thunk';

import { Favorites as FavoritesClient } from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_COMMENTS = 'LOADING_PROJECT_ITEM_COMMENTS';
export const LOADED_COMMENTS = 'LOADED_PROJECT_ITEM_COMMENTS';
export const LOAD_COMMENTS_FAILED = 'LOAD_PROJECT_ITEM_COMMENTS_FAILED';

export const ADDING_COMMENT = 'ADDING_PROJECT_ITEM_COMMENT';
export const ADDED_COMMENT = 'ADDED_PROJECT_ITEM_COMMENT';
export const ADD_COMMENT_FAILED = 'ADD_PROJECT_ITEM_COMMENT_FAILED';

export const CLEAR_COMMENTS = 'CLEAR_PROJECT_ITEM_COMMENTS';

export interface ProjectItemCommentsAction {
  type: string;
  itemId?: number;
  comments?: TrykApi.Catalog.Favorites.IComment[];
  comment?: TrykApi.Catalog.Favorites.IComment;
}

export function loadComments(itemId: number): ThunkAction<Promise<any>, RootState, ProjectItemCommentsAction> {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_COMMENTS,
      itemId
    });

    return FavoritesClient.getItemComments(itemId)
      .then(
      comments => dispatch({
        type: LOADED_COMMENTS,
        itemId,
        comments
      }),
      err => dispatch({
        type: LOAD_COMMENTS_FAILED,
        itemId
      })
      );
  };
}

export function addComment(itemId: number, comment: string): ThunkAction<Promise<any>, RootState, ProjectItemCommentsAction> {
  return (dispatch, getState) => {
    dispatch({
      type: ADDING_COMMENT,
      itemId
    });

    return FavoritesClient.addItemComment(itemId, comment)
      .then(
      comment => dispatch({
        type: ADDED_COMMENT,
        itemId,
        comment
      }),
      err => dispatch({
        type: ADD_COMMENT_FAILED,
        itemId
      })
      );
  };
}

export function clearComments(): ProjectItemCommentsAction {
  return {
    type: CLEAR_COMMENTS
  };
}
