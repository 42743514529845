import {
  LOADED_TEXTURES,
  LOADING_TEXTURES,
  LOAD_TEXTURES_FAILED,
  TexturesAction
} from './Actions';

export interface TexturesState {
  items: TrykApi.Catalog.ITexture[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: TexturesState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false
};

export default function texturesReducer(state = initialState, action: TexturesAction): TexturesState {
  switch (action.type) {
    case LOADED_TEXTURES:
      return {
        items: action.items.concat(),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_TEXTURES:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_TEXTURES_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
