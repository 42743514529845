import { LOADED_ORDER_DETAILS } from '../Actions/OrderDetails';

import { UserAction } from '../Actions';
import UserState from '../State';

export default function orders(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case LOADED_ORDER_DETAILS:
      return {
        ...state,
        orders: {
          ...state.orders,
          byId: {
            ...state.orders.byId,
            [action.orderDetail.orderId.toString()]: action.orderDetail.order
          }
        }
      };
    default:
      return state;
  }
}
