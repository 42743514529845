import _omit from 'lodash-es/omit';

import {
  LOADING_DESIGN,
  LOAD_DESIGN_FAILED,
  LOADED_DESIGN,
  DesignAction
} from './Actions';

export interface DesignsStore {
  errorsByCode: _.Dictionary<boolean>;
  loadingByCode: _.Dictionary<boolean>;
  itemsByCode: _.Dictionary<DesignState>;
}

export interface DesignState {
  overview: TrykApi.Catalog.IDesignOverview;
  installs: TrykApi.Catalog.IDesignInstall[];
}

const initialStore: DesignsStore = {
  errorsByCode: {},
  loadingByCode: {},
  itemsByCode: {}
};

const initialDesignState: DesignState = {
  overview: null,
  installs: null
};

function designState(state = initialDesignState, action: DesignAction): DesignState {
  switch (action.type) {
    case LOADED_DESIGN:
      return {
        ...state,
        overview: action.data.overview,
        installs: action.data.installs
          .map(x => ({ ...x, name: x.name.replace(/[0-9]+[xX][0-9]+/, '') }))
      };
    default:
      return state;
  }
}

function designsReducer(store = initialStore, action: DesignAction): DesignsStore {
  const designCode = (action.designCode || '').toUpperCase();

  switch (action.type) {
    case LOADING_DESIGN:
      return {
        ...store,
        errorsByCode: _omit(store.errorsByCode, designCode),
        loadingByCode: {
          ...store.loadingByCode,
          [designCode]: true
        }
      };
    case LOAD_DESIGN_FAILED:
      return {
        ...store,
        loadingByCode: _omit(store.loadingByCode, designCode),
        errorsByCode: {
          ...store.errorsByCode,
          [designCode]: true
        }
      };
    case LOADED_DESIGN:
      return {
        ...store,
        errorsByCode: _omit(store.errorsByCode, designCode),
        loadingByCode: _omit(store.loadingByCode, designCode),
        itemsByCode: {
          ...store.itemsByCode,
          [designCode]: (
            designState(store.itemsByCode[designCode], action)
          )
        }
      };
    default:
      return store;
  }
}

export default designsReducer;
