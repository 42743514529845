import omit from 'lodash-es/omit';

import {
  ADDING_PROJECT_ITEM_TO_BASKET,
  ADDED_PROJECT_ITEM_TO_BASKET,
  ADD_PROJECT_ITEM_TO_BASKET_FAILED,
  CLEAR_PROJECT_ITEMS,
  DELETING_PROJECT_ITEM,
  DELETED_PROJECT_ITEM,
  DELETE_PROJECT_ITEM_FAILED,
  LOADED_PROJECT_ITEM,
  LOADING_PROJECT_ITEM,
  LOAD_PROJECT_ITEM_FAILED,
  ProjectItemsAction
} from './Actions';

type FlagLookup = _.Dictionary<boolean>;

export interface ProjectItemsState {
  itemsById: _.Dictionary<TrykApi.Catalog.Favorites.ISavedItem>;
  loadingById: FlagLookup;
  failedById: FlagLookup;
  deletingById: FlagLookup;
  deletedById: FlagLookup;
  deleteFailedById: FlagLookup;
  addingToBasketById: FlagLookup;
  addedToBasketById: FlagLookup;
  addToBasketFailedById: FlagLookup;
}

const initialState: ProjectItemsState = {
  itemsById: {},
  loadingById: {},
  failedById: {},
  deletingById: {},
  deletedById: {},
  deleteFailedById: {},
  addingToBasketById: {},
  addedToBasketById: {},
  addToBasketFailedById: {}
};

export default function projectItemsReducer(state = initialState, action: ProjectItemsAction): ProjectItemsState {
  switch (action.type) {
    case CLEAR_PROJECT_ITEMS:
      return {
        ...initialState
      };
    case LOADED_PROJECT_ITEM:
      return {
        ...state,
        itemsById: {
          ...state.itemsById,
          [action.itemId]: action.item
        },
        loadingById: omit(state.loadingById, action.itemId),
        failedById: omit(state.failedById, action.itemId)
      };
    case LOADING_PROJECT_ITEM:
      return {
        ...state,
        loadingById: {
          ...state.loadingById,
          [action.itemId]: true
        }
      };
    case LOAD_PROJECT_ITEM_FAILED:
      return {
        ...state,
        loadingById: omit(state.loadingById, action.itemId),
        failedById: {
          ...state.failedById,
          [action.itemId]: true
        }
      };
    case DELETING_PROJECT_ITEM:
      return {
        ...state,
        deletingById: {
          ...state.deletingById,
          [action.itemId]: true
        }
      };
    case DELETED_PROJECT_ITEM:
      return {
        ...state,
        deletedById: {
          ...state.deletedById,
          [action.itemId]: true
        },
        deletingById: omit(state.deletingById, action.itemId),
        deleteFailedById: omit(state.deleteFailedById, action.itemId)
      };
    case DELETE_PROJECT_ITEM_FAILED:
      return {
        ...state,
        deletingById: omit(state.deletingById, action.itemId),
        deleteFailedById: {
          ...state.deleteFailedById,
          [action.itemId]: true
        }
      };
    case ADDING_PROJECT_ITEM_TO_BASKET:
      return {
        ...state,
        addingToBasketById: {
          ...state.addingToBasketById,
          [action.itemId]: true
        },
        addedToBasketById: omit(state.addedToBasketById, action.itemId)
      };
    case ADDED_PROJECT_ITEM_TO_BASKET:
      return {
        ...state,
        addedToBasketById: {
          ...state.addedToBasketById,
          [action.itemId]: true
        },
        addingToBasketById: omit(state.addingToBasketById, action.itemId),
        addToBasketFailedById: omit(state.addToBasketFailedById, action.itemId)
      };
    case ADD_PROJECT_ITEM_TO_BASKET_FAILED:
      return {
        ...state,
        addingToBasketById: omit(state.addingToBasketById, action.itemId),
        addToBasketFailedById: {
          ...state.addToBasketFailedById,
          [action.itemId]: true
        }
      };
    default:
      return state;
  }
}
