import {
  LOADING_SHIPPING_HISTORY,
  LOADED_SHIPPING_HISTORY,
  LOAD_SHIPPING_HISTORY_FAILED
} from '../Actions/ShippingHistory';

import { UserAction } from '../Actions';

import UserState, { initialState as initialUser } from '../State';

export default function shippingHistory(state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case LOADING_SHIPPING_HISTORY:
      return {
        ...state,
        shippingHistory: {
          loading: true,
          loaded: false,
          loadFailed: false,
          data: null
        }
      };
    case LOAD_SHIPPING_HISTORY_FAILED:
      return {
        ...state,
        shippingHistory: {
          loading: false,
          loaded: false,
          loadFailed: true,
          data: null
        }
      };
    case LOADED_SHIPPING_HISTORY:
      return {
        ...state,
        shippingHistory: {
          loading: false,
          loaded: false,
          loadFailed: true,
          data: action.shippingHistory
        }
      };
    default:
      return state;
  }
}
