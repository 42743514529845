import { createSelector } from 'reselect';

import * as Enums from 'Clients/Catalog/Enums';
import RootState from 'Store/Root';

import colorwaySelector from './Colorway';
import { Props } from './Props';

const textureSelector = createSelector(
  colorwaySelector,
  (state: RootState): TrykApi.Catalog.ITexture[] =>
    state.textures.items || [],
  (state: RootState, props: Props): number =>
    props.textureId || 0,
  (colorway, textures, textureId) => {
    if (!colorway || colorway.engineId !== Enums.Engines.Id.Print) {
      return null;
    }

    const texture = textures.find(
      x => x.textureId === textureId
    );

    return texture || textures.find(x => x.isDefault) ||
      textures[0];
  }
);

export default textureSelector;
