import { ThunkAction } from 'redux-thunk';

import { Data as StudioData } from 'Clients/Studio';

import RootState from 'Store/Root';

import { PrintScaleOption } from 'Types/Basket';

export const LOADING_PRINT_SCALES = 'LOADING_PRINT_SCALES';
export const LOADED_PRINT_SCALES = 'LOADED_PRINT_SCALES';
export const LOAD_PRINT_SCALES_FAILED = 'LOAD_PRINT_SCALES_FAILED';

export function loadPrintScales(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.printScales.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    return StudioData.getPrintScales()
      .then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
      );
  };
}

export interface PrintScalesAction {
  type: string;
  items?: PrintScaleOption[];
}

function loading(): PrintScalesAction {
  return {
    type: LOADING_PRINT_SCALES
  };
}

function loadFailed(): PrintScalesAction {
  return {
    type: LOAD_PRINT_SCALES_FAILED
  };
}

function loaded(items: PrintScaleOption[]): PrintScalesAction {
  return {
    type: LOADED_PRINT_SCALES,
    items: items
  };
}
