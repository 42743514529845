import { YarnCodeList } from 'Types/Yarns';

export function getYarnCodes(input: YarnCodeList): Array<string | string[]> {
  return input.map(x => {
    if (isYarn(x)) {
      if (x.yarnId > 10) {
        return x.code;
      } else {
        return x.children.map(y => y.code);
      }
    } else if (Array.isArray(x)) {
      return x.concat();
    } else if (typeof x === 'string') {
      return x as string;
    } else {
      throw new Error('Unsupported input element encountered.');
    }
  });
}

export function serializeYarnCodes(input: YarnCodeList): string {
  return getYarnCodes(input).map(x => {
    if (Array.isArray(x)) {
      return `[${x.join(',')}]`;
    } else {
      return x;
    }
  }).join(',');
}

export function deserializeYarnCodes(input: string): YarnCodeList {
  throw new Error('Not implemented.');
}

function isYarn(x: any): x is TrykApi.Catalog.IColorwayYarn {
  if (!x) {
    return false;
  } else if (Array.isArray(x)) {
    return false;
  } else {
    return Object.keys(x).some(y => y === 'yarnId');
  }
}
