import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';
import { pushAnalytics } from 'Utils/Analytics';

export const LOGGING_IN = 'USER_LOGGING_IN';
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const LOGIN_FAILED = 'USER_LOGIN_FAILED';

export default function login(attempt: TrykApi.Catalog.ICookieLogin): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(loggingIn());

    pushAnalytics('login-attempt');

    const request = CatalogClient.User.login(attempt);

    request.then(
      x => {
        pushAnalytics('login', {
          loginStatus: 'complete'
        });

        return dispatch(loginSuccess(x));
      },
      err => {
        pushAnalytics('login', {
          loginStatus: 'error'
        });

        return dispatch(loginFailed());
      }
    );

    return request;
  };
}

function loggingIn(): UserAction {
  return {
    type: LOGGING_IN
  };
}

function loginFailed(): UserAction {
  return {
    type: LOGIN_FAILED
  };
}

function loginSuccess(account: TrykApi.Catalog.IAccount): UserAction {
  return {
    type: LOGIN_SUCCESS,
    account: account
  };
}
