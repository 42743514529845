import { createSelector } from 'reselect';

import _has from 'lodash-es/has';

import { Favorites as FavoritesClient } from 'Clients/Catalog';

import { mapItem } from 'Store/ProjectItems/Utils';

import { createPaginationActions } from 'Store/Pagination/Actions';
import createPaginationSelectors from 'Store/Pagination/Selectors';
import RootState from 'Store/Root';

import {
  SearchArgs,
  SavedItem
} from 'Types/Projects';

const PAGINATION_STORE_NAME: string = 'projectItemSearch';
export const PAGE_SIZE: number = 5;

type Item = TrykApi.Catalog.Favorites.ISavedItem;

const defaultArgs: SearchArgs = {};

export const actions = createPaginationActions<Item, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  loadPage: (page, opts = defaultArgs) => (
    FavoritesClient.getSearchPage({ ...opts, page: page.index, pageSize: page.size })
      .then(x => mapPage(x))
  )
});

const baseSelectors = createPaginationSelectors<Item, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  pageSize: PAGE_SIZE
});

export const selectors = {
  ...baseSelectors,
  currResults: createSelector(
    baseSelectors.currResults,
    (state: RootState, args: SearchArgs) => state.user.account.data,
    (state: RootState, args: SearchArgs) => state.projectItems.deletedById,
    (items, user, deletedById): SavedItem[] => {
      return items.map(i => mapItem(i, {
        isOwner: i.createdBy.userId === user.userId,
        isDeleted: _has(deletedById, i.folderItemId)
      }));
    }
  )
};

function getItemKey(item: Item): string {
  return item.folderItemId.toString();
}

function getStoreKey(args: SearchArgs, pageSize: number): string {
  const parts: string[] = [];

  if (args.projectId > 0) {
    parts.push(`p:${args.projectId}`);
  }

  if (args.folderId > 0) {
    parts.push(`f:${args.folderId}`);
  }

  parts.push(`s:${pageSize}`);

  if (parts.length === 0) {
    return 'all';
  } else {
    return parts.join('/');
  }
}

type Page = {
  count: number;
  items: Item[];
};

function mapPage(page: TrykApi.Catalog.Favorites.ISearchPage): Page {
  return {
    count: page.count,
    items: page.items.map(itemId => toItem(itemId, page))
  };
}

type ItemLookups = {
  colorways: _.Dictionary<TrykApi.Catalog.Favorites.IColorway>;
  rugs: _.Dictionary<TrykApi.Catalog.Favorites.IRug>;
};

function toItem(id: number, args: ItemLookups): Item {
  if (_has(args.colorways, id)) {
    return args.colorways[id];
  } else if (_has(args.rugs, id)) {
    return args.rugs[id];
  } else {
    throw `Error: No item found for ${id}.`;
  }
}
