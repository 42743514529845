import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

export const LOADING_SHIPPING_HISTORY = 'USER_LOADING_SHIPPING_HISTORY';
export const LOADED_SHIPPING_HISTORY = 'USER_LOADED_SHIPPING_HISTORY';
export const LOAD_SHIPPING_HISTORY_FAILED = 'USER_LOAD_SHIPPING_HISTORY_FAILED';

export default function loadShippingHistory(): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(loading());

    const request = CatalogClient.User.getShippingHistory();

    request.then(
      x => dispatch(loaded(x)),
      err => dispatch(loadFailed())
    );

    return request;
  };
}

function loading(): UserAction {
  return {
    type: LOADING_SHIPPING_HISTORY
  };
}

function loadFailed(): UserAction {
  return {
    type: LOAD_SHIPPING_HISTORY_FAILED
  };
}

function loaded(shippingHistory: TrykApi.Catalog.IShippingHistory): UserAction {
  return {
    type: LOADED_SHIPPING_HISTORY,
    shippingHistory
  };
}
