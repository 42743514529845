import RootState from 'Store/Root';

import { Props } from './Props';

const fixedColorwaysSelector = (state: RootState, props: Props) => {
  const colorwayState = state.colorways[props.designCode];

  if (!colorwayState || !colorwayState.fixed || !colorwayState.fixed.items) {
    return [];
  }

  return colorwayState.fixed.items;
};

export default fixedColorwaysSelector;
