import _isEmpty from "lodash-es/isEmpty";

import RootState from "Store/Root";
import { getCustomColorwayMapKey } from "Store/Colorways/Reducer/Utils";
import { serializeRgbs } from "Utils/Rgb";
import { serializeYarnCodes } from "Utils/Yarns";

import { Props } from "./Props";

const colorwaySelector = (
  state: RootState,
  props: Props
): TrykApi.Catalog.IColorway => {
  const colorwayState = state.colorways[props.designCode];

  if (!colorwayState) {
    return null;
  }

  if (
    !_isEmpty(props.yarnCodes) ||
    !_isEmpty(props.rgbs) ||
    (props.clutColorwayId &&
      props.clutColorwayId !== '')
  ) {
    const key = getCustomColorwayMapKey({
      designCode: props.designCode,
      yarns: serializeYarnCodes(props.yarnCodes),
      rgbs: serializeRgbs(props.rgbs),
      refColor: props.refColorCode,
      clutColorwayId: props.clutColorwayId
    });
    const customState = colorwayState.customs[key];

    if (!customState) {
      return null;
    }
    return customState.item;
  } else {
    if (!colorwayState.fixed || !colorwayState.fixed.items) {
      return null;
    }

    let colorway: TrykApi.Catalog.IColorway = null;

    if (props.colorCode) {
      colorway = colorwayState.fixed.items.find(
        ({ colorCode }) =>
          colorCode && colorCode.toUpperCase() === props.colorCode
      );
    } else if (props.refColorCode) {
      colorway = colorwayState.fixed.items.find(
        ({ colorCode }) =>
          colorCode && colorCode.toUpperCase() === props.refColorCode
      );
    }

    if (!colorway) {
      colorway = colorwayState.fixed.items.find(x => x.isDefault) || colorwayState.fixed.items[0];
    }

    if (
      colorway.engineId === 9 &&
      !colorway.yarns.length &&
      !colorway.rgbs.length
    ) {
      // If we are a printed pattern with no yarns, check if we have artwork RGBs and
      // slip those in for use as the colorways RGB palette since these aren't stored
      // with the colorway at the moment.
      const rgbs = colorwayState.fixed.palettes[colorway.colorCode] || [];

      if (rgbs.length > 0) {
        colorway = {
          ...colorway,
          rgbs
        };
      }
    }

    return colorway;
  }
};

export default colorwaySelector;
