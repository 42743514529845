import {
  LOADED_EXCLUSIVE_DESIGNS,
  LOADING_EXCLUSIVE_DESIGNS,
  LOAD_EXCLUSIVE_DESIGNS_FAILED,
  ExclusiveDesignsAction
} from './Actions';

export interface ExclusiveDesignsState {
  items: { [id: number]: TrykApi.Catalog.ISearchResult };
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: ExclusiveDesignsState = {
  items: {},
  loading: false,
  loaded: false,
  failed: false
};

export default function exclusiveDesignsReducer(state = initialState, action: ExclusiveDesignsAction): ExclusiveDesignsState {
  switch (action.type) {
    case LOADED_EXCLUSIVE_DESIGNS:
      return {
        items: action.items,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_EXCLUSIVE_DESIGNS:
      return {
        items: {},
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_EXCLUSIVE_DESIGNS_FAILED:
      return {
        items: {},
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
