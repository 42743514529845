import RootState from 'Store/Root';

import {
  EngineLibrariesMap,
  EnginePalettesMap
} from 'Types/Yarns';

import { LoadingStatus } from 'Types/Common';

export const loadingStatusSelector = (state: RootState): LoadingStatus => {
  return {
    loading: state.yarnLibraries.loading,
    loaded: state.yarnLibraries.loaded,
    failed: state.yarnLibraries.failed
  };
};

export const yarnLibrariesSelector = (state: RootState): TrykApi.Yarns.IYarnLibrary[] => {
  if (!state.yarnLibraries || !state.yarnLibraries.items) {
    return [];
  }

  return state.yarnLibraries.items;
};

export const engineLibrariesMapSelector = (state: RootState): EngineLibrariesMap => {
  if (!state.yarnLibraries || !state.yarnLibraries.engineLibraryMap) {
    return {};
  }

  return state.yarnLibraries.engineLibraryMap;
};

export const enginePalettesMapSelector = (state: RootState): EnginePalettesMap => {
  if (!state.yarnLibraries.enginePaletteMap) {
    return {};
  }

  return state.yarnLibraries.enginePaletteMap;
};
