import { createSelector } from 'reselect';

import { PatternOptions } from 'Types/Designs';

import colorwayOptionsSelector from './Colorways';
import {
  installOptionsGroupSelector,
  installOptionsSelector
} from './Installs';
import roomOptionsSelector, {roomCategoryOptionsSelector} from './Rooms';
import textureOptionsSelector from './Textures';

const patternOptionsSelector = createSelector(
  colorwayOptionsSelector,
  installOptionsSelector,
  installOptionsGroupSelector,
  roomOptionsSelector,
  roomCategoryOptionsSelector,
  textureOptionsSelector,
  (colorways, installs, installsBySize, rooms, roomCategories, textures): PatternOptions => {
    return {
      colorways,
      installs,
      installsBySize,
      rooms,
      roomCategories,
      textures
    };
  }
);

export default patternOptionsSelector;
