import { SurfacePosition } from "./Designs";

export interface PrintScaleOption {
  ratio: string;
  percentage: string;
  value: number;
  thumbnailUrl: string;
}

export interface BasketItemState {
  id: string;
  layoutBrandId: number;
  productId: number;
  roomId: number;
}

export interface ColorwayState extends BasketItemState {
  colorway: TrykApi.Catalog.IColorway;
  installId: number;
  printScale: number;
  swatchOffset: TrykApi.Catalog.ITrykSwatchOffset;
  textureId: number;
  areaRug: TrykApi.Catalog.ITrykAreaRugRequest;
  clutColorwayId?: string;
  rotation: number;
  position: SurfacePosition;
}

export interface RugState extends BasketItemState {
  rug: TrykApi.Catalog.IRug;
  printScale: number;
}

export type BasketItemStateType = ColorwayState | RugState;
export type BasketItemStateList = BasketItemStateType[];

export function isColorwayState(state: ColorwayState | RugState): state is ColorwayState {
  return (<ColorwayState>state).colorway !== undefined;
}

export function isRugState(state: ColorwayState | RugState): state is RugState {
  return (<RugState>state).rug !== undefined;
}

export interface BasketItem {
  id: string;
  display: BasketItemDisplay;
  brand: TrykApi.Catalog.ISiteBrand;
  product: TrykProductOption;
  room: RoomSceneOption;
}

export interface BasketItemColorway extends BasketItem {
  colorway: TrykApi.Catalog.IColorway;
  install: DesignInstallOption;
  printScale: PrintScaleOption;
  swatchOffset: TrykApi.Catalog.ITrykSwatchOffset;
}

export interface BasketItemRug extends BasketItem {
  rug: TrykApi.Catalog.IRug;
  printScale: PrintScaleOption;
}

export type BasketItemType = BasketItemColorway | BasketItemRug;
export type BasketItemList = BasketItemType[];

export function isColorwayItem(item: BasketItemColorway | BasketItemRug): item is BasketItemColorway {
  return (<BasketItemColorway>item).colorway !== undefined;
}

export function isRugItem(item: BasketItemColorway | BasketItemRug): item is BasketItemRug {
  return (<BasketItemRug>item).rug !== undefined;
}

export type BasketItemDisplay = {
  primaryDisplay: string;
  secondaryDisplay: string;

  thumbnailUrl: string;
};

export interface TrykProductOption extends TrykApi.Catalog.ITrykProduct {
  thumbnailUrl: string;
  hasSwatch: boolean;
  hasRoom: boolean;
}

export interface RoomSceneOption extends TrykApi.Catalog.IRoomScene {
  thumbnailUrl: string;
}

export interface DesignInstallOption extends TrykApi.Catalog.IDesignInstall {
  thumbnailUrl: string;
}

export interface EmailDestinationOption extends TrykApi.Catalog.IEmailDestination {
  id: string;
}

export interface PhysicalDestinationOption extends TrykApi.Catalog.IPhysicalDestinationAddress {
  id: string;
}

export type AvailableItemOptions = {
  brands: TrykApi.Catalog.ISiteBrand[];
  installs: DesignInstallOption[];
  printScales: PrintScaleOption[];
  products: TrykProductOption[];
  rooms: RoomSceneOption[];
};

export interface ShippingOptions {
  emailDestinations: EmailDestinationOption[];
  physicalDestinations: PhysicalDestinationOption[];
}
