import * as ImagesClient from '@shawfloors/images-client';

import Config from 'Config';

ImagesClient.init(Config.imagesBaseUrl);

export {
  Types,
  Urls,
  Util
} from '@shawfloors/images-client';
