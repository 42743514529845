import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

export const LOADING_ORDER_DETAILS = 'LOADING_ORDER_DETAILS';
export const LOADED_ORDER_DETAILS = 'LOADED_ORDER_DETAILS';
export const LOAD_ORDER_DETAILS_FAILED = 'LOAD_ORDER_DETAILS_FAILED';

type OrderOverview = TrykApi.Catalog.OrderHistory.IOrderOverview;
type TrykOverview = TrykApi.Catalog.OrderHistory.ITrykOverview;

export function loadOrderDetails(orderId: number): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(loading(orderId));

    return Promise.all([
      CatalogClient.OrderHistory.getOrderById(orderId),
      CatalogClient.OrderHistory.getTryksInOrder(orderId)
    ]).then(
      x => dispatch(loaded(x[0], x[1])),
      e => dispatch(loadFailed(orderId))
    );
  };
}

function loading(orderId: number): UserAction {
  return {
    type: LOADING_ORDER_DETAILS,
    orderDetail: {
      orderId: orderId
    }
  };
}

function loadFailed(orderId: number): UserAction {
  return {
    type: LOAD_ORDER_DETAILS_FAILED,
    orderDetail: {
      orderId: orderId
    }
  };
}

function loaded(order: OrderOverview, tryks: TrykOverview[]): UserAction {
  return {
    type: LOADED_ORDER_DETAILS,
    orderDetail: {
      orderId: order.orderId,
      order: order,
      tryks: tryks
    }
  };
}
