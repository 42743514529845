import {
  LOADING_CUSTOM_COLORWAY,
  LOAD_CUSTOM_COLORWAY_FAILED,
  LOADED_CUSTOM_COLORWAY,
  ColorwayAction
} from '../Actions';

interface CustomColorwayState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  item: TrykApi.Catalog.IColorway;
}

export default CustomColorwayState;

const initialState: CustomColorwayState = {
  loading: false,
  loaded: false,
  error: false,
  item: null
};

export function customColorway(state = initialState, action: ColorwayAction) {
  switch (action.type) {
    case LOADING_CUSTOM_COLORWAY:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_CUSTOM_COLORWAY_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    case LOADED_CUSTOM_COLORWAY:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        item: {
          ...action.item,
          colorCode: action.refColor || action.item.colorCode
        }
      };
    default:
      return state;
  }
}
