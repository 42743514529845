import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_COLLECTIONS = 'LOADING_COLLECTIONS';
export const LOADED_COLLECTIONS = 'LOADED_COLLECTIONS';
export const LOAD_COLLECTIONS_FAILED = 'LOADED_COLLECTIONS_FAILED';

export interface CollectionsAction {
  type: string;
  collections?: TrykApi.Catalog.ICollection[];
}

export function loadCollections(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.collections.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loadingCollections());

    return CatalogClient.Global.getCollections()
      .then(
      c => dispatch(collectionsLoaded(c)),
      e => dispatch(collectionsLoadFailed())
      );
  };
}

function loadingCollections(): CollectionsAction {
  return {
    type: LOADING_COLLECTIONS
  };
}

function collectionsLoadFailed(): CollectionsAction {
  return {
    type: LOAD_COLLECTIONS_FAILED
  };
}

function collectionsLoaded(collections: TrykApi.Catalog.ICollection[]): CollectionsAction {
  return {
    type: LOADED_COLLECTIONS,
    collections
  };
}
