import { ThunkAction } from 'redux-thunk';

import { Favorites as FavoritesClient } from 'Clients/Catalog';

import RootState from 'Store/Root';

import { decrementFolderItemCount } from 'Store/Projects/Actions';

import { ProjectItemsAction } from './Action';

export const DELETING_PROJECT_ITEM = 'DELETING_PROJECT_ITEM';
export const DELETED_PROJECT_ITEM = 'DELETED_PROJECT_ITEM';
export const DELETE_PROJECT_ITEM_FAILED = 'DELETE_PROJECT_ITEM_FAILED';

export function deleteProjectItem(itemId: number): ThunkAction<Promise<any>, RootState, ProjectItemsAction> {
  return (dispatch, getState) => {
    dispatch({
      type: DELETING_PROJECT_ITEM,
      itemId
    });

    return FavoritesClient.deleteItem(itemId)
      .then(
      item => {
        dispatch({
          type: DELETED_PROJECT_ITEM,
          itemId
        });
        dispatch(decrementFolderItemCount(item.folderId));
      },
      err => dispatch({
        type: DELETE_PROJECT_ITEM_FAILED,
        itemId
      })
      );
  };
}
