import { createSelector } from 'reselect';

import _isEmpty from 'lodash-es/isEmpty';

import * as CatalogClient from 'Clients/Catalog';
import * as Enums from 'Clients/Catalog/Enums';

import RootState from 'Store/Root';
import { createPaginationActions } from 'Store/Pagination/Actions';
import createPaginationSelectors from 'Store/Pagination/Selectors';

import { engineIdSelector } from './Selectors/EngineId';
import { overviewsSelector } from './Selectors/Overviews';

import { QueryArgs } from 'Types/Rugs';

const PAGINATION_STORE_NAME: string = 'rugComponentSearch';
export const PAGE_SIZE: number = 25;

export type SearchArgs = {
  collectionId: number;
  engineIds: number[];
  orderBy: string[];
  query: string;
  sectionId: number;
  repeatHeight: number;
};

export const defaultArgs: SearchArgs = {
  collectionId: 0,
  engineIds: [
    Enums.Engines.Id.Cyp,
    Enums.Engines.Id.Print
  ],
  orderBy: [
    'SortOrder',
    'DesignCode'
  ],
  query: '',
  sectionId: 0,
  repeatHeight: 0
};

export const actions = createPaginationActions<TrykApi.Catalog.ISearchResult, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  loadPage: (page, opts) => CatalogClient.Product.getSearchPage({
    collectionIds: opts.collectionId > 0 ? [opts.collectionId] : [],
    engineIds: opts.engineIds || [],
    orderBy: opts.orderBy,
    page: page.index,
    pageSize: page.size,
    query: opts.query,
    sectionId: opts.sectionId,
    repeatHeight: opts.repeatHeight > 0 ? opts.repeatHeight : 0
  })
});

export const selectors = createPaginationSelectors<TrykApi.Catalog.ISearchResult, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  pageSize: PAGE_SIZE
});

export const searchArgsSelector = createSelector(
  (state: RootState, query: QueryArgs) => state.rug,
  (state: RootState, query: QueryArgs) => query,
  overviewsSelector,
  engineIdSelector,
  (rug, query, overviews, engineId): SearchArgs => {
    const getSectionId = (sectionId: number): number => {
      const isFill = [Enums.Sections.Id.Fill, Enums.Sections.Id.Infill, Enums.Sections.Id.Outfill].indexOf(sectionId) !== -1;
      return isFill ? Enums.Sections.Id.Fill : sectionId;
    };

    if (overviews.length === 0 || engineId <= 0) {
      return {
        ...defaultArgs,
        collectionId: rug.componentCollectionId,
        query: rug.componentQuery,
        sectionId: getSectionId(rug.componentSectionId)
      };
    }

    return {
      ...defaultArgs,
      collectionId: rug.componentCollectionId,
      engineIds: [engineId],
      query: rug.componentQuery,
      repeatHeight: rug.componentSizeFilter,
      sectionId: getSectionId(rug.componentSectionId)
    };
  }
);

function getItemKey(item: TrykApi.Catalog.ISearchResult): string {
  return `${item.designId}/${item.colorwayId}`;
}

function getStoreKey(args: SearchArgs, pageSize: number): string {
  const hasCollections = args.collectionId > 0,
    hasEngine = !_isEmpty(args.engineIds),
    hasQuery = !!args.query,
    hasSection = args.sectionId > 0,
    hasRepeatHeight = args.repeatHeight > 0;

  const parts: string[] = [];

  if (hasCollections) {
    parts.push(`c:${args.collectionId}`);
  }

  if (hasEngine) {
    parts.push(`e:${args.engineIds.join(',')}`);
  }

  if (hasQuery) {
    parts.push(`q:${args.query.toLowerCase()}`);
  }

  if (hasSection) {
    parts.push(`s:${args.sectionId}`);
  }

  if (hasRepeatHeight) {
    parts.push(`rw:${args.repeatHeight}`);
  }

  if (parts.length === 0) {
    return 'all';
  } else {
    return parts.join('/');
  }
}
