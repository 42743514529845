import _pick from 'lodash-es/pick';

import * as Storage from 'Utils/Storage';

import {
  ColorwayState,
  RugState,
  ShippingOptions
} from 'Types/Basket';

const STORAGE_KEY = 'basket.storage.v2';

export type BasketStateStorage = {
  itemsList: string[];
  colorwaysById: _.Dictionary<ColorwayState>;
  rugsById: _.Dictionary<RugState>;
};

const STORAGE_PROPS = [
  'itemsList',
  'colorwaysById',
  'rugsById'
];

export function saveItemState(state: BasketStateStorage): void {
  const storage = _pick(state, STORAGE_PROPS);
  Storage.setItem(STORAGE_KEY, storage, true);
}

export function getItemState(): BasketStateStorage {
  return Storage.getItem<BasketStateStorage>(STORAGE_KEY) || {
    itemsList: [],
    colorwaysById: {},
    rugsById: {}
  };
}

export function saveShippingOptions(userId: number, options: ShippingOptions): void {
  Storage.setItem(makeShippingOptionsKey(userId), options, true);
}

export function getShippingOptions(userId: number): ShippingOptions {
  return Storage.getItem<ShippingOptions>(makeShippingOptionsKey(userId));
}

export function clearShippingOptions(userId: number): void {
  Storage.removeItem(makeShippingOptionsKey(userId));
}

export function reset(userId: number): void {
  Storage.removeItem(STORAGE_KEY);
  clearShippingOptions(userId);
}

function makeShippingOptionsKey(userId: number): string {
  return `basket.shipping-options.user:${userId}.v1`;
}
