import {
  EngineLibrariesMap,
  EnginePalettesMap
} from 'Types/Yarns';

import {
  LOADED_YARN_LIBRARIES,
  LOADING_YARN_LIBRARIES,
  LOAD_YARN_LIBRARIES_FAILED,
  YarnLibrariesAction
} from './Actions';

export interface YarnLibrariesState {
  items: TrykApi.Yarns.IYarnLibrary[];
  engineLibraryMap: EngineLibrariesMap;
  enginePaletteMap: EnginePalettesMap;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: YarnLibrariesState = {
  items: [],
  engineLibraryMap: {},
  enginePaletteMap: {},
  loading: false,
  loaded: false,
  failed: false
};

export default function yarnLibrariesReducer(state = initialState, action: YarnLibrariesAction): YarnLibrariesState {
  switch (action.type) {
    case LOADED_YARN_LIBRARIES:
      return {
        items: action.items.concat(),
        engineLibraryMap: action.libraryMap,
        enginePaletteMap: action.paletteMap,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_YARN_LIBRARIES:
      return {
        items: [],
        engineLibraryMap: {},
        enginePaletteMap: {},
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_YARN_LIBRARIES_FAILED:
      return {
        items: [],
        engineLibraryMap: {},
        enginePaletteMap: {},
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
