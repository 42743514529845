import {
  LOADED_RECENTLY_USED_DESIGNS,
  LOADING_RECENTLY_USED_DESIGNS,
  LOAD_RECENTLY_USED_DESIGNS_FAILED,
  RecentlyUsedDesignsAction
} from './Actions';

import RecentlyUsedDesignsState, { initialState } from './State';

export default function recentlyUsedDesignsReducer(state = initialState, action: RecentlyUsedDesignsAction): RecentlyUsedDesignsState {
  switch (action.type) {
    case LOADED_RECENTLY_USED_DESIGNS:
      return {
        items: action.items,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_RECENTLY_USED_DESIGNS:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_RECENTLY_USED_DESIGNS_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
