import React from 'react';

import Raven from 'raven-js';

import {Props} from './Props';
import {pushAnalytics} from 'Utils/Analytics';

export default class SentryContextManager extends React.Component<Props, {}> {
  componentDidMount() {
    updateUserContext(this.props.account);
  }

  componentWillReceiveProps(nextProps: Props) {
    updateUserContext(nextProps.account, this.props.account);
  }

  render() {
    if (this.props.children) {
      return React.Children.only(this.props.children);
    } else {
      return null;
    }
  }
}

function updateUserContext(account: TrykApi.Catalog.IAccount, last: TrykApi.Catalog.IAccount = null) {
  const clear = () => Raven.setUserContext();
  const setContext = ({userId, username}: TrykApi.Catalog.IAccount) => {

    pushAnalytics('dataLayer-initialized', {
      visitorType: 'returning',
      dealerType: 'undefined',
      consumerType: 'undefined',
      userId: userId.toString()
    });

    return (
      Raven.setUserContext({
        username,
        id: userId.toString(),
        email: username
      })
    );
  };

  if (account && last && account.userId !== last.userId) {
    setContext(account);
  } else if (account && !last) {
    setContext(account);
  } else if (!account) {
    clear();
  }
}
