import _isPlainObject from 'lodash-es/isPlainObject';
import _range from 'lodash-es/range';
import _uniq from 'lodash-es/uniq';

import { defer } from './Promise';

export function hasItem(key: string): boolean {
  let result = false;

  if (window.Modernizr.localstorage) {
    result = window.localStorage.getItem(key) !== null;
  }

  if (!result && window.Modernizr.sessionstorage) {
    result = window.sessionStorage.getItem(key) !== null;
  }

  return result;
}

export function getItem<T>(key: string): T {
  let item: string = null;

  if (window.Modernizr.localstorage) {
    item = window.localStorage.getItem(key);
  }

  if (item === null && window.Modernizr.sessionstorage) {
    item = window.sessionStorage.getItem(key);
  }

  if (item === null || !isJsonString(item)) {
    return null;
  }

  return JSON.parse(item);
}

export function setItem(key: string, item: any, persist?: boolean): void {
  if (_isPlainObject(item) || Array.isArray(item)) {
    item = JSON.stringify(item);
  }

  if (persist && window.Modernizr.localstorage) {
    window.localStorage.setItem(key, item);

    if (window.Modernizr.sessionstorage) {
      window.sessionStorage.removeItem(key);
    }
  } else if (window.Modernizr.sessionstorage) {
    window.sessionStorage.setItem(key, item);

    if (window.Modernizr.localstorage) {
      window.localStorage.removeItem(key);
    }
  }
}

export function addOrGetItemAsync<T>(key: string, promiseFn: () => Promise<T>, persist?: boolean): Promise<T> {
  const deferred = defer<T>();

  if (hasItem(key)) {
    deferred.resolve(getItem<T>(key));
  } else {
    promiseFn().then((item) => {
      try {
        setItem(key, item, persist);
      } catch (err) {
        // Note: It's important to handle exceptions as it's possible we exceed the browser's quota for storage (5mb for Chrome).
        console.log('setItem failed!', err);
      }
      deferred.resolve(item);
    }, (err) => {
      deferred.reject(err);
    }).catch((err) => {
      deferred.reject(err);
    });
  }

  return deferred.promise;
}

export function removeItem(key: string): void {
  if (window.Modernizr.sessionstorage) {
    window.sessionStorage.removeItem(key);
  }

  if (window.Modernizr.localstorage) {
    window.localStorage.removeItem(key);
  }
}

export function getKeys(): string[] {
  const keys = [].concat(
    getSessionStorageKeys(),
    getLocalStorageKeys());

  return _uniq(keys);
}

export function clear(): void {
  window.Modernizr.localstorage && window.localStorage.clear();
  window.Modernizr.sessionstorage && window.sessionStorage.clear();
}

function getLocalStorageKeys(): string[] {
  let keys: string[] = [];

  if (window.Modernizr.localstorage) {
    keys = _range(window.localStorage.length).map((x) => window.localStorage.key(x));
  }

  return keys;
}

function getSessionStorageKeys(): string[] {
  let keys: string[] = [];

  if (window.Modernizr.sessionstorage) {
    keys = _range(window.sessionStorage.length).map((x) => window.sessionStorage.key(x));
  }

  return keys;
}

function isJsonString(raw: string): boolean {
  try {
    JSON.parse(raw);
  } catch (e) {
    return false;
  }
  return true;
}
