import { ThunkAction } from 'redux-thunk';

import RootState from 'Store/Root';

import { addColorwayItem } from 'Store/Basket/Actions';
import { loadFromQuery } from 'Store/Designs/Actions';
import { paramsSelector } from 'Store/Designs/Selectors';

import { mapColorwayToQuery } from '../../Utils';
import { ProjectItemsAction } from '../Action';

import {
  ADDING_PROJECT_ITEM_TO_BASKET,
  ADDED_PROJECT_ITEM_TO_BASKET,
  ADD_PROJECT_ITEM_TO_BASKET_FAILED
} from './Item';

export function addColorwayToBasket(colorway: TrykApi.Catalog.Favorites.IColorway): ThunkAction<Promise<any>, RootState, ProjectItemsAction> {
  return (dispatch, getState) => {
    const itemAction = (type: string) => {
      dispatch({
        type,
        itemId: colorway.folderItemId
      });
    };

    itemAction(ADDING_PROJECT_ITEM_TO_BASKET);

    const args = mapColorwayToQuery(colorway);

    return dispatch(loadFromQuery(args)).then(
      x => {
        const state = getState();
        const params = paramsSelector(state, args);

        const success = dispatch(addColorwayItem(params.colorway, {
          roomId: params.room.roomId,
          installId: params.install.installId,
          textureId: colorway.textureId,
          clutColorwayId: colorway.clutColorwayId,
          areaRug: null // TODO: Implement custom rug in My Saved Designs.
        }));

        if (success) {
          itemAction(ADDED_PROJECT_ITEM_TO_BASKET);
        } else {
          itemAction(ADD_PROJECT_ITEM_TO_BASKET_FAILED);
        }

        return success;
      },
      err => itemAction(ADD_PROJECT_ITEM_TO_BASKET_FAILED)
    );
  };
}
