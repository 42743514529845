// Entry point for Sentry config and install.
// https://sentry.io/tricycle/
import Raven from 'raven-js';
import Config from 'Config';

let dsn = Config.sentryId;

// Disable in dev enironment by not passing our DSN into the config.
if (Config.environment === 'development') {
  dsn = '';
}

Raven.config(dsn, {
  environment: Config.environment,
  release: Config.version
}).install();
