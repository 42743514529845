import {
  RESETTING_CACHE,
  RESET_CACHE_FAILED,
  RESET_CACHE_SUCCESS,
  AdminAction
} from './Actions';

export interface ResetCacheState {
  loading: boolean;
  didSucceed: boolean;
  didFail: boolean;
}

export interface AdminState {
  resetCache: ResetCacheState;
}

const initialState: AdminState = {
  resetCache: {
    loading: false,
    didSucceed: false,
    didFail: false
  }
};

function adminReducer(state = initialState, action: AdminAction): AdminState {
  switch (action.type) {
    case RESETTING_CACHE:
      return {
        ...state,
        resetCache: {
          loading: true,
          didSucceed: false,
          didFail: false
        }
      };
    case RESET_CACHE_FAILED:
      return {
        ...state,
        resetCache: {
          loading: false,
          didSucceed: false,
          didFail: true
        }
      };
    case RESET_CACHE_SUCCESS:
      return {
        ...state,
        resetCache: {
          loading: false,
          didSucceed: true,
          didFail: false
        }
      };
    default:
      return state;
  }
}

export default adminReducer;
