type Environment = (
  'development' | 'staging' | 'production'
);

declare var appConfig: {
  environment: Environment;
  version: string;
  catalogBaseUrl: string;
  imagesBaseUrl: string;
  studioBaseUrl: string;
  trykPdfUrlTemplate: string;
  trackingId: string;
  sentryId: string;

};

interface Site {
  title: string;
  titleTemplate: string;
  oneTrustPrivacy: boolean;
  draftPrivacy: boolean;
  showShareProject: boolean;
}

interface Studio {
  allowCustomLayouts: boolean;
  allowCustomTwists: boolean;
  allowLockedRecoloring: boolean;
  useBrandInstalls: boolean;
  newCategoryId: number;
  exclusiveCategoryId: number[];
  roomsBrandId: number;
  rugBrandId: number;
  showBrandOnSearch: boolean;
  showOldContentMessage: boolean;
  trykProductsBrandId: number;
  yarnLibraryBrandId: number;
  enableAreaRugs: boolean;
  requiresLogin: boolean;
  showYarnCoverage: boolean;
  showPomBox: boolean;
  rootCategoriesAsBrands: boolean;
  orderBy: string[];
  swatchRoomId: number;
}

declare var siteConfig: Site;
declare var studioConfig: Partial<Studio>;

interface Config {
  environment: Environment;
  version: string;
  site: Site;
  studio: Studio;
  catalogBaseUrl: string;
  imagesBaseUrl: string;
  studioBaseUrl: string;
  trackingId: string;
  sentryId: string;
  getTrykPdfUrl(trykGuid: string): string;
}

const config: Config = {
  environment: appConfig.environment,
  version: appConfig.version,
  site: { ...siteConfig },
  studio: parseStudioConfig(studioConfig),
  catalogBaseUrl: appConfig.catalogBaseUrl,
  imagesBaseUrl: appConfig.imagesBaseUrl,
  studioBaseUrl: appConfig.studioBaseUrl,
  trackingId: appConfig.trackingId,
  sentryId: appConfig.sentryId,
  getTrykPdfUrl: (trykGuid: string): string => {
    return appConfig.trykPdfUrlTemplate.replace('{trykGuid}', trykGuid);
  }
};

export default config;

function parseStudioConfig(config: Partial<Studio>): Studio {
  return {
    allowCustomLayouts: config.allowCustomLayouts || false,
    allowCustomTwists: config.allowCustomTwists || false,
    allowLockedRecoloring: config.allowLockedRecoloring || false,
    useBrandInstalls: config.useBrandInstalls || false,
    newCategoryId: config.newCategoryId || -1,
    exclusiveCategoryId: config.exclusiveCategoryId || [-1],
    roomsBrandId: config.roomsBrandId || -1,
    rugBrandId: config.rugBrandId || -1,
    showBrandOnSearch: config.showBrandOnSearch || false,
    showOldContentMessage: config.showOldContentMessage || false,
    trykProductsBrandId: config.trykProductsBrandId || -1,
    yarnLibraryBrandId: config.yarnLibraryBrandId || -1,
    enableAreaRugs: config.enableAreaRugs || false,
    requiresLogin: config.requiresLogin || false,
    showYarnCoverage: config.showYarnCoverage || false,
    showPomBox: config.showPomBox || false,
    rootCategoriesAsBrands: config.rootCategoriesAsBrands || false,
    orderBy: config.orderBy || ['SortOrder', 'DesignCode'],
    swatchRoomId: config.swatchRoomId || -1,
  };
}
