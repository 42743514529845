import Config from "Config";

import RootState from "Store/Root";
import {
  getInstallsByBrand,
  mapAsDesignInstalls
} from "Store/InstallTemplates/Utils";

type Args = {
  designCode: string;
  useBrandInstalls?: boolean;
};

const designInstallsSelector = (state: RootState, { designCode, useBrandInstalls }: Args) => {
  const designState = state.designs.itemsByCode[designCode.toUpperCase()];

  if (!designState || !designState.overview) {
    return [];
  }
  const defaultDesignInstall = designState.installs.map(x => {
    if (x.isDefault) return x;
  });
  if (Config.studio.useBrandInstalls || useBrandInstalls) {
    return mapAsDesignInstalls(
      getInstallsByBrand(state.installTemplates, designState.overview.brandId),
      designState.installs ? defaultDesignInstall[0] : null
    );
  } else {
    return designState.installs || [];
  }
};

export default designInstallsSelector;
