import {
  LOADED_RECENTLY_USED_FOLDERS,
  LOADING_RECENTLY_USED_FOLDERS,
  LOAD_RECENTLY_USED_FOLDERS_FAILED,
  RecentlyUsedFoldersAction
} from './Actions';

import RecentlyUsedFoldersState, { initialState } from './State';

export default function recentlyUsedFoldersReducer(state = initialState, action: RecentlyUsedFoldersAction): RecentlyUsedFoldersState {
  switch (action.type) {
    case LOADED_RECENTLY_USED_FOLDERS:
      return {
        items: action.items,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_RECENTLY_USED_FOLDERS:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_RECENTLY_USED_FOLDERS_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
