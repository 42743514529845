import { createSelector } from "reselect";

import * as Enums from "Clients/Catalog/Enums";
import { Urls as StudioUrls } from "Clients/Studio";

import RootState from "Store/Root";
import { siteSelector } from "Store/Site/Selectors";

import { LinkItem } from "Types/Common";
import { Params, PatternInfo, PatternInfoItem } from "Types/Designs";
import { QueryArgs } from "Types/Rugs";

import { getNameWithTileSize } from "Utils/DesignInstalls";

import fixedColorwaysSelector from "./FixedColorways";
import paramsSelector from "./Params";
import { Props } from "./Props";
import Config from "Config";

export const overviewSelector = (
  state: RootState,
  props: Props
): TrykApi.Catalog.IDesignOverview => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return null;
  }

  return designState.overview;
};

const designInstallsSelector = (
  state: RootState,
  props: Props
): TrykApi.Catalog.IDesignInstall[] => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.installs) {
    return [];
  }

  return designState.installs;
};

const exclusiveDesignsSelector = (state: RootState, props: Props): boolean => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return false;
  }

  if (designState.overview.isExclusive === true) {
    return true;
  }

  return designState.overview.categoryIds.some(
    x => Config.studio.exclusiveCategoryId.some(y => y === x)
  );
};

const conceptDesignsSelector = (state: RootState, props: Props): boolean => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return false;
  }

  return designState.overview.isConcept;
};

const designConfigurationSelector = (state: RootState, props: Props): string => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return '';
  }

  return designState.overview.designConfiguration;
};

const patternInfoSelector = createSelector(
  siteSelector,
  overviewSelector,
  designInstallsSelector,
  fixedColorwaysSelector,
  exclusiveDesignsSelector,
  conceptDesignsSelector,
  paramsSelector,
  designConfigurationSelector,
  (
    site,
    overview,
    designInstalls,
    fixedColorways,
    isExclusive,
    isConcept,
    params,
    designConfiguration
  ): PatternInfo => {
    if (!site || !overview || !params.colorway || !params.install) {
      return null;
    }
    const result: PatternInfo = {
      primaryHeading: overview.code,
      secondaryHeading:
        overview.name && overview.code !== overview.name ? overview.name : "",
      items: [],
      links: [],
      notices: (overview.notices || []).concat(),
      exclusiveDesign: isExclusive,
      isConcept: isConcept,
      lastGenerated: overview.lastGenerated
    };

    if (site.brands.length > 1) {
      result.items.push({
        label: "Brand",
        value: overview.brandName
      });
    }

    const sections = filterByValidSections(overview.sectionIds);

    if (params.customRug) {
      result.items.push({
        label: "Rug Size",
        value: `${params.customRug.rugWidth}" x ${params.customRug.rugHeight}"`
      });

      if (params.customRug.rugShape.toLowerCase() === "rect") {
        result.items.push({
          label: "Rug Shape",
          value: params.customRug.rugWidth === params.customRug.rugHeight ? "Square" : "Rectangle"
        });
      } else if (params.customRug.rugShape.toLowerCase() === "circle") {
        result.items.push({
          label: "Rug Shape",
          value: params.customRug.rugWidth === params.customRug.rugHeight ? "Circle" : "Oval"
        });
      }
    }

    if (overview.engineId === 5 || overview.engineId === 11) {
      if ((typeof overview.designQuality === 'string') && (overview.designQuality.trim().length > 0)) {
        result.items.push({
          label: 'Design Quality',
          value: overview.designQuality
        });
      }
    }

    if (params.install.installId === 1) {
      if (!params.customRug) {
        result.items.push({
          label: sections.some(x => x === Enums.Sections.Id.Rug)
            ? "Size"
            : "Repeat Size",
          value: overview.displayRepeatSize
        });

        if (overview.displayRepeatSizeAlt) {
          result.items.push({
            label: "",
            value: overview.displayRepeatSizeAlt
          });
        }
      }

      if (designInstalls.length > 1) {
        result.items.push(getInstallItem(params.install));
      }
    } else {
      result.items.push(getInstallItem(params.install));
    }

    let typeItem: PatternInfoItem = null;

    if (sections.length > 0) {
      typeItem = {
        label: "Type",
        value: sections.map(x => Enums.Sections.getName(x)).join(", ")
      };
    }

    if (params.colorway.rgbs.length > 0) {
      typeItem && result.items.unshift(typeItem);
      result.items.unshift(getConstructionItem(params.colorway, designConfiguration));
    } else if (designConfiguration !== null && designConfiguration.length > 0) {
      result.items.push(getConstructionItem(params.colorway, designConfiguration));
      typeItem && result.items.push(typeItem);
    } else {
      result.items.push(getConstructionItem(params.colorway, designConfiguration));
      typeItem && result.items.push(typeItem);
    }

    // if (fixedColorways.length > 1) {
    result.items.push({
      label: "Colorway",
      value: params.colorway.colorName
    });
    // }

    if (params.colorway.threadingSequences && params.colorway.threadingSequences.length > 0) {
      result.items.push(...params.colorway.threadingSequences.map<PatternInfoItem>(x => ({ label: x.needleBar, value: x.sequence })));
    }

    const rugLink = getRugLink(overview, params);

    if (rugLink) {
      result.links.push(rugLink);
    }

    if (overview.isBroadloom === true && overview.isModular === true) {
      result.items.push({
        label: 'Category',
        value: 'Broadloom and tile'
      });
    }
    else if (overview.isBroadloom === true) {
      result.items.push({
        label: 'Category',
        value: 'Broadloom'
      });
    }
    else if (overview.isModular === true) {
      result.items.push({
        label: 'Category',
        value: 'Tile'
      });
    }

    const usageItem = getUsageItem(params);

    if (usageItem) {
      result.items.push(usageItem);
    }

    return result;
  }
);

export default patternInfoSelector;

const filterByValidSections = (sectionIds: number[]): number[] => {
  const validTypes = [
    Enums.Sections.Id.Border,
    Enums.Sections.Id.Corner,
    Enums.Sections.Id.Crown,
    Enums.Sections.Id.Medallion,
    Enums.Sections.Id.Rug,
    Enums.Sections.Id.Runner
  ];

  return sectionIds.filter(x => validTypes.indexOf(x) !== -1);
};

const getInstallItem = (
  install: TrykApi.Catalog.IDesignInstall
): PatternInfoItem => {
  return {
    label: "Installation",
    value: getNameWithTileSize(install)
  };
};

const getConstructionItem = (
  colorway: TrykApi.Catalog.IColorway,
  designConfiguration: string
): PatternInfoItem => {
  return {
    label: "Construction",
    value: ((): string => {
      if (colorway.rgbs.length > 0) {
        return "Canvas";
      } else if (designConfiguration !== null && designConfiguration.length > 0) {
        return designConfiguration;
      }
      else {
        return Enums.Engines.getName(colorway.engineId);
      }
    })()
  };
};

const getRugLink = (
  overview: TrykApi.Catalog.IDesignOverview,
  params: Params
): LinkItem => {
  if (params.install.installId !== 1) {
    return null;
  } else if (!Enums.Engines.isValidRug(overview.engineId)) {
    return null;
  } else if (!Enums.Sections.isValidRugComponent(overview.sectionIds)) {
    return null;
  } else if (params.colorway.yarns.length === 0) {
    return null;
  }

  const sectionId = ((sections: number[]): number => {
    sections = Enums.Sections.filterByValidRugComponents(sections);

    if (sections.length === 0) {
      return Enums.Sections.Id.Outfill;
    } else if (
      sections.length > 1 &&
      sections.find(id => id === Enums.Sections.Id.Outfill)
    ) {
      return Enums.Sections.Id.Outfill;
    } else {
      return sections[0];
    }
  })(overview.sectionIds);

  const palette = ((yarns: TrykApi.Catalog.IColorwayYarn[]): string[] => {
    return yarns.map(y => {
      if (y.yarnId > 10) {
        return y.code;
      } else if (y.children.length > 0) {
        return `[${y.children.map(c => c.code).join(",")}]`;
      } else {
        throw new Error("Unhandled yarn type.");
      }
    });
  })(params.colorway.yarns);

  const args: QueryArgs = {
    designs: [overview.code],
    palette,
    positions: [params.colorway.yarns.map((x, idx) => idx)],
    sections: [sectionId],
    scales: [1],
    visibles: [true],
    textureId: params.texture && params.texture.textureId,
    roomId: 0,
    viewMode: "2d"
  };

  return {
    label: "Create a custom assembly",
    target: "_blank",
    href: StudioUrls.rug(args)
  };
};

const getUsageItem = (
  params: Params
): PatternInfoItem => {
  // Sum the total percentage usages for each type
  const usages: Map<string, number> = new Map<string, number>();

  params.colorway.colorwayCoverages.forEach((value) => {
    let coverage = value.coveragePercent;

    if (usages.has(value.yarnUsageTypeName)) {
      coverage += usages.get(value.yarnUsageTypeName);
    }

    usages.set(value.yarnUsageTypeName, coverage);
  });

  if (usages.size === 1) {
    // Format the used type
    return {
      label: 'Texture',
      value: [...usages].map(([k, v]) => `${k}`).join(''),
    };
  } else if (usages.size > 1) {
    // Format the total usages
    return {
      label: 'Texture',
      value: [...usages].map(([k, v]) => `${k}:${v.toFixed(0)}%`).join(', '),
    };
  }

  return null;
};
