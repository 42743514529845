import React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { asyncComponent } from 'react-async-component';

import Layout from './Components/Layout';

const App: React.SFC<{}> = () => (
  <Layout>
    <Route path="/" component={AsyncRoutes} />
  </Layout>
);

export default App;

const AsyncRoutes = asyncComponent<RouteComponentProps<{}>>({
  resolve: () => import('./Routes')
    .then(x => x.default)
});
