import { createSelector } from "reselect";

import {
  engineLibrariesMapSelector,
  yarnLibrariesSelector
} from "Store/YarnLibraries/Selectors";
import { getLibrariesByBrand } from "Store/YarnLibraries/Utils";

import colorwaySelector from "./Colorway";

const PIECE_DYED_YARNLIB = 39;

const yarnLibrariesByEngineSelector = createSelector(
  yarnLibrariesSelector,
  engineLibrariesMapSelector,
  colorwaySelector,
  (libs, engineLibsMap, colorway): TrykApi.Yarns.IYarnLibrary[] => {
    if (!libs || !engineLibsMap || !colorway) {
      return [];
    } else if (colorway.enableLockedRecoloring) {
      // restrict to only "hidden" libraries
      var defaultLibIds = Object.keys(engineLibsMap).reduce<number[]>((acc, curr) => acc.concat(engineLibsMap[curr]), []);
      return libs.filter(x => !defaultLibIds.some(y => x.yarnLibraryId === y));
    }

    const validIds = colorway.hasTonalMap
      ? [PIECE_DYED_YARNLIB]
      : engineLibsMap[colorway.engineId.toString()] || [];

    if (validIds.length === 0) {
      return libs;
    }

    const validLibs = validIds
      .filter(x => !!libs.find(y => y.yarnLibraryId === x))
      .map(x => libs.find(y => y.yarnLibraryId === x));

    return getLibrariesByBrand(validLibs, colorway.brandId);
  }
);

export default yarnLibrariesByEngineSelector;

export const yarnLibrariesByBrandSelector = createSelector(
  yarnLibrariesSelector,
  colorwaySelector,
  (libs, colorway): TrykApi.Yarns.IYarnLibrary[] => {
    if (!libs || !colorway) {
      return [];
    }

    return getLibrariesByBrand(libs, colorway.brandId);
  }
)
