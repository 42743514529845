import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_RECENTLY_USED_FOLDERS = 'LOADING_RECENTLY_USED_FOLDERS';
export const LOADED_RECENTLY_USED_FOLDERS = 'LOADED_RECENTLY_USED_FOLDERS';
export const LOAD_RECENTLY_USED_FOLDERS_FAILED = 'LOAD_RECENTLY_USED_FOLDERS_FAILED';

export interface RecentlyUsedFoldersAction {
  type: string;
  items?: TrykApi.Catalog.Favorites.IFolder[];
}

function loading(): RecentlyUsedFoldersAction {
  return {
    type: LOADING_RECENTLY_USED_FOLDERS
  };
}

function loadFailed(): RecentlyUsedFoldersAction {
  return {
    type: LOAD_RECENTLY_USED_FOLDERS_FAILED
  };
}

function loaded(items: TrykApi.Catalog.Favorites.IFolder[]): RecentlyUsedFoldersAction {
  return {
    type: LOADED_RECENTLY_USED_FOLDERS,
    items,
  };
}

export function loadRecentlyUsedFolders(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user.status.loggedIn) return;

    dispatch(loading());

    return CatalogClient.Favorites.getRecentlyUsedFolders().then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
    );
  };
}

export function saveRecentlyUsedFolder(folderId: number): ThunkAction<Promise<any>, RootState, any> {
  return (_, getState) => {
    const state = getState();

    if (!state.user.status.loggedIn) return;

    return CatalogClient.Favorites.saveRecentlyUsedFolder(folderId);
  };
}
