import {
  applyMiddleware,
  compose,
  createStore,
  Middleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import Config from 'Config';

import { loadStoredBasket } from './Basket/Actions';
import preloadAction from './Preload/Actions';
import { rootReducer } from './Root';

const MIDDLEWARES: Middleware[] = [
  thunkMiddleware
];

export function configureStore(preloadedState: any) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(...MIDDLEWARES))
  );

  if (module.hot) {
    module.hot.accept('./Root', () => {
      const nextRoot = require('./Root');
      store.replaceReducer(nextRoot);
    });
  }

  store.dispatch(preloadAction());
  store.dispatch(loadStoredBasket());

  return store;
}

function composeEnhancer(next: Redux.StoreEnhancer<any>): Redux.StoreEnhancer<any> {
  if (Config.environment === 'development' && !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(next);
  }

  return compose(next);
}
