import { createSelector } from 'reselect';

import _isEmpty from 'lodash-es/isEmpty';

import { LoadingStatus } from 'Types/Common';

import RootState from 'Store/Root';
import { getCustomColorwayMapKey } from 'Store/Colorways/Reducer/Utils';
import { serializeRgbs } from 'Utils/Rgb';
import { serializeYarnCodes } from 'Utils/Yarns';

import { Props } from './Props';

export const loadingDesignSelector = (state: RootState, props: Props): LoadingStatus => {
  return {
    failed: !!state.designs.errorsByCode[props.designCode],
    loaded: !!state.designs.itemsByCode[props.designCode],
    loading: !!state.designs.loadingByCode[props.designCode]
  };
};

export const loadingCustomColorwaySelector = (state: RootState, props: Props): LoadingStatus => {
  const colorwayState = state.colorways[props.designCode];

  if (!colorwayState || (_isEmpty(props.yarnCodes) && _isEmpty(props.rgbs))) {
    return {
      loading: false,
      loaded: false,
      failed: false
    };
  }

  const key = getCustomColorwayMapKey({
    designCode: props.designCode,
    rgbs: serializeRgbs(props.rgbs),
    yarns: serializeYarnCodes(props.yarnCodes),
    refColor: props.refColorCode,
    clutColorwayId: props.clutColorwayId
  });
  const customState = colorwayState.customs[key];

  if (!customState) {
    return {
      loading: false,
      loaded: false,
      failed: false
    };
  }

  return {
    loading: customState.loading,
    loaded: customState.loaded,
    failed: customState.error
  };
};

export const loadingSelector = createSelector(
  loadingDesignSelector,
  loadingCustomColorwaySelector,
  (designStatus, colorwayStatus): LoadingStatus => {
    const loaded = designStatus.loaded && colorwayStatus.loaded;
    const loading = !loaded && (designStatus.loading || colorwayStatus.loading);
    const failed = !loading && (designStatus.failed || colorwayStatus.failed);

    return {
      loaded,
      loading,
      failed
    };
  }
);
