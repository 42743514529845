import tinyGif from 'Utils/TinyGif';

export function getPath(install: TrykApi.Catalog.IDesignInstall) {
  if (isTileInstall(install)) {
    return getTileInstallPath(install);
  } else {
    return getRollBasedInstallPath(install);
  }
}

function getTileInstallPath(install: TrykApi.Catalog.IDesignInstall): string {
  if (install.tileSizes.length > 1) {
    console.warn('Multiple tile size install icons are not supported.');
    return tinyGif;
  }

  const tileSize = install.tileSizes[0];
  const target = `${tileSize.widthInches}x${tileSize.heightInches}/${getFileName(install.name)}`;

  const keys = require.context('Assets/Images/Installs', true).keys();

  if (keys.find(x => x === `./${target}`)) {
    return require(`Assets/Images/Installs/${target}`);
  } else {
    return tinyGif;
  }
}

function getRollBasedInstallPath(install: TrykApi.Catalog.IDesignInstall): string {
  const keys = require.context('Assets/Images/Installs', true).keys();

  if (keys.find(x => x === `./${getFileName(install.name)}`)) {
    return require(`Assets/Images/Installs/${getFileName(install.name)}`);
  } else {
    return tinyGif;
  }
}

function isTileInstall(install: TrykApi.Catalog.IDesignInstall): boolean {
  return install.tileSizes.some(x => x.widthInches > 0 && x.heightInches > 0);
}

function getFileName(installName: string) {
  return installName.replace('-', '')
    .replace(/[0-9\.]+[\\"]* [xX] [0-9\.]+[\\"]/, '')
    .replace(/[0-9\.]+[xX][0-9\.]+/, '')
    .replace(/[0-9\.]+"/, '')
    .replace(/"/g, '')
    .replace(/ /g, '');
}
