import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

import loginAction from './Login';
import { pushAnalytics } from 'Utils/Analytics';

export const REGISTERING = 'USER_REGISTERING';
export const REGISTRATION_FAILED = 'USER_REGISTRATION_FAILED';

export default function register(attempt: TrykApi.Catalog.IRegistration, remember: boolean = false): ThunkAction<Promise<any>, any, any> {

  return (dispatch, getState) => {
    dispatch(registering());

    const request = CatalogClient.User.register(attempt);

    request.then(
      x => {

        pushAnalytics('signup', {
          signupStatus: 'complete'
        });

        return dispatch(
          loginAction({
            username: attempt.emailAddress,
            password: attempt.password,
            remember: remember
          }));
      },
      err => {

        pushAnalytics('signup', {
          signupStatus: 'error'
        });

        return dispatch(registrationFailed());
      }
    );

    return request;
  };
}

function registering(): UserAction {
  return {
    type: REGISTERING
  };
}

function registrationFailed(): UserAction {
  return {
    type: REGISTRATION_FAILED
  };
}
