
export const TOGGLE_GRAYSCALE = 'grayscale/TOGGLE';

export interface SearchResultAction {
  type: string;
  enableGrayScale?: boolean;
}

export function toggleGrayScale(enableGrayScale: boolean): SearchResultAction {
  return {
    type: TOGGLE_GRAYSCALE,
    enableGrayScale: enableGrayScale
  };
}
