import {
  LOADING_FIXED_COLORWAYS,
  LOAD_FIXED_COLORWAYS_FAILED,
  LOADED_FIXED_COLORWAYS,
  LOADING_CUSTOM_COLORWAY,
  LOAD_CUSTOM_COLORWAY_FAILED,
  LOADED_CUSTOM_COLORWAY,
  ColorwayAction
} from '../Actions';

import CustomColorwayState, { customColorway } from './Custom';
import FixedColorwaysState, { fixedColorways } from './Fixed';
import { getCustomColorwayMapKey } from './Utils';

interface ColorwayState {
  fixed: FixedColorwaysState;
  customs: { [key: string]: CustomColorwayState };
}

export default ColorwayState;

const initialState: ColorwayState = {
  fixed: {
    loading: false,
    loaded: false,
    error: false,
    items: null,
    palettes: null
  },
  customs: {
  }
};

export function colorway(state = initialState, action: ColorwayAction): ColorwayState {
  switch (action.type) {
    case LOADING_FIXED_COLORWAYS:
    case LOADED_FIXED_COLORWAYS:
    case LOAD_FIXED_COLORWAYS_FAILED:
      return {
        ...state,
        fixed: fixedColorways(state.fixed, action)
      };
    case LOADING_CUSTOM_COLORWAY:
    case LOADED_CUSTOM_COLORWAY:
    case LOAD_CUSTOM_COLORWAY_FAILED:
      return {
        ...state,
        customs: {
          ...state.customs,
          [getCustomKey(action)]: customColorway(state.customs[getCustomKey(action)], action)
        }
      };
    default:
      return state;
  }
}

function getCustomKey({ designCode, refColor, rgbs, yarns, clutColorwayId }: ColorwayAction): string {
  return getCustomColorwayMapKey({ designCode, refColor, rgbs, yarns, clutColorwayId });
}
