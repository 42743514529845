import { createSelector } from 'reselect';

import _isEmpty from 'lodash-es/isEmpty';
import _times from 'lodash-es/times';

import RootState from 'Store/Root';
import { mapOverviewToColorwayYarn } from 'Store/YarnLibraries/Utils';

import { QueryArgs } from 'Types/Rugs';

import { loadingSelector } from './Loading';
import { overviewsSelector } from './Overviews';
import { yarnPaletteSelector } from './Yarns';

const colorwayYarnSelector = createSelector(
  loadingSelector,
  yarnPaletteSelector,
  (status, palette): TrykApi.Catalog.IColorwayYarn[] => {
    if (!status.loaded || _isEmpty(palette)) {
      return [];
    }

    return palette.map(x => mapOverviewToColorwayYarn(x));
  }
);

const yarnPositionsSelector = createSelector(
  loadingSelector,
  colorwayYarnSelector,
  overviewsSelector,
  (state: RootState, query: QueryArgs) => query,
  (status, palette, overviews, query): number[][] => {
    if (!status.loaded || _isEmpty(overviews)) {
      return [];
    }

    return query.designs.map((d, idx) => {
      if (_isEmpty(query.positions[idx])) {
        return _times(overviews[idx].threadCount, x => x % palette.length);
      } else {
        return query.positions[idx];
      }
    });
  }
);

const componentsSelector = createSelector(
  loadingSelector,
  yarnPositionsSelector,
  (state: RootState, query: QueryArgs) => query,
  (status, positions, query): TrykApi.Catalog.IRugComponent[] => {
    if (!status.loaded || _isEmpty(positions)) {
      return [];
    }

    return query.designs.map((d, idx) => ({
      designCode: d,
      sectionId: query.sections[idx],
      scale: query.scales[idx],
      positions: positions[idx]
    }));
  }
);

export const catalogRugSelector = createSelector(
  loadingSelector,
  colorwayYarnSelector,
  componentsSelector,
  (state: RootState, query: QueryArgs) => query.textureId || 0,
  (status, palette, components, textureId): TrykApi.Catalog.IRug => {
    if (!status.loaded || _isEmpty(components)) {
      return null;
    }

    return {
      palette,
      components,
      textureId
    };
  }
);
