import { createSelector } from "reselect";

import _groupBy from "lodash-es/groupBy";
import _map from "lodash-es/map";

import * as InstallIcons from "Clients/Icons/Install";
import { Urls as StudioUrls } from "Clients/Studio";

import {
  mapAndSortWithTileSize,
  sortInstallsByArea
} from "Utils/DesignInstalls";

import { ThumbnailOptionGroup, ThumbnailOptionItem } from "Types/Common";
import { Params } from "Types/Designs";

import designInstallsSelector from "../DesignInstalls";
import paramsSelector from "../Params";

export const installOptionsGroupSelector = createSelector(
  designInstallsSelector,
  paramsSelector,
  (installs, params) => {

    if (params.room != null && params.room.surfaces.some(s => s.name === 'Stair')) {
      return [];
    }

    const sorted = sortInstallsByArea(installs);
    const groups = _groupBy(sorted, x =>
      x.tileSizes.map(y => `${y.widthInches},${y.heightInches}`).join("|")
    );

    return _map(groups, (x, idx) => mapInstallGroup(idx, x, params));
  }
);

export const installOptionsSelector = createSelector(
  designInstallsSelector,
  paramsSelector,
  (installs, params) => {
    const sorted = mapAndSortWithTileSize(installs);

    if (params.room !== null && params.room.surfaces.some(x => x.name === 'Stair')) {

      let result: TrykApi.Catalog.IDesignInstall[] = sorted.filter(x => x.installId === 1);

      return result.map(x => mapInstallToOptionItem(x, params));
    }

    return sorted.map(x => mapInstallToOptionItem(x, params));
  }
);

function mapInstallGroup(
  idx: string,
  installs: TrykApi.Catalog.IDesignInstall[],
  params: Params
): ThumbnailOptionGroup {
  const item = installs[0];
  const labels = item.tileSizes.map(x => {
    if (x.widthInches === 0 || x.heightInches === 0) {
      return "Roll Based";
    } else if (x.widthInches === 28.8 && x.heightInches === 24.94) {
      return 'Hexagon Tiles';
    } else if (x.widthInches === 22.4972 && x.heightInches === 19.4831) {
      return 'Hexagon Tiles (EU)'
    } else if (x.widthInches === 19.685 && x.heightInches === 19.685) {
      return '50cm Tiles';
    } else if (x.widthInches === x.heightInches) {
      return `${x.widthInches}" Tiles`;
    } else {
      return `${x.widthInches}x${x.heightInches}" Tiles`;
    }
  });

  return {
    id: idx,
    label: labels.join(", "),
    options: installs.map(x => mapInstallToOptionItem(x, params))
  };
}

function mapInstallToOptionItem(
  install: TrykApi.Catalog.IDesignInstall,
  params: Params
): ThumbnailOptionItem {
  const result = {
    id: install.installId,
    label: install.name,
    imageUrl: InstallIcons.getPath(install),
    href: getInstallUrl(install, params),
    isActive:
      params && params.install && params.install.installId === install.installId
  };

  return result;
}

function getInstallUrl(
  install: TrykApi.Catalog.IDesignInstall,
  params: Params
): string {
  if (!params.colorway) {
    return "#";
  }

  return StudioUrls.colorway(params.colorway, {
    textureId: params.texture && !params.texture.isDefault && params.texture.textureId,
    clutColorwayId: params.clutColorwayId,
    mutedIndices: params.mutedIndices,
    roomId: params.room && params.room.roomId,
    installId: install.installId,
    viewMode: params.viewMode,
    customRug: params.customRug,
    position: params.position
  });
}
