import * as CatalogClient from '@shawfloors/catalog-client';

import Config from 'Config';

// Important! Initialize the CatalogClient with our
// path to the Catalog API from configuration.
CatalogClient.init(Config.catalogBaseUrl);

import * as Global from './Global';
import * as Product from './Product';
import * as Util from './Util';

import {
  Favorites,
  OrderHistory,
  User
} from '@shawfloors/catalog-client';

export {
  Favorites,
  Global,
  OrderHistory,
  Product,
  User,
  Util,
};
