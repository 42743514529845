import Config from 'Config';

import * as Enums from "Clients/Catalog/Enums";
import RootState from 'Store/Root';
import { CustomRugInfo } from 'Types/Designs';

import { Props } from './Props';

export const customRugEnabledSelector = (state: RootState, props: Props): boolean => {
  const designState = state.designs.itemsByCode[props.designCode];

  if (!designState || !designState.overview) {
    return false;
  }

  const INVALID_SECTIONS = [
    Enums.Sections.Id.Border,
    Enums.Sections.Id.Corner,
    Enums.Sections.Id.Crown,
    Enums.Sections.Id.Medallion,
    Enums.Sections.Id.Rug,
    Enums.Sections.Id.Runner
  ];

  const { sectionIds } = designState.overview;

  if (Config.studio.enableAreaRugs && (!sectionIds || sectionIds.filter(x => INVALID_SECTIONS.indexOf(x) !== -1).length === 0)) {
    return true;
  }

  return false;
};

const customRugSelector = (state: RootState, props: Props): CustomRugInfo => {
  if (props && props.rugWidth > 0 && props.rugHeight > 0) {
    return {
      rugWidth: props.rugWidth,
      rugHeight: props.rugHeight,
      rugShape: (props.rugShape || 'rect').toLowerCase()
    };
  }

  return null;
};

export default customRugSelector;
