import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

import { UserAction } from './index';

export const LOADING_ACCOUNT = 'USER_LOADING_ACCOUNT';
export const LOAD_ACCOUNT_FAILED = 'USER_LOAD_ACCOUNT_FAILED';
export const LOADED_ACCOUNT = 'USER_ACCOUNT_LOADED';

export default function loadAccount(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.user.account.loading) {
      return Promise.resolve(true);
    }

    dispatch(loadingAccount());

    const request = CatalogClient.User.getAccount();

    request.then(
      x => dispatch(accountLoaded(x)),
      e => dispatch(loadAccountFailed())
    );

    return request;
  };
}

function loadingAccount(): UserAction {
  return {
    type: LOADING_ACCOUNT
  };
}

function loadAccountFailed(): UserAction {
  return {
    type: LOAD_ACCOUNT_FAILED
  };
}

function accountLoaded(account: TrykApi.Catalog.IAccount): UserAction {
  return {
    type: LOADED_ACCOUNT,
    account: account
  };
}
