import * as CatalogClient from 'Clients/Catalog';

import { createPaginationActions } from 'Store/Pagination/Actions';
import createPaginationSelectors from 'Store/Pagination/Selectors';

const PAGINATION_STORE_NAME: string = 'orderHistory';
export const PAGE_SIZE: number = 15;

type Order = TrykApi.Catalog.OrderHistory.IOrderOverview;
type Args = TrykApi.Catalog.OrderHistory.IOrderSearchRequest;

const defaultArgs: Args = {};

export const actions = createPaginationActions<Order, Args>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  loadPage: (page, opts = defaultArgs) => (
    CatalogClient.OrderHistory.getOrderSearchPage(page.size, page.index, opts)
  )
});

export const selectors = createPaginationSelectors<Order, Args>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  pageSize: PAGE_SIZE
});

function getItemKey(item: Order): string {
  return item.orderId.toString();
}

function getStoreKey(args: any, pageSize: number): string {
  return 'user';
}
