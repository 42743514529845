import _has from "lodash-es/has";

import Config from "Config";

import {TrykLayoutsState} from "Store/TrykLayouts/Reducer";
import RootState from "Store/Root";
import {ViewMode} from "Types/Designs";

const SWATCH_PRODUCTID = 2;
const ROOM_INSTALL_PRODUCTID = 3;

export function getDefaultColorwayProductId(
  state: TrykLayoutsState,
  brandId: number,
  enableRooms: boolean,
  viewMode: ViewMode,
  sections: number[]
): number {
  const products = filterColorwayProducts(
    state,
    brandId,
    enableRooms,
    sections
  );

  let prod;
  if (viewMode === "2d") {
    prod = products.find(p => p.productId === SWATCH_PRODUCTID);
  } else if (viewMode === "3d") {
    prod = products.find(p => p.productId === ROOM_INSTALL_PRODUCTID);
  } else {
    prod = products.find(p => p.isDefault) || products[0];
  }

  return (prod && prod.productId) || -1;
}

export function filterColorwayProducts(
  state: TrykLayoutsState,
  brandId: number,
  enableRooms: boolean,
  sections: number[]
): TrykApi.Catalog.ITrykProduct[] {
  const COMPLEX_FRAME_TYPES = [3, 4],
    COMPLEX_PRODUCT_IDS = [35, 41, 43, 46, 47];
  const TILESET_PRODUCT_ID = 56;
  const HIRES_TILESET_PRODUCT_ID = 60;

  const targetId =
    Config.studio.trykProductsBrandId > 0
      ? Config.studio.trykProductsBrandId
      : brandId;
  const brandKey = targetId.toString();

  if (!_has(state.brandMap, brandKey)) {
    return [];
  }

  let products = state.brandMap[brandKey].map(
    x => state.itemsById[x.toString()]
  );

  if (sections.length !== 0) {
    products = products.filter(x => {
      return x.productId !== TILESET_PRODUCT_ID && x.productId !== HIRES_TILESET_PRODUCT_ID;
    });
  }

  return products.filter(x => {
    return (
      COMPLEX_PRODUCT_IDS.indexOf(x.productId) === -1 &&
      x.frames.some(
        y => COMPLEX_FRAME_TYPES.indexOf(y.frameContentTypeId) !== -1
      ) === false &&
      (enableRooms ||
        x.frames.length === 0 ||
        x.frames.every(y => y.frameContentTypeId === 1))
    );
  });
}

export function getDefaultRugProductId(
  products: TrykApi.Catalog.ITrykProduct[]
): number {
  const prod =
    filterRugProducts(products).find(p => p.productId === 47) || products[0];

  return (prod && prod.productId) || -1;
}

export function filterRugProducts<T extends TrykApi.Catalog.ITrykProduct>(
  products: T[]
): T[] {
  const RUG_PRODUCT_IDS = [46, 47];

  return products.filter(x => RUG_PRODUCT_IDS.indexOf(x.productId) !== -1);
}

export function getSections(state: RootState, designCode: string) {
  let sectionIds: number[] = [];

  if (!state || !state.designs) return sectionIds;

  const designState = state.designs.itemsByCode[designCode.toUpperCase()];

  if (designState && designState.overview) {
    sectionIds = designState.overview.sectionIds;
  }
  return sectionIds;
}
