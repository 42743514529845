import {
  PRELOADING,
  PRELOAD_COMPLETE,
  PRELOAD_FAILED,
  PreloadAction
} from './Actions';

export interface PreloadState {
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: PreloadState = {
  loading: false,
  loaded: false,
  failed: false
};

export function preloadReducer(state = initialState, action: PreloadAction): PreloadState {
  switch (action.type) {
    case PRELOAD_COMPLETE:
      return {
        loaded: true,
        loading: false,
        failed: false
      };
    case PRELOADING:
      return {
        loading: true,
        loaded: false,
        failed: false
      };
    case PRELOAD_FAILED:
      return {
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
