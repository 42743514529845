import {
  ColorwayState,
  EmailDestinationOption,
  PhysicalDestinationOption,
  RugState
} from 'Types/Basket';

interface BasketState {
  colorwaysById: { [id: string]: ColorwayState };
  rugsById: { [id: string]: RugState };
  itemsList: string[];

  loadingShipping: boolean;
  loadedShipping: boolean;

  emailsById: { [id: string]: EmailDestinationOption };
  emailsList: string[];

  physicalsById: { [id: string]: PhysicalDestinationOption };
  physicalsList: string[];

  projectName: string;

  placingOrder: boolean;
  placeOrderError: boolean;
}

export default BasketState;

export const initialState: BasketState = {
  colorwaysById: {},
  rugsById: {},
  itemsList: [],

  loadingShipping: false,
  loadedShipping: false,

  emailsById: {},
  emailsList: [],

  physicalsById: {},
  physicalsList: [],

  projectName: '',

  placingOrder: false,
  placeOrderError: false
};
