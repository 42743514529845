import SearchResultState, {initialState} from './State';
import {SearchResultAction} from './Actions';

import {
  TOGGLE_GRAYSCALE
} from './Actions';

function searchResultReducer(state = initialState, action: SearchResultAction): SearchResultState {
  switch (action.type) {
    case TOGGLE_GRAYSCALE:
      return {
        ...state,
        enableGrayScale: action.enableGrayScale
      };
    default:
      return state;
  }
}

export default searchResultReducer;
