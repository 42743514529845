import {ThunkAction} from 'redux-thunk';

import _isEmpty from 'lodash-es/isEmpty';

import * as CatalogClient from 'Clients/Catalog';
import {Data as StudioData} from 'Clients/Studio';

import RootState from 'Store/Root';
import {loadCustomColorway} from 'Store/Colorways/Actions';
import {loadDesign} from 'Store/Designs/Actions';
import {incrementFolderItemCount} from 'Store/Projects/Actions';

import {
  QueryArgs,
  RugTab
} from 'Types/Rugs';

import {
  actions as pagedActions,
  searchArgsSelector,
  PAGE_SIZE
} from './Paginator';

import {catalogRugSelector} from './Selectors/CatalogRug';

export const RESET_SETTINGS = 'RESET_RUG_SETTINGS';
export const CHANGE_TAB = 'CHANGE_RUG_TAB';
export const CHANGE_COMPONENT_COLLECTION = 'CHANGE_RUG_COMPONENT_COLLECTION';
export const CHANGE_COMPONENT_QUERY = 'CHANGE_RUG_COMPONENT_QUERY';
export const CHANGE_COMPONENT_SIZE_FILTER = 'CHANGE_RUG_COMPONENT_SIZE_FILTER';
export const CHANGE_COMPONENT_SECTION = 'CHANGE_RUG_COMPONENT_SECTION';
export const CHANGE_RECOLOR_SECTION = 'CHANGE_RUG_RECOLOR_SECTION';
export const LOADED_SECTION_FILTER_SIZES = 'LOADED_RUG_SECTION_FILTER_SIZES';
export const SAVING_RUG = 'SAVING_RUG';
export const SAVED_RUG = 'SAVED_RUG';
export const SAVE_RUG_FAILED = 'SAVE_RUG_FAILED';

export interface RugAction {
  type: string;
  collectionId?: number;
  query?: string;
  sizeFilter?: number;
  sectionFilterSizes?: _.Dictionary<number[]>;
  sectionId?: number;
  tab?: RugTab;
}

export function loadRug(query: QueryArgs): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const promises: Promise<any>[] = [];

    query.designs.forEach(
      (d, idx) => {
        promises.push(
          dispatch(
            loadDesign(d)
          )
        );

        if (!_isEmpty(query.positions[idx])) {
          const yarns = query.positions[idx].map(x => query.palette[x]);

          promises.push(
            dispatch(
              loadCustomColorway({
                designCode: d,
                yarns: yarns.join(','),
                rgbs: undefined,
                refColor: undefined,
                refLibs: undefined
              })
            )
          );
        }
      }
    );

    promises.push(
      dispatch(loadSectionFilterSizes())
    );

    return Promise.all(promises);
  };
}

export function saveRug(folderId: number, query: QueryArgs): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<RugAction>({type: SAVING_RUG});
    const state = getState();

    return CatalogClient.Favorites.saveRug({
      folderId,
      roomId: query.viewMode === '3d' && query.roomId || 0,
      rug: catalogRugSelector(state, query)
    }).then(
      x => {
        dispatch(incrementFolderItemCount(folderId));
        dispatch<RugAction>({type: SAVED_RUG});
      },
      err => dispatch<RugAction>({type: SAVE_RUG_FAILED})
    );
  };
}

export function loadComponentSearchPage(pageIdx: number, query: QueryArgs): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();
    const args = searchArgsSelector(state, query);

    return dispatch(
      pagedActions.loadPage({index: pageIdx, size: PAGE_SIZE}, args)
    );
  };
}

export function resetSettings(): RugAction {
  return {
    type: RESET_SETTINGS
  };
}

export function changeTab(tab: RugTab): RugAction {
  return {
    type: CHANGE_TAB,
    tab
  };
}

export function changeComponentCollection(collectionId: number): RugAction {
  return {
    type: CHANGE_COMPONENT_COLLECTION,
    collectionId
  };
}

export function changeComponentQuery(query: string): RugAction {
  return {
    type: CHANGE_COMPONENT_QUERY,
    query
  };
}

export function changeComponentSizeFilter(sizeFilter: number): RugAction {
  return {
    type: CHANGE_COMPONENT_SIZE_FILTER,
    sizeFilter
  };
}

export function changeComponentSection(sectionId: number, size: number = 0): ThunkAction<any, RootState, any> {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGE_COMPONENT_SECTION,
      sectionId
    });

    dispatch(changeComponentSizeFilter(size));

    return true;
  };
}

export function changeRecolorSection(sectionId: number): RugAction {
  return {
    type: CHANGE_RECOLOR_SECTION,
    sectionId
  };
}

function loadSectionFilterSizes(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    return new Promise<boolean>((res, rej) => {
      const state = getState();

      if (!_isEmpty(state.rug.sectionFilterSizes)) {
        res(true);
      } else {
        StudioData.getSectionFilterSizesMap()
          .then(map => {
            dispatch<RugAction>({
              type: LOADED_SECTION_FILTER_SIZES,
              sectionFilterSizes: map
            });
            res(true);
          }, err => {
            rej(false);
          });
      }
    });
  };
}
