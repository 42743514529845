import {
  UPDATING_CONTACT,
  UPDATED_CONTACT,
  UPDATE_CONTACT_FAILED,
} from '../../Actions/Contact';

import { UserAction } from '../../Actions';
import UserState, { initialState } from '../../State';

export default function contact(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case UPDATING_CONTACT:
      return {
        ...state,
        account: {
          ...state.account,
          data: state.account.data,
          updating: true,
          updateFailed: false,
          didUpdate: false
        }
      };
    case UPDATE_CONTACT_FAILED:
      return {
        ...state,
        account: {
          ...state.account,
          data: state.account.data,
          updating: false,
          updateFailed: true,
          didUpdate: false
        }
      };
    case UPDATED_CONTACT:
      return {
        ...state,
        account: {
          ...state.account,
          data: {
            ...state.account.data,
            contact: { ...action.contact }
          },
          updating: false,
          updateFailed: false,
          didUpdate: true
        }
      };
    default:
      return state;
  }
}
