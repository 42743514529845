import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_TRYK_LAYOUTS = 'LOADING_TRYK_LAYOUTS';
export const LOADED_TRYK_LAYOUTS = 'LOADED_TRYK_LAYOUTS';
export const LOAD_TRYK_LAYOUTS_FAILED = 'LOAD_TRYK_LAYOUTS_FAILED';

export interface TrykLayoutsAction {
  type: string;
  items?: TrykApi.Catalog.IBrandTrykProducts[];
}

function loading(): TrykLayoutsAction {
  return {
    type: LOADING_TRYK_LAYOUTS
  };
}

function loadFailed(): TrykLayoutsAction {
  return {
    type: LOAD_TRYK_LAYOUTS_FAILED
  };
}

function loaded(items: TrykApi.Catalog.IBrandTrykProducts[]): TrykLayoutsAction {
  return {
    type: LOADED_TRYK_LAYOUTS,
    items: items
  };
}

export function loadTrykLayouts(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.trykLayouts.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    return CatalogClient.Global.getTrykProducts()
      .then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
      );
  };
}
