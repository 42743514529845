import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { configureStore } from 'Store/Configure';

import Config from 'Config';

import { SentryContextManager } from 'Components/Sentry';

import App from './App';

const store = configureStore({});
const container = document.getElementById('app');

// Reference: https://github.com/ctrlplusb/react-universally/

function renderApp(AppTarget: React.ComponentClass<{}> | React.SFC<{}>) {
  const app = (
    <AppContainer>
      <Provider store={store}>
        <SentryContextManager>
          <BrowserRouter>
            <AppTarget />
          </BrowserRouter>
        </SentryContextManager>
      </Provider>
    </AppContainer>
  );

  ReactDOM.render(app, container);
}

renderApp(App);

if (Config.environment === 'development' && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}
