import _flatMap from "lodash-es/flatMap";
import _groupBy from "lodash-es/groupBy";
import _mapKeys from "lodash-es/mapKeys";
import _mapValues from "lodash-es/mapValues";
import _uniqBy from "lodash-es/uniqBy";

import {
  LOADED_INSTALL_TEMPLATES,
  LOADING_INSTALL_TEMPLATES,
  LOAD_INSTALL_TEMPLATES_FAILED,
  InstallTemplatesAction
} from "./Actions";

export interface InstallTemplatesState {
  itemsById: _.Dictionary<TrykApi.Catalog.IInstallTemplate>;
  brandMap: _.Dictionary<number[]>;
  tileSizesByBrand: _.Dictionary<TrykApi.Catalog.ISoftSurfaceTileSize[]>;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: InstallTemplatesState = {
  itemsById: {},
  brandMap: {},
  tileSizesByBrand: {},
  loading: false,
  loaded: false,
  failed: false
};

function installTemplatesReducer(
  state = initialState,
  action: InstallTemplatesAction
): InstallTemplatesState {
  switch (action.type) {
    case LOADED_INSTALL_TEMPLATES:
      return {
        itemsById: _mapKeys(
          _uniqBy(_flatMap(action.items, x => x.installs), x => x.installId),
          x => x.installId
        ),
        brandMap: _mapValues(_groupBy(action.items, "brandId"), grp =>
          _flatMap(grp, x => x.installs.map(y => y.installId))
        ),
        tileSizesByBrand: _mapValues(_groupBy(action.items, "brandId"), grp =>
          _flatMap(grp, x => x.tileSizes)
        ),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_INSTALL_TEMPLATES:
      return {
        itemsById: {},
        brandMap: {},
        tileSizesByBrand: {},
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_INSTALL_TEMPLATES_FAILED:
      return {
        itemsById: {},
        brandMap: {},
        tileSizesByBrand: {},
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}

export default installTemplatesReducer;
