import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

export const UPDATING_CONTACT = 'USER_UPDATING_CONTACT';
export const UPDATE_CONTACT_FAILED = 'USER_UPDATE_CONTACT_FAILED';
export const UPDATED_CONTACT = 'USER_CONTACT_UPDATED';

export default function updateContact(contact: TrykApi.Catalog.IAccountContact): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(updatingContact());

    const request = CatalogClient.User.updateContact(contact);

    request.then(
      x => dispatch(contactUpdated(contact)),
      e => dispatch(updateContactFailed())
    );

    return request;
  };
}

function updatingContact(): UserAction {
  return {
    type: UPDATING_CONTACT
  };
}

function updateContactFailed(): UserAction {
  return {
    type: UPDATE_CONTACT_FAILED
  };
}

function contactUpdated(contact: TrykApi.Catalog.IAccountContact): UserAction {
  return {
    type: UPDATED_CONTACT,
    contact: contact
  };
}
