import { ThunkAction } from 'redux-thunk';

import RootState from 'Store/Root';

import { addRugItem } from 'Store/Basket/Actions';
import { loadRug } from 'Store/Rugs/Actions';
import { catalogRugSelector } from 'Store/Rugs/Selectors/CatalogRug';
import { roomIdSelector } from 'Store/Rugs/Selectors/Options/Rooms';


import { mapRugToQuery } from '../../Utils';
import { ProjectItemsAction } from '../Action';

import {
  ADDING_PROJECT_ITEM_TO_BASKET,
  ADDED_PROJECT_ITEM_TO_BASKET,
  ADD_PROJECT_ITEM_TO_BASKET_FAILED
} from './Item';

export function addRugToBasket(rug: TrykApi.Catalog.Favorites.IRug): ThunkAction<Promise<any>, RootState, ProjectItemsAction> {
  return (dispatch, getState) => {
    const itemAction = (type: string) => {
      dispatch({
        type,
        itemId: rug.folderItemId
      });
    };

    itemAction(ADDING_PROJECT_ITEM_TO_BASKET);

    const args = mapRugToQuery(rug);

    return dispatch(loadRug(args)).then(
      x => {
        const state = getState();
        const catRug = catalogRugSelector(state, args);

        const success = dispatch(addRugItem(catRug, {
          roomId: args.roomId || roomIdSelector(state, args)
        }));

        if (success) {
          itemAction(ADDED_PROJECT_ITEM_TO_BASKET);
        } else {
          itemAction(ADD_PROJECT_ITEM_TO_BASKET_FAILED);
        }

        return success;
      },
      err => itemAction(ADD_PROJECT_ITEM_TO_BASKET_FAILED)
    );
  };
}
