import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const GET_CATEGORY_TREE = 'GET_CATEGORIES';
export const LOADING_CATEGORY_TREE = 'LOADING_CATEGORIES';
export const LOADED_CATEGORY_TREE = 'LOADED_CATEGORIES';
export const LOAD_CATEGORY_TREE_FAILED = 'LOADED_CATEGORIES_FAILED';

export interface CategoryTreeAction {
  type: string;
  categories?: TrykApi.Catalog.ICategory[];
}

function loadingCategoryTree(): CategoryTreeAction {
  return {
    type: LOADING_CATEGORY_TREE
  };
}

function categoryTreeLoadFailed(): CategoryTreeAction {
  return {
    type: LOAD_CATEGORY_TREE_FAILED
  };
}

function categoryTreeLoaded(categories: TrykApi.Catalog.ICategory[]): CategoryTreeAction {
  return {
    type: LOADED_CATEGORY_TREE,
    categories: categories
  };
}

export function loadCategoryTree(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.categoryTree.loaded || state.categoryTree.loading) {
      return Promise.resolve(true);
    }

    dispatch(loadingCategoryTree());

    return CatalogClient.Global.getCategories()
      .then(
      c => dispatch(categoryTreeLoaded(c)),
      e => dispatch(categoryTreeLoadFailed())
      );
  };
}
