import { createSelector } from 'reselect';

import { LinkItem } from 'Types/Common';

import {
  view2dUrlSelector,
  view3dUrlSelector
} from './Urls';

import paramsSelector from './Params';
import viewModeSelector from './ViewMode';

const viewModeToggleSelector = createSelector(
  paramsSelector,
  viewModeSelector,
  view2dUrlSelector,
  view3dUrlSelector,
  (params, viewMode, view2dUrl, view3dUrl): LinkItem => {
    if (!params.room) {
      return null;
    }

    switch (viewMode) {
      case '2d':
        return {
          label: 'Room View',
          href: view3dUrl
        };
      case '3d':
        return {
          label: 'Swatch View',
          href: view2dUrl
        };
      default:
        throw `Unsupported view mode encountered: ${viewMode}`;
    }
  }
);

export default viewModeToggleSelector;
