import _cloneDeep from 'lodash-es/cloneDeep';
import _isEqual from 'lodash-es/isEqual';
import _has from 'lodash-es/has';
import _keyBy from 'lodash-es/keyBy';

import {
  ActionTypes,
  PaginationAction,
  PageResult,
  ItemKeyFn,
  StoreKeyFn
} from './Actions';

import {
  KeyValueMap,
  Page,
  PaginatedItemStore,
  Paginator,
  default as PaginationState
} from './State';

type ReducerFn<TItem, TOpts> = (store: PaginatedItemStore<TItem, TOpts>, action: PaginationAction<TItem, TOpts>) => PaginatedItemStore<TItem, TOpts>;

type ReducersMap = {
  [key: string]: ReducerFn<any, any>
};

const __REDUCERS_MAP__: ReducersMap = {};

type ReducerArgs<TItem, TOpts> = {
  name: string,
  types: ActionTypes,
  getItemKey: ItemKeyFn<TItem>,
  getStoreKey: StoreKeyFn<TOpts>
};

export default function paginationReducer(state: PaginationState = {}, action: PaginationAction<any, any>): PaginationState {
  const reducer = __REDUCERS_MAP__[action.__PAGINATION_ID__];

  if (reducer) {
    const currStore: PaginatedItemStore<any, any> = state[action.__PAGINATION_ID__];
    const nextStore = reducer(currStore, action);

    if (_isEqual(currStore, nextStore)) {
      return state;
    }

    return {
      ...state,
      [action.__PAGINATION_ID__]: nextStore
    };
  } else {
    return state;
  }
}

export function registerPagedReducer<TItem, TOpts>(args: ReducerArgs<TItem, TOpts>): void {
  if (_has(__REDUCERS_MAP__, args.name)) {
    throw `Pagination reducer already exists for identifier: "${args.name}"`;
  }

  __REDUCERS_MAP__[args.name] = createPagedReducer(args);
}

function createPagedReducer<TItem, TOpts>(args: ReducerArgs<TItem, TOpts>): ReducerFn<TItem, TOpts> {
  const reduceItems = (items: KeyValueMap<TItem>, result: PageResult<TItem>): KeyValueMap<TItem> => {
    if (!result || !result.items || result.items.length === 0) {
      return items;
    }

    return {
      ...items,
      ..._keyBy(result.items, x => args.getItemKey(x))
    };
  };

  const reducePage = (page: Page = initialPage, action: PaginationAction<TItem, TOpts>): Page => {
    const didPageLoad = action.type === args.types.LOADED_PAGE;
    const didFail = action.type === args.types.PAGE_LOAD_FAILED;
    const isLoading = action.type === args.types.LOADING_PAGE;

    return {
      index: action.page.index,
      loading: isLoading,
      failed: didFail,
      ids: didPageLoad ? action.result.items.map(x => args.getItemKey(x)) : page.ids
    };
  };

  const reducePaginator = (paginator: Paginator<TOpts> = initialPaginator, action: PaginationAction<TItem, TOpts>): Paginator<TOpts> => {
    const didPageLoad = action.type === args.types.LOADED_PAGE;

    return {
      options: paginator.options || action.options,
      loaded: paginator.loaded || didPageLoad,
      pageSize: Math.max(paginator.pageSize, 0) || action.page.size,
      currPage: action.page ? action.page.index : paginator.currPage,
      resultCount: didPageLoad ? action.result.count : paginator.resultCount,
      pages: {
        ...paginator.pages,
        [action.page.index]: reducePage(paginator.pages[action.page.index], action)
      }
    };
  };

  const reduceMap = (map: KeyValueMap<Paginator<TOpts>>, action: PaginationAction<TItem, TOpts>): KeyValueMap<Paginator<TOpts>> => {
    const storeKey = args.getStoreKey(action.options, action.page.size);
    const paginator: Paginator<TOpts> = map[storeKey];

    return {
      ...map,
      [storeKey]: reducePaginator(paginator, action)
    };
  };

  const reduceStore = (store: PaginatedItemStore<TItem, TOpts>, action: PaginationAction<TItem, TOpts>): PaginatedItemStore<TItem, TOpts> => {
    return {
      ...store,
      items: reduceItems(store.items, action.result),
      map: reduceMap(store.map, action)
    };
  };

  return function(store: PaginatedItemStore<TItem, TOpts> = initialItemStore, action: PaginationAction<TItem, TOpts>): PaginatedItemStore<TItem, TOpts> {
    switch (action.type) {
      case args.types.LOADING_PAGE:
      case args.types.LOADED_PAGE:
      case args.types.PAGE_LOAD_FAILED:
        return reduceStore(store, action);
      case args.types.CLEAR:
        return _cloneDeep(initialItemStore);
      default:
        return store;
    }
  };
}

const initialItemStore: PaginatedItemStore<any, any> = {
  items: {},
  map: {}
};

const initialPaginator: Paginator<any> = {
  options: {},
  loaded: false,
  resultCount: 0,
  pageSize: 0,
  currPage: -1,
  pages: {}
};

const initialPage: Page = {
  index: -1,
  failed: false,
  loading: false,
  ids: []
};
