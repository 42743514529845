import * as Enums from 'Clients/Catalog/Enums';

import { RugTab } from 'Types/Rugs';

import {
  CHANGE_COMPONENT_COLLECTION,
  CHANGE_COMPONENT_QUERY,
  CHANGE_COMPONENT_SECTION,
  CHANGE_COMPONENT_SIZE_FILTER,
  CHANGE_RECOLOR_SECTION,
  CHANGE_TAB,
  LOADED_SECTION_FILTER_SIZES,
  RESET_SETTINGS,
  RugAction
} from './Actions';

export interface RugState {
  activeTab: RugTab;
  componentCollectionId: number;
  componentQuery: string;
  componentSectionId: number;
  componentSizeFilter: number;
  recolorSectionId: number;
  sectionFilterSizes: _.Dictionary<number[]>;
}

const initialState: RugState = {
  activeTab: 'recolor',
  componentCollectionId: 0,
  componentQuery: '',
  componentSizeFilter: 0,
  componentSectionId: Enums.Sections.Id.Outfill,
  recolorSectionId: -1,
  sectionFilterSizes: {}
};

export function rugReducer(state = initialState, action: RugAction): RugState {
  switch (action.type) {
    case RESET_SETTINGS:
      return {
        ...initialState,
        sectionFilterSizes: {
          ...state.sectionFilterSizes
        }
      };
    case CHANGE_COMPONENT_COLLECTION:
      return {
        ...state,
        activeTab: 'components',
        componentCollectionId: action.collectionId
      };
    case CHANGE_COMPONENT_QUERY:
      return {
        ...state,
        activeTab: 'components',
        componentQuery: action.query
      };
    case CHANGE_COMPONENT_SECTION:
      return {
        ...state,
        activeTab: 'components',
        componentSectionId: action.sectionId
      };
    case CHANGE_COMPONENT_SIZE_FILTER:
      return {
        ...state,
        activeTab: 'components',
        componentSizeFilter: action.sizeFilter
      };
    case CHANGE_RECOLOR_SECTION:
      return {
        ...state,
        activeTab: 'recolor',
        recolorSectionId: action.sectionId
      };
    case CHANGE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
    case LOADED_SECTION_FILTER_SIZES:
      return {
        ...state,
        sectionFilterSizes: action.sectionFilterSizes
      };
    default:
      return state;
  }
}
