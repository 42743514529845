import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { registerPagedReducer } from './Reducer';
import { PartialRootState } from './State';

export interface PageParams {
  index: number;
  size: number;
}

export interface PageResult<TItem> {
  items: TItem[];
  count: number;
}

export interface PaginationAction<TItem, TOpts> {
  __PAGINATION_ID__: string;
  type: string;
  options?: TOpts;
  page?: PageParams;
  result?: PageResult<TItem>;
}

export type StoreKeyFn<TOpts> = (opts: TOpts, pageSize: number) => string;
export type ItemKeyFn<TItem> = (item: TItem) => string;

export interface FactoryArgs<TItem, TOpts> {
  loadPage: (page: PageParams, opts: TOpts) => Promise<PageResult<TItem>>;
  getItemKey: ItemKeyFn<TItem>;
  getStoreKey: StoreKeyFn<TOpts>;
}

export interface PaginationActions<TItem, TOpts> {
  loadPage: (page: PageParams, opts: TOpts) => ThunkAction<Promise<PageResult<TItem>>, PartialRootState, any>;
  clear: () => PaginationAction<TItem, TOpts>;
}

export function createPaginationActions<TItem, TOpts>(name: string, args: FactoryArgs<TItem, TOpts>): PaginationActions<TItem, TOpts> {
  const types = createActionTypes(name);
  const {
    loadingPage,
    pageLoaded,
    pageLoadFailed,
    clear
  } = createActionFuncs<TItem, TOpts>(name, types);

  registerPagedReducer({
    name: name,
    types: types,
    getItemKey: args.getItemKey,
    getStoreKey: args.getStoreKey
  });

  const loadPage = (page: PageParams, opts: TOpts): ThunkAction<Promise<PageResult<TItem>>, PartialRootState, any> => {
    return (dispatch: Dispatch<PartialRootState>, getState: () => PartialRootState) => {
      dispatch(loadingPage(page, opts));

      const promise = args.loadPage(page, opts);

      promise.then(
        x => dispatch(pageLoaded(x, page, opts)),
        e => dispatch(pageLoadFailed(page, opts))
      );

      return promise;
    };
  };

  return {
    loadPage,
    clear
  };
}

export interface ActionTypes {
  LOADING_PAGE: string;
  LOADED_PAGE: string;
  PAGE_LOAD_FAILED: string;
  CLEAR: string;
}

function createActionTypes(name: string): ActionTypes {
  const ACTION_PREFIX = '@shawfloors-pagination';

  return {
    LOADING_PAGE: `${ACTION_PREFIX}/${name}/LOADING_PAGE`,
    LOADED_PAGE: `${ACTION_PREFIX}/${name}/LOADED_PAGE`,
    PAGE_LOAD_FAILED: `${ACTION_PREFIX}/${name}/PAGE_LOAD_FAILED`,
    CLEAR: `${ACTION_PREFIX}/${name}/CLEAR`
  };
}

interface ActionFuncs<TItem, TOpts> {
  loadingPage: (page: PageParams, opts: TOpts) => PaginationAction<TItem, TOpts>;
  pageLoadFailed: (page: PageParams, opts: TOpts) => PaginationAction<TItem, TOpts>;
  pageLoaded: (result: PageResult<TItem>, page: PageParams, opts: TOpts) => PaginationAction<TItem, TOpts>;
  clear: () => PaginationAction<TItem, TOpts>;
}

function createActionFuncs<TItem, TOpts>(id: string, types: ActionTypes): ActionFuncs<TItem, TOpts> {
  return {
    loadingPage: (page: PageParams, opts: TOpts): PaginationAction<TItem, TOpts> => {
      return {
        __PAGINATION_ID__: id,
        type: types.LOADING_PAGE,
        page: page,
        options: opts
      };
    },
    pageLoadFailed: (page: PageParams, opts: TOpts): PaginationAction<TItem, TOpts> => {
      return {
        __PAGINATION_ID__: id,
        type: types.PAGE_LOAD_FAILED,
        page: page,
        options: opts
      };
    },
    pageLoaded: (result: PageResult<TItem>, page: PageParams, opts: TOpts): PaginationAction<TItem, TOpts> => {
      return {
        __PAGINATION_ID__: id,
        type: types.LOADED_PAGE,
        result: result,
        page: page,
        options: opts
      };
    },
    clear: (): PaginationAction<TItem, TOpts> => {
      return {
        __PAGINATION_ID__: id,
        type: types.CLEAR
      };
    }
  };
}
