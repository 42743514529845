import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_SITE = 'LOADING_SITE';
export const LOADED_SITE = 'LOADED_SITE';
export const LOAD_SITE_FAILED = 'LOAD_SITE_FAILED';

export interface SiteAction {
  type: string;
  site?: TrykApi.Catalog.ISite;
}

export function loadSite(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.site.loaded || state.site.loading) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    return CatalogClient.Global.getSite()
      .then(
      s => dispatch(loaded(s)),
      e => dispatch(loadFailed())
      );
  };
}

function loading(): SiteAction {
  return {
    type: LOADING_SITE
  };
}

function loadFailed(): SiteAction {
  return {
    type: LOAD_SITE_FAILED
  };
}

function loaded(site: TrykApi.Catalog.ISite): SiteAction {
  return {
    type: LOADED_SITE,
    site
  };
}
