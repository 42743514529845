import {
  LOADED_CATEGORY_TREE,
  LOADING_CATEGORY_TREE,
  LOAD_CATEGORY_TREE_FAILED,
  CategoryTreeAction
} from './Actions';

export interface CategoryTreeState {
  items: TrykApi.Catalog.ICategory[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: CategoryTreeState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false
};

function categoryTreeReducer(state = initialState, action: CategoryTreeAction): CategoryTreeState {
  switch (action.type) {
    case LOADED_CATEGORY_TREE:
      return {
        items: action.categories.concat(),
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_CATEGORY_TREE:
      return {
        items: [],
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_CATEGORY_TREE_FAILED:
      return {
        items: [],
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}

export default categoryTreeReducer;
