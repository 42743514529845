import {
  LOADED_SITE,
  LOADING_SITE,
  LOAD_SITE_FAILED,
  SiteAction
} from './Actions';

export interface SiteState {
  item: TrykApi.Catalog.ISite;
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: SiteState = {
  item: null,
  loading: false,
  loaded: false,
  failed: false
};

function siteReducer(state = initialState, action: SiteAction): SiteState {
  switch (action.type) {
    case LOADED_SITE:
      return {
        item: action.site,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_SITE:
      return {
        item: null,
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_SITE_FAILED:
      return {
        item: null,
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}

export default siteReducer;
