import {PrintScaleOption} from 'Types/Basket';
import {MaintenanceAlert} from 'Types/MaintenanceAlert';
import {SectionMap} from 'Types/Rooms';
import {
  EngineLibrariesMap,
  EnginePalettesMap
} from 'Types/Yarns';

import Config from 'Config';

type PrintScaleItem = {
  ratio: string;
  percentage: string;
  value: number;
};

export function getEngineLibrariesMap(): Promise<EngineLibrariesMap> {
  return new Promise<EngineLibrariesMap>((res, rej) => {
    const map = require('Assets/Data/engine-libraries-map.json');
    res(map);
  });
}

export function getEnginePalettesMap(): Promise<EnginePalettesMap> {
  return new Promise<EnginePalettesMap>((res, rej) => {
    const map = require('Assets/Data/engine-palettes-map.json');
    res(map);
  });
}

export function getPrintScales(): Promise<PrintScaleOption[]> {
  return new Promise<PrintScaleOption[]>((res, rej) => {
    const map = require('Assets/Data/print-scales.json') as PrintScaleItem[];
    res(map.map(x => ({
      ...x,
      thumbnailUrl: require(`Assets/Images/PrintScales/PrintScale_${x.value}.png`)
    })));
  });
}

export function getSectionFilterSizesMap(): Promise<_.Dictionary<number[]>> {
  return new Promise<_.Dictionary<number[]>>((res, rej) => {
    const map = require('Assets/Data/section-filter-sizes-map.json');
    res(map);
  });
}

export function getSectionRoomScenesMap(): Promise<SectionMap> {
  return new Promise<SectionMap>((res, rej) => {
    const map = require('Assets/Data/section-room-scenes-map.json');
    res(map);
  });
}

export function getMaintenanceMessage(): MaintenanceAlert {
  const site = Config.site.title.replace(/\[DEV\]|\s+/g, '');

  const map = require(`Assets/Sites/${site}/maintenance-msg.json`);
  let alert = map as MaintenanceAlert;

  let startDate = convertESTToLocal(alert.startDate);

  let endDate = convertESTToLocal(alert.endDate);

  const currentDate = new Date();

  if ((currentDate < startDate || currentDate > endDate)) {
    alert.enabled = false;
  }

  return alert;
}

// Inspired by https://gist.github.com/mihaipaun/9191226
function convertESTToLocal(dateInput: string) {
  // EST - UTC offset: 5 hours
  let offset = 5.0,

    /*
      - calculate the difference between the inputDate and UTC
      - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
      - the time-zone offset is the difference, in minutes, between UTC and local time
      - 60000 milliseconds = 60 seconds = 1 minute
    */
    inputDate = new Date(dateInput),
    utc = inputDate.getTime() - (inputDate.getTimezoneOffset() * 60000),

    /*
      - apply the offset between UTC and EST (5 hours)
      - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
    */
    clientDate = new Date(utc + (3600000 * offset));

  return clientDate;
}
