import { ThunkAction } from "redux-thunk";

import * as CatalogClient from "Clients/Catalog";

import RootState from "Store/Root";

export const LOADING_INSTALL_TEMPLATES = "LOADING_INSTALL_TEMPLATES";
export const LOADED_INSTALL_TEMPLATES = "LOADED_INSTALL_TEMPLATES";
export const LOAD_INSTALL_TEMPLATES_FAILED = "LOAD_INSTALL_TEMPLATES_FAILED";

export interface InstallTemplatesAction {
  type: string;
  items?: TrykApi.Catalog.IBrandInstallTemplates[];
}

export function loadInstallTemplates(): ThunkAction<
  Promise<any>,
  RootState,
  any
> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.installTemplates.loaded || state.installTemplates.loading) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    return CatalogClient.Global.getInstallTemplates().then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
    );
  };
}

function loading(): InstallTemplatesAction {
  return {
    type: LOADING_INSTALL_TEMPLATES
  };
}

function loadFailed(): InstallTemplatesAction {
  return {
    type: LOAD_INSTALL_TEMPLATES_FAILED
  };
}

function loaded(
  items: TrykApi.Catalog.IBrandInstallTemplates[]
): InstallTemplatesAction {
  return {
    type: LOADED_INSTALL_TEMPLATES,
    items
  };
}
