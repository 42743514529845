import { createSelector } from 'reselect';

import { Urls as ImageUrls } from 'Clients/Images';
import { Urls as StudioUrls } from 'Clients/Studio';

import RootState from 'Store/Root';
import { getValidRooms } from 'Store/Rooms/Utils';

import { ThumbnailOptionItem } from 'Types/Common';
import { Params } from 'Types/Designs';

import { overviewSelector } from '../PatternInfo';
import paramsSelector from '../Params';
import designInstallsSelector from '../DesignInstalls';
import { IRoomSceneCategory } from 'Components/Pages/Design/Rooms/Props';

import Config from 'Config';

const getRoomUrl = (room: TrykApi.Catalog.IRoomScene, params: Params) => {
  if (!params.colorway || !params.install) {
    return '#';
  }

  return StudioUrls.colorway(params.colorway, {
    textureId: params.texture && !params.texture.isDefault && params.texture.textureId,
    installId: room.surfaces.some(s => s.name === 'Stair') ? 1 : params.install.installId || 0,
    roomId: room.roomId,
    viewMode: '3d',
    clutColorwayId: params.clutColorwayId,
    customRug: params.customRug,
    position: null
  });
};

const mapRoomToOptionItem = (room: TrykApi.Catalog.IRoomScene, params: Params): ThumbnailOptionItem => {
  return {
    id: room.roomId,
    label: room.name,
    imageUrl: ImageUrls.Thumbnail.room(room.roomId),
    isActive: params.room && params.room.roomId === room.roomId,
    href: getRoomUrl(room, params),
    categoryIds: room.categoryIds
  };
};

const roomOptionsSelector = createSelector(
  (state: RootState) => state.rooms,
  designInstallsSelector,
  overviewSelector,
  paramsSelector,
  (roomsState, installs, overview, params) => {
    if (!roomsState || !roomsState.sectionMap || !overview || !params || !installs) {
      return [];
    }

    const rooms = getValidRooms(roomsState, overview.brandId, overview.sectionIds, params.customRug !== null, installs.some(x => x.installId === 1));

    return rooms.map(x => mapRoomToOptionItem(x, params));
  }
);
export default roomOptionsSelector;

export const roomCategoryOptionsSelector = createSelector(
  (state: RootState) => state.rooms,
  roomOptionsSelector,
  (roomsState, rooms) => {
    if (!roomsState.categories ||
      roomsState.categories.find(x => x.brandId === Config.studio.roomsBrandId).children.length === 0) {
      return [];
    }

    const roomsceneCategories = roomsState.categories.find(x => x.brandId === Config.studio.roomsBrandId);

    const existingCatIds = rooms.map(x => x.categoryIds)
      .reduce((a, b) => {
        return a.concat(b);
      }, []);

    const roomCategories: IRoomSceneCategory[] = roomsceneCategories.children.map(c => {
      return {
        categoryId: c.categoryId,
        name: c.name,
      };
    }).filter(x =>
      existingCatIds.find(cid => cid === x.categoryId));

    roomCategories.unshift({ categoryId: -1, name: 'All Segments' });

    return roomCategories;
  }
);

