export interface IDeferred<T> {
  promise?: Promise<T>;
  resolve?: (value: T) => void;
  reject?: (error?: any) => void;
}

export default function defer<T>() {
  const deferred: IDeferred<T> = {};

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}
