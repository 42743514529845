import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

export const LOADING_RECENTLY_USED_DESIGNS = 'LOADING_RECENTLY_USED_DESIGNS';
export const LOADED_RECENTLY_USED_DESIGNS = 'LOADED_RECENTLY_USED_DESIGNS';
export const LOAD_RECENTLY_USED_DESIGNS_FAILED = 'LOAD_RECENTLY_USED_DESIGNS_FAILED';

export interface RecentlyUsedDesignsAction {
  type: string;
  items?: TrykApi.Catalog.ISearchResult[];
}

function loading(): RecentlyUsedDesignsAction {
  return {
    type: LOADING_RECENTLY_USED_DESIGNS
  };
}

function loadFailed(): RecentlyUsedDesignsAction {
  return {
    type: LOAD_RECENTLY_USED_DESIGNS_FAILED
  };
}

function loaded(items: TrykApi.Catalog.ISearchResult[]): RecentlyUsedDesignsAction {
  return {
    type: LOADED_RECENTLY_USED_DESIGNS,
    items,
  };
}

export function loadRecentlyUsedDesigns(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user.status.loggedIn) return;

    dispatch(loading());

    return CatalogClient.Product.getRecentlyUsedDesigns().then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
    );
  };
}

export function saveRecentlyUsedDesign(designId: number): ThunkAction<Promise<any>, RootState, any> {
  return (_, getState) => {
    const state = getState();

    if (!state.user.status.loggedIn) return;

    return CatalogClient.Product.saveRecentlyUsedDesign(designId);
  };
}
