import {
  CHANGING_PASSWORD,
  CHANGED_PASSWORD,
  CHANGE_PASSWORD_FAILED
} from '../Actions/ChangePassword';

import { UserAction } from '../Actions';

import UserState, { initialState as initialUser } from '../State';

export default function password(state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case CHANGING_PASSWORD:
      return {
        ...state,
        password: {
          ...initialUser.password,
          changing: true,
          changeFailed: false,
          didChange: false
        }
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        password: {
          ...initialUser.password,
          changing: false,
          changeFailed: true,
          didChange: false
        }
      };
    case CHANGED_PASSWORD:
      return {
        ...state,
        password: {
          ...initialUser.password,
          changing: false,
          changeFailed: false,
          didChange: true
        }
      };
    default:
      return state;
  }
}
