import { ThunkAction } from 'redux-thunk';

import { defer } from 'Utils/Promise';

import RootState from 'Store/Root';

import loadAccount from '../User/Actions/Account';
import { loadCategoryTree } from '../CategoryTree/Actions';
import { loadCollections } from '../Collections/Actions';
//import { loadExclusiveDesigns } from '../ExclusiveDesigns/Actions';
import { loadInstallTemplates } from '../InstallTemplates/Actions';
//import { loadNewDesigns } from '../NewDesigns/Actions';
import { loadPrintScales } from '../PrintScales/Actions';
import { loadRooms } from '../Rooms/Actions';
import { loadSite } from '../Site/Actions';
import { loadTextures } from '../Textures/Actions';
import { loadTrykLayouts } from '../TrykLayouts/Actions';
import { loadYarnLibraries } from '../YarnLibraries/Actions';

export const PRELOADING = 'PRELOADING_DATA';
export const PRELOAD_COMPLETE = 'PRELOAD_DATA_COMPLETE';
export const PRELOAD_FAILED = 'PRELOAD_DATA_FAILED';

export interface PreloadAction {
  type: string;
}

function preloadAccount(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState(),
      dfd = defer<any>();

    if (!state.user.status.loggedIn && !state.user.account.loadFailed) {
      // If we aren't logged in, try and load the account
      // since it will load properly if we have a valid cookie set.
      dispatch(loadAccount()).then(
        x => dfd.resolve(true),
        e => dfd.resolve(false)
      ).catch(e => dfd.resolve(false));
    } else {
      // If we are already logged in we don't need to do anything,
      // but resolve our promise.
      dfd.resolve(true);
    }

    return dfd.promise;
  };
}

export default function load(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.preload.loading || state.preload.loaded) {
      return Promise.resolve(true);
    }

    dispatch(preloading());

    return Promise.all([
      dispatch(loadCategoryTree()),
      dispatch(loadCollections()),
      //dispatch(loadExclusiveDesigns()),
      dispatch(loadInstallTemplates()),
      //dispatch(loadNewDesigns()),
      dispatch(loadPrintScales()),
      dispatch(loadRooms()),
      dispatch(loadSite()),
      dispatch(loadTextures()),
      dispatch(loadTrykLayouts()),
      dispatch(loadYarnLibraries()),
      dispatch(preloadAccount())
    ]).then(
      () => dispatch(preloadComplete()),
      () => dispatch(preloadFailed())
      );
  };
}

function preloading(): PreloadAction {
  return {
    type: PRELOADING
  };
}

function preloadComplete(): PreloadAction {
  return {
    type: PRELOAD_COMPLETE
  };
}

function preloadFailed(): PreloadAction {
  return {
    type: PRELOAD_FAILED
  };
}
