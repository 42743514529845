import _flatMap from 'lodash-es/flatMap';

import { createSelector } from 'reselect';

import {
  ItemKeyFn,
  StoreKeyFn
} from './Actions';
import {
  KeyValueMap,
  Page,
  PaginatedItemStore,
  Paginator,
  PartialRootState
} from './State';

export interface FactoryArgs<TItem, TOpts> {
  pageSize: number;
  getItemKey: ItemKeyFn<TItem>;
  getStoreKey: StoreKeyFn<TOpts>;
}

export type Selector<TOut, TProps> = (state: PartialRootState, props: TProps) => TOut;

export interface PaginationSelectors<TItem, TOpts> {
  allResults: Selector<TItem[], TOpts>;
  currResults: Selector<TItem[], TOpts>;
  resultCount: Selector<number, TOpts>;
  pageCount: Selector<number, TOpts>;
  currPageIdx: Selector<number, TOpts>;
  loaded: Selector<boolean, TOpts>;
  loadingPage: Selector<boolean, TOpts>;
}

export default function createPaginationSelectors<TItem, TOpts>(name: string, args: FactoryArgs<TItem, TOpts>): PaginationSelectors<TItem, TOpts> {
  const storeSelector = (state: PartialRootState): PaginatedItemStore<TItem, TOpts> => {
    return state.pagination[name] || null;
  };

  const paginatorSelector = (state: PartialRootState, props: TOpts): Paginator<TOpts> => {
    const store = storeSelector(state);

    if (!store) {
      return null;
    }

    return store.map[args.getStoreKey(props, args.pageSize)];
  };

  const currPageSelector: Selector<Page, TOpts> = createSelector(
    paginatorSelector,
    (paginator: Paginator<TOpts>) => {
      if (!paginator) {
        return null;
      }

      return paginator.pages[paginator.currPage];
    }
  );

  const itemsSelector: Selector<KeyValueMap<TItem>, TOpts> = createSelector(
    storeSelector,
    (store: PaginatedItemStore<TItem, TOpts>): KeyValueMap<TItem> => {
      if (!store) {
        return {};
      }

      return store.items;
    }
  );

  const allResultsSelector: Selector<TItem[], TOpts> = createSelector(
    paginatorSelector,
    itemsSelector,
    (paginator: Paginator<TOpts>, items: KeyValueMap<TItem>): TItem[] => {
      if (!paginator || !items) {
        return [];
      }

      return _flatMap(paginator.pages, x => x.ids).map(x => items[x]);
    }
  );

  const currResultsSelector: Selector<TItem[], TOpts> = createSelector(
    currPageSelector,
    itemsSelector,
    (page: Page, items: KeyValueMap<TItem>): TItem[] => {
      if (!page || !items) {
        return [];
      }

      return page.ids.map(x => items[x]);
    }
  );

  const resultCountSelector: Selector<number, TOpts> = createSelector(
    paginatorSelector,
    (paginator: Paginator<TOpts>) => {
      if (!paginator || !paginator.loaded) {
        return 0;
      }

      return paginator.resultCount;
    }
  );

  const pageCountSelector: Selector<number, TOpts> = createSelector(
    resultCountSelector,
    (resultCount: number) => {
      if (resultCount <= 0) {
        return 0;
      }

      return Math.ceil(resultCount / args.pageSize);
    }
  );

  const currPageIdxSelector: Selector<number, TOpts> = createSelector(
    paginatorSelector,
    (paginator: Paginator<TOpts>) => {
      if (!paginator) {
        return -1;
      }

      return paginator.currPage;
    }
  );

  const loadedSelector: Selector<boolean, TOpts> = createSelector(
    paginatorSelector,
    (paginator: Paginator<TOpts>) => {
      if (!paginator) {
        return false;
      }

      return paginator.loaded;
    }
  );

  const loadingPageSelector: Selector<boolean, TOpts> = createSelector(
    currPageSelector,
    (page: Page) => {
      if (!page) {
        return false;
      }

      return page.loading;
    }
  );

  return {
    allResults: allResultsSelector,
    currResults: currResultsSelector,
    resultCount: resultCountSelector,
    pageCount: pageCountSelector,
    currPageIdx: currPageIdxSelector,
    loaded: loadedSelector,
    loadingPage: loadingPageSelector
  };
}
