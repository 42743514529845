import RootState from 'Store/Root';

import { QueryArgs } from 'Types/Rugs';

export const overviewsSelector = (state: RootState, query: QueryArgs): TrykApi.Catalog.IDesignOverview[] => {
  const designs = query.designs.map(x => state.designs.itemsByCode[x.toUpperCase()])
    .filter(x => !!x);

  if (designs.length !== query.designs.length) {
    return [];
  }

  return designs.map(x => x.overview);
};
