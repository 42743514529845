export type ProjectItem = {
  id: number;
  viewHref: string;
  usersHref: string;

  name: string;
  client: string;
  yardage: number;
  description: string;
  private: boolean;

  folders: FolderItem[];
  itemCount: number;

  isOwner: boolean;
  owner: TrykApi.Catalog.IAccount;
  users: TrykApi.Catalog.IAccount[];
  addingUser: boolean;
  addUserFailed: boolean;
  deletingUser: boolean;
  deleteUserFailed: boolean;

  createdDisplay: string;
  modifiedDisplay: string;
  fromNowDisplay: string;
};

export type FolderItem = {
  id: number;
  href: string;

  name: string;
  private: boolean;
  itemCount: number;

  createdDisplay: string;
  modifiedDisplay: string;
  fromNowDisplay: string;
};

export type ItemType = (
  TrykApi.Catalog.Favorites.IColorway |
  TrykApi.Catalog.Favorites.IRug
);

export type SavedItem = {
  id: number;
  item: ItemType;
  totalComments: number;

  isDeleted: boolean;
  isOwner: boolean;
  owner: TrykApi.Catalog.IAccount;

  hrefs: {
    pattern: string;
    item: string;
  };

  imageUrls: {
    thumbnail: string;
    preview: string;
  }

  display: {
    primary: string;
    secondary?: string;
    created: string;
    modified: string;
    fromNow: string;
  };
};

export function isSavedColorway(item: TrykApi.Catalog.Favorites.ISavedItem): item is TrykApi.Catalog.Favorites.IColorway {
  return item && (item as any)['designId'] !== undefined;
}

export function isSavedRug(item: TrykApi.Catalog.Favorites.ISavedItem): item is TrykApi.Catalog.Favorites.IRug {
  return item && (item as any)['components'] !== undefined;
}

export type UserComment = {
  id: number;
  text: string;

  isOwner: boolean;
  owner: TrykApi.Catalog.IAccount;

  display: {
    created: string;
    modified: string;
    fromNow: string;
  }
};

export type SearchArgs = {
  projectId?: number;
  folderId?: number;
};

export type ProjectFilterType = (
  'all' | 'owner' | 'shared'
);

export type ProjectFilterOption = {
  label: string;
  value: ProjectFilterType;
};

export type FolderFilterOption = FolderItem & {
  active: boolean;
};
