import _flatten from 'lodash-es/flatten';
import _toPairs from 'lodash-es/toPairs';
import * as CatalogClient from '@shawfloors/catalog-client';
import * as Query from '@shawfloors/catalog-client/dist/Clients/Support/Query';

import * as Storage from 'Utils/Storage';

import cacheKey from './CacheKey';

export import IPageResult = CatalogClient.Product.IPageResult;
export import ISearchOptions = CatalogClient.Product.ISearchOptions;
import { RgbList } from '@shawfloors/catalog-client/dist/Clients/Support/Types';

export function getSearchPage(opts: ISearchOptions): Promise<IPageResult<TrykApi.Catalog.ISearchResult>> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'search', JSON.stringify(opts)),
    () => CatalogClient.Product.getSearchPage(opts));
}

export function getColorSearchPage(opts: ISearchOptions): Promise<IPageResult<TrykApi.Catalog.ISearchResult>> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'search', 'colors', JSON.stringify(opts)),
    () => CatalogClient.Product.getColorSearchPage(opts));
}

export function getDesign(designCode: string): Promise<TrykApi.Catalog.IDesignOverview> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'designs', designCode),
    () => CatalogClient.Product.getDesign(designCode));
}

export function getInstalls(designCode: string): Promise<TrykApi.Catalog.IDesignInstall[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'installs', designCode),
    () => CatalogClient.Product.getInstalls(designCode));
}

export function getColorways(designCode: string): Promise<TrykApi.Catalog.IColorway[]> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'colorways', designCode),
    () => CatalogClient.Product.getColorways(designCode));
}

export function getColorway(designCode: string, colorCode: string): Promise<TrykApi.Catalog.IColorway> {
  return Storage.addOrGetItemAsync(
    cacheKey('product', 'colorway', designCode, colorCode),
    () => CatalogClient.Product.getColorway(designCode, colorCode));
}

export function getRecentlyUsedDesigns(): Promise<TrykApi.Catalog.ISearchResult[]> {
  return CatalogClient.Product.getRecentlyUsedDesigns();
}

export function saveRecentlyUsedDesign(designId: number): Promise<boolean> {
  return CatalogClient.Product.saveRecentlyUsedDesign(designId);
}

export type ICustomColorwayArgs = CatalogClient.Product.ICustomColorwayArgs;
export type IRgbList = ICustomColorwayArgs['rgbs'];

export function getCustomColorway({
  designCode,
  refColor,
  refLibs,
  yarns,
  rgbs,
  clutColorwayId
}: ICustomColorwayArgs): Promise<TrykApi.Catalog.IColorway> {
  return Storage.addOrGetItemAsync(
    cacheKey(
      'product', 'colorway', designCode, 'custom',
      ...parseKeyValue(Query.parseArg('refColor', refColor)),
      ...parseKeyValue(Query.yarnsToArg(yarns)),
      ...parseKeyValue(Query.rgbsToArg(rgbs)),
      ...parseKeyValue(Query.parseArg('refLibs', refLibs)),
      ...parseKeyValue(Query.parseArg('clut', clutColorwayId)),
    ),
    () => {
      return CatalogClient.Product.getCustomColorway({ designCode, refColor, refLibs, yarns, rgbs, clutColorwayId });
    });
}

export function getPalette(designCode: string, rgbs: IRgbList, refColor: string = ''): Promise<TrykApi.Catalog.IRgb[]> {
  return Storage.addOrGetItemAsync(
    cacheKey(
      'product', 'palette', designCode,
      ...parseKeyValue(Query.rgbsToArg(rgbs)),
      ...parseKeyValue(Query.parseArg('refColor', refColor))
    ),
    () => CatalogClient.Product.getPalette(designCode, rgbs, refColor));
}
export function getClutColorwayPalette(designCode: string, clutColorwayId: string, size: number = 5): Promise<TrykApi.Catalog.IRgb[]> {
  return CatalogClient.Product.getclutColorwayPalette(designCode, clutColorwayId, size);
}

export function getClutColorways(userId: number): Promise<TrykApi.Catalog.IHaldClutColorway[]> {
  return CatalogClient.Product.getHaldClutColorwaysFromUser(userId);
}

export function deleteCustomClut(clutId: string): Promise<TrykApi.Catalog.IHaldClutColorway> {
  return CatalogClient.Product.deleteCustomClut(clutId);
}

export function createCustomClut(designCode: string, rgbs: RgbList, colorwayName: string, refColor?: string): Promise<TrykApi.Catalog.IHaldClutColorway> {
  return CatalogClient.Product.createCustomClut(designCode, rgbs, colorwayName, refColor);
}

export function getAllPalettes(designCode: string, size: number = 5, haldClutId: string = ''): Promise<_.Dictionary<TrykApi.Catalog.IRgb[]>> {

  return Storage.addOrGetItemAsync(
    cacheKey(
      'product',
      'palettes',
      designCode,
      ...parseKeyValue(Query.parseArg('size', size)),
      ...parseKeyValue(Query.parseArg('haldClutId', haldClutId))
    ),
    () => CatalogClient.Product.getAllPalettes(designCode, size, haldClutId));
}

export type ParseArgTypes = (
  | string
  | string[]
  | number
  | number[]
  | boolean
  | boolean[]
  | undefined
);

export function parseKeyValue(input: { [key: string]: string }): string[] {
  return _flatten(_toPairs(input));
}
