import {
  LOADING_FIXED_COLORWAYS,
  LOAD_FIXED_COLORWAYS_FAILED,
  LOADED_FIXED_COLORWAYS,
  ColorwayAction
} from '../Actions';

interface FixedColorwaysState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  items: TrykApi.Catalog.IColorway[];
  palettes: _.Dictionary<TrykApi.Catalog.IRgb[]>;
}

export default FixedColorwaysState;

const initialState: FixedColorwaysState = {
  loading: false,
  loaded: false,
  error: false,
  items: null,
  palettes: null
};

export function fixedColorways(state = initialState, action: ColorwayAction): FixedColorwaysState {
  switch (action.type) {
    case LOADING_FIXED_COLORWAYS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_FIXED_COLORWAYS_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    case LOADED_FIXED_COLORWAYS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        items: action.data.items,
        palettes: action.data.palettes
      };
    default:
      return state;
  }
}
