import {
  LOADING_TRYK,
  LOADED_TRYK,
  LOAD_TRYK_FAILED
} from '../../Actions/Tryks';

import {
  LOADING_ORDER_DETAILS,
  LOADED_ORDER_DETAILS,
  LOAD_ORDER_DETAILS_FAILED
} from '../../Actions/OrderDetails';

import { UserAction } from '../../Actions';

import UserState from '../../State';

import getTryksByOrder from './ByOrder';
import getTryks from './Tryk';

export default function tryks(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case LOADING_ORDER_DETAILS:
    case LOADED_ORDER_DETAILS:
    case LOAD_ORDER_DETAILS_FAILED:
      return {
        ...state,
        tryks: getTryksByOrder(state.tryks, action.type, action.orderDetail)
      };
    case LOADING_TRYK:
    case LOADED_TRYK:
    case LOAD_TRYK_FAILED:
      return {
        ...state,
        tryks: getTryks(state.tryks, action.type, action.trykDetail)
      };
    default:
      return state;
  }
}

