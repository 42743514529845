import RootState from 'Store/Root';

import { LoadingStatus } from 'Types/Common';

export const siteSelector = (state: RootState): TrykApi.Catalog.ISite => {
  return state.site.item;
};

export const loadingStatusSelector = (state: RootState): LoadingStatus => {
  return {
    loading: state.site.loading,
    loaded: state.site.loaded,
    failed: state.site.failed
  };
};
