import _keyBy from 'lodash-es/keyBy';

import {
  LOADING_ORDER_DETAILS,
  LOADED_ORDER_DETAILS,
  LOAD_ORDER_DETAILS_FAILED
} from '../../Actions/OrderDetails';

import { OrderDetailPayload } from '../../Actions';
import {
  TryksState,
  TrykMap,
  TryksByOrderMap,
  TryksByOrderState
} from '../../State';

export default function byOrder(state: TryksState, type: string, payload: OrderDetailPayload): TryksState {
  switch (type) {
    case LOADING_ORDER_DETAILS:
    case LOADED_ORDER_DETAILS:
    case LOAD_ORDER_DETAILS_FAILED:
      return {
        ...state,
        itemsById: getTrykMap(state.itemsById, type, payload),
        orderMap: getOrderMap(state.orderMap, type, payload)
      };
    default:
      return state;
  }
}

function getTrykMap(map: TrykMap, type: string, payload: OrderDetailPayload): TrykMap {
  if (!payload.tryks) {
    return map;
  }

  return {
    ...map,
    ..._keyBy(payload.tryks, x => x.trykId.toString())
  };
}

function getOrderMap(map: TryksByOrderMap, type: string, payload: OrderDetailPayload): TryksByOrderMap {
  const key = payload.orderId.toString();

  return {
    ...map,
    [key]: getOrderState(map[key], type, payload)
  };
}

const initialState: TryksByOrderState = {
  loading: false,
  failed: false,
  trykIds: []
};

function getOrderState(state: TryksByOrderState = initialState, type: string, payload: OrderDetailPayload): TryksByOrderState {
  return {
    loading: type === LOADING_ORDER_DETAILS,
    failed: type === LOAD_ORDER_DETAILS_FAILED,
    trykIds: payload.tryks && payload.tryks.map(x => x.trykId) || state.trykIds
  };
}
