import { createSelector } from 'reselect';

import * as Enums from 'Clients/Catalog/Enums';
import { Urls as ImageUrls } from 'Clients/Images';
import Config from 'Config';

import {
  engineLibrariesMapSelector
} from "Store/YarnLibraries/Selectors";
import {
  mapLibrariesToItems,
  mapLibrariesToSearchYarns
} from 'Store/YarnLibraries/Utils';

import {
  RecolorConfig,
  RecolorMode,
  RecolorRgb,
  RecolorYarns
} from 'Types/Designs';
import {
  EditableYarnItem,
  EditableYarnList,
  PaletteLink,
  LibrarySpriteItem,
  YarnItemDisplay
} from 'Types/Yarns';

import { loadingCustomColorwaySelector } from './Loading';
import paramsSelector from './Params';
import { Props } from './Props';
import { yarnLibrariesByBrandSelector } from './YarnLibrariesByEngine';

const recolorableStatusSelector = createSelector(
  paramsSelector,
  ({ colorway }): boolean => {
    return !!(colorway && colorway.recolorable);
  }
);

const recolorModeSelector = createSelector(
  paramsSelector,
  (state, { rgbs, yarnCodes }: Props) => ({ rgbs, yarnCodes }),
  ({ colorway }, query): RecolorMode => {
    if (!colorway) {
      if (query.yarnCodes && query.yarnCodes.length > 0) {
        return 'yarns';
      } else if (query.rgbs && query.rgbs.length > 0) {
        return 'rgb';
      } else {
        return 'none';
      }
    } else if (colorway.engineId === 9 && colorway.rgbs.length > 0) {
      return 'rgb';
    } else if (colorway.yarns.length === 0) {
      return 'none';
    } else {
      return 'yarns';
    }
  }
);

const recolorRgbsSelector = createSelector(
  loadingCustomColorwaySelector,
  paramsSelector,
  (status, params): RecolorRgb => ({
    loading: status.loading,
    designCode: params.colorway && params.colorway.designCode || '',
    refColorCode: params.colorway && params.colorway.colorCode || '',
    rgbs: params.colorway && params.colorway.rgbs || [],
    viewMode: params.viewMode,
    roomId: params.room && params.room.roomId,
    installId: params.install && params.install.installId,
    textureId: params.texture && params.texture.textureId || 0,
    customRug: params.customRug,
    rotation: params.rotation,
    position: params.position
  })
);

const editableYarnsSelector = createSelector(
  paramsSelector,
  engineLibrariesMapSelector,
  (params, engineLibsMap): EditableYarnList => {
    if (!params || !params.colorway) {
      return [];
    }

    const getLibraryIds = (yarn: TrykApi.Catalog.IColorwayYarn): number[] => {
      if (!Config.studio.allowLockedRecoloring || (!yarn.isLocked && (yarn.yarnLibraryIds || []).length === 0)) {
        return engineLibsMap[params.colorway.engineId.toString()];
      }

      return yarn.yarnLibraryIds;
    };

    const getEditable = (idx: number, yarn: TrykApi.Catalog.IColorwayYarn, isHidden: boolean, isMuted: boolean): EditableYarnItem => {
      return {
        index: idx,
        id: yarn.yarnId,
        typeId: yarn.yarnTypeId,
        code: yarn.code,
        label: yarn.code,
        childCodes: yarn.children.map(x => x.code),
        imageUrl: ImageUrls.Thumbnail.yarn(yarn.code, params.colorway.designCode, yarn.masterLibraryCode, 30),
        libraries: [],
        isHidden,
        isLocked: Config.studio.allowLockedRecoloring && yarn.isLocked,
        libraryIds: getLibraryIds(yarn),
        isMuted,
        isHighLuster: yarn.properties.isHighLustre
      };
    };

    return params.colorway.yarns.map((x, idx) => {
      if (x.yarnId < 10) {
        return x.children.map((y, subIdx) => getEditable(subIdx, y, false, params.mutedIndices.some(m => m === idx)));
      } else {
        return getEditable(idx, x, params.colorway.paletteLinks.some(p => p.link === idx + 1), params.mutedIndices.some(m => m === idx));
      }
    });
  }
);

const paletteLinksSelector = createSelector(
  paramsSelector,
  (params): PaletteLink[] => {
    if (!params || !params.colorway) {
      return [];
    }

    return (params.colorway.paletteLinks || []).map<PaletteLink>(p => ({
      position: p.position,
      link: p.link
    }));
  }
);

const libraryItemsSelector = createSelector(
  yarnLibrariesByBrandSelector,
  (libs): LibrarySpriteItem[] => {
    return mapLibrariesToItems(libs);
  }
);

const searchYarnsSelector = createSelector(
  yarnLibrariesByBrandSelector,
  (libs): YarnItemDisplay[] => {
    return mapLibrariesToSearchYarns(libs);
  }
);

const recolorYarnsSelector = createSelector(
  loadingCustomColorwaySelector,
  paramsSelector,
  editableYarnsSelector,
  paletteLinksSelector,
  searchYarnsSelector,
  libraryItemsSelector,
  (status, params, yarns, paletteLinks, searchYarns, libraries): RecolorYarns => {
    return {
      libraries,
      searchYarns,
      yarns,
      yarnUsages: params.colorway && params.colorway.yarnUsages || [],
      paletteLinks,
      loading: status.loading,
      designCode: params.colorway && params.colorway.designCode || '',
      refColorCode: params.colorway && params.colorway.colorCode || '',
      allowCustomTwists: Config.studio.allowCustomTwists && (
        params.colorway && params.colorway.engineId !== Enums.Engines.Id.Print
      ),
      useLockedRecoloring: Config.studio.allowLockedRecoloring && (
        params.colorway && params.colorway.enableLockedRecoloring
      ),
      viewMode: params.viewMode,
      roomId: params.room && params.room.roomId,
      installId: params.install && params.install.installId,
      textureId: params.texture && params.texture.textureId || 0,
      customRug: params.customRug,
      rotation: params.rotation,
      position: params.position
    };
  }
);

const recolorConfigSelector = createSelector(
  recolorableStatusSelector,
  recolorModeSelector,
  recolorRgbsSelector,
  recolorYarnsSelector,
  (enabled, mode, rgb, yarns): RecolorConfig => ({
    enabled,
    mode,
    rgb,
    yarns,
    tabName: rgb.loading || rgb.rgbs.length > 0 ? 'Canvas' : 'Colors'
  })
);

export default recolorConfigSelector;
