import { createSelector } from 'reselect';

import _isEmpty from 'lodash-es/isEmpty';

import RootState from 'Store/Root';

import { LoadingStatus } from 'Types/Common';
import { QueryArgs } from 'Types/Rugs';

const designsLoadingSelector = (state: RootState, query: QueryArgs): LoadingStatus => {
  const failed = query.designs
    .map(x => !!state.designs.errorsByCode[x.toUpperCase()])
    .some(x => x);

  const loading = !failed && query.designs
    .map(x => !!state.designs.loadingByCode[x.toUpperCase()])
    .some(x => x);

  const loaded = !failed && !loading && query.designs
    .map(x => !!state.designs.itemsByCode[x.toUpperCase()])
    .every(x => x);

  return {
    loading,
    loaded,
    failed
  };
};

const colorwaysLoadingSelector = (state: RootState, query: QueryArgs): LoadingStatus => {
  const stores = query.designs.map(x => state.colorways[x.toUpperCase()]);
  const status = stores.map(
    (x, idx): LoadingStatus => {
      if (!x) {
        return {
          loading: false,
          loaded: false,
          failed: false
        };
      }

      if (!_isEmpty(query.positions[idx])) {
        const custom = x.customs[
          query.positions[idx].map(y => query.palette[y])
            .join(',')
            .toUpperCase()
        ];

        if (!custom) {
          return {
            loading: false,
            loaded: false,
            failed: false
          };
        }

        return {
          loading: custom.loading,
          loaded: custom.loaded,
          failed: custom.error
        };
      } else {
        return {
          loading: x.fixed.loading,
          loaded: x.fixed.loaded,
          failed: x.fixed.error
        };
      }
    }
  );

  const failed = status.some(x => x.failed);
  const loading = !failed && status.some(x => x.loading);
  const loaded = status.every(x => x.loaded);

  return {
    loading,
    loaded,
    failed
  };
};

export const loadingSelector = createSelector(
  designsLoadingSelector,
  colorwaysLoadingSelector,
  (designStatus: LoadingStatus, colorwayStatus: LoadingStatus): LoadingStatus => {
    const loaded = designStatus.loaded && colorwayStatus.loaded;
    const loading = !loaded && (designStatus.loading || colorwayStatus.loading);
    const failed = designStatus.failed || colorwayStatus.failed;

    return {
      loaded,
      loading,
      failed
    };
  }
);
