import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import { UserAction } from './index';

export const LOADING_TRYK = 'LOADING_ACCOUNT_TRYK';
export const LOADED_TRYK = 'LOADED_ACCOUNT_TRYK';
export const LOAD_TRYK_FAILED = 'LOAD_ACCOUNT_TRYK_FAILED';

type TrykOverview = TrykApi.Catalog.OrderHistory.ITrykOverview;
type Design = TrykApi.Catalog.OrderHistory.IDesign;
type Destinations = TrykApi.Catalog.OrderHistory.ITrykDestinationDetail;

export function loadTryk(trykId: number): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(loadingTryk(trykId));

    return Promise.all([
      CatalogClient.OrderHistory.getTrykById(trykId),
      CatalogClient.OrderHistory.getDesignsInTryk(trykId),
      CatalogClient.OrderHistory.getDestinationsInTryk(trykId)
    ]).then(
      x => dispatch(loadedTryk(x[0], x[1], x[2])),
      e => dispatch(loadTrykFailed(trykId))
    );
  };
}

function loadingTryk(trykId: number): UserAction {
  return {
    type: LOADING_TRYK,
    trykDetail: {
      trykId: trykId
    }
  };
}

function loadTrykFailed(trykId: number): UserAction {
  return {
    type: LOAD_TRYK_FAILED,
    trykDetail: {
      trykId: trykId
    }
  };
}

function loadedTryk(tryk: TrykOverview, designs: Design[], destinations: Destinations) {
  return {
    type: LOADED_TRYK,
    trykDetail: {
      trykId: tryk.trykId,
      tryk: tryk,
      designs: designs,
      destinations: destinations
    }
  };
}
