import { createSelector } from "reselect";

import { Urls as ImageUrls } from "Clients/Images";
import { Urls as StudioUrls } from "Clients/Studio";

import * as Enums from "Clients/Catalog/Enums";

import RootState from "Store/Root";

import { ThumbnailOptionItem } from "Types/Common";
import { Params } from "Types/Designs";

import paramsSelector from "../Params";

const getTextureUrl = (texture: TrykApi.Catalog.ITexture, params: Params) => {
  if (!params.colorway || !params.install) {
    return "#";
  }

  if (params.clutColorwayId) {
    return StudioUrls.colorway(params.colorway, {
      textureId: !texture.isDefault && texture.textureId,
      installId: params.install.installId,
      roomId: params.room && params.room.roomId,
      viewMode: params.viewMode,
      clutColorwayId: params.clutColorwayId,
      customRug: params.customRug,
      position: params.position
    });
  }

  return StudioUrls.colorway(params.colorway, {
    textureId: !texture.isDefault && texture.textureId,
    installId: params.install.installId,
    roomId: params.room && params.room.roomId,
    viewMode: params.viewMode,
    customRug: params.customRug,
    position: params.position
  });
};

const mapTextureToOptionItem = (
  texture: TrykApi.Catalog.ITexture,
  params: Params
): ThumbnailOptionItem => {
  return {
    id: texture.textureId,
    label: texture.name,
    imageUrl: ImageUrls.Thumbnail.texture(texture.textureId, 100),
    isActive:
      params &&
      params.texture &&
      params.texture.textureId === texture.textureId,
    href: getTextureUrl(texture, params)
  };
};

const textureOptionsSelector = createSelector(
  (state: RootState) => state.textures.items,
  paramsSelector,
  (textures, params) => {
    if (
      !params ||
      !params.colorway ||
      params.colorway.engineId !== Enums.Engines.Id.Print
    ) {
      return [];
    }

    return textures.map(x => mapTextureToOptionItem(x, params));
  }
);

export default textureOptionsSelector;
