interface RecentlyUsedFoldersState {
  items: TrykApi.Catalog.Favorites.IFolder[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

export default RecentlyUsedFoldersState;

export const initialState: RecentlyUsedFoldersState = {
  items: [],
  loading: false,
  loaded: false,
  failed: false,
};
