import { ThunkAction } from 'redux-thunk';
import URI from 'urijs';

import { Favorites as FavoritesClient } from 'Clients/Catalog';
import { Urls as StudioUrls } from 'Clients/Studio';
import Config from 'Config';

import RootState from 'Store/Root';

import { clearComments } from 'Store/ProjectItemComments/Actions';
import { clearProjectItems } from 'Store/ProjectItems/Actions';
import { actions as paginatedActions } from 'Store/ProjectItems/Paginator';

export const LOADING_PROJECTS = 'LOADING_PROJECTS';
export const LOADED_PROJECTS = 'LOADED_PROJECTS';
export const LOAD_PROJECTS_FAILED = 'LOAD_PROJECTS_FAILED';

export const ADDING_PROJECT = 'ADDING_PROJECT';
export const ADDED_PROJECT = 'ADDED_PROJECT';
export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED';

export const DELETING_PROJECT = 'DELETING_PROJECT';
export const DELETED_PROJECT = 'DELETED_PROJECT';
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export const ADDING_PROJECT_USER = 'ADDING_PROJECT_USER';
export const ADDED_PROJECT_USER = 'ADDED_PROJECT_USER';
export const ADD_PROJECT_USER_FAILED = 'ADD_PROJECT_USER_FAILED';

export const DELETING_PROJECT_USER = 'DELETING_PROJECT_USER';
export const DELETED_PROJECT_USER = 'DELETED_PROJECT_USER';
export const DELETE_PROJECT_USER_FAILED = 'DELETE_PROJECT_USER_FAILED';

export const ADDING_FOLDER = 'ADDING_FOLDER';
export const ADDED_FOLDER = 'ADDED_FOLDER';
export const ADD_FOLDER_FAILED = 'ADD_FOLDER_FAILED';

export const DELETING_FOLDER = 'DELETING_FOLDER';
export const DELETED_FOLDER = 'DELETED_FOLDER';
export const DELETE_FOLDER_FAILED = 'DELETE_FOLDER_FAILED';

export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';

export const INCREMENT_ITEM_COUNT = 'PROJECTS_INCREMENT_ITEM_COUNT';
export const DECREMENT_ITEM_COUNT = 'PROJECTS_DECREMENT_ITEM_COUNT';

export interface ProjectsAction {
  type: string;
  projectId?: number;
  project?: TrykApi.Catalog.Favorites.IProject;
  projects?: TrykApi.Catalog.Favorites.IProject[];
  folderId?: number;
  folder?: TrykApi.Catalog.Favorites.IFolder;
}

export function loadProjects(reload?: boolean): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (!reload && state.projects.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loading());

    return FavoritesClient.getProjects()
      .then(
      x => dispatch(loaded(x)),
      e => dispatch(loadFailed())
      );
  };
}

export function addProject(request: TrykApi.Catalog.Favorites.IEditProject): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: ADDING_PROJECT
    });

    return FavoritesClient.createProject(request)
      .then(
      project => {
        dispatch<ProjectsAction>({
          type: ADDED_PROJECT,
          projectId: project.projectId,
          project
        });

        return project;
      },
      err => dispatch<ProjectsAction>({
        type: ADD_PROJECT_FAILED
      })
      );
  };
}

export function deleteProject(projectId: number): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: DELETING_PROJECT,
      projectId
    });

    return FavoritesClient.deleteProject(projectId)
      .then(
      project => {
        dispatch<ProjectsAction>({
          type: DELETED_PROJECT,
          projectId
        });

        return project;
      },
      err => dispatch<ProjectsAction>({
        type: DELETE_PROJECT_FAILED,
        projectId
      })
      );
  };
}

export function addProjectUser(projectId: number, email: string): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: ADDING_PROJECT_USER,
      projectId
    });

    const url = URI(StudioUrls.viewProject(projectId))
      .absoluteTo(Config.studioBaseUrl)
      .toString();

    return FavoritesClient.addProjectUser(projectId, email, url)
      .then(
      project => {
        dispatch<ProjectsAction>({
          type: ADDED_PROJECT_USER,
          projectId: project.projectId,
          project
        });

        return project;
      },
      err => dispatch<ProjectsAction>({
        type: ADD_PROJECT_USER_FAILED,
        projectId
      })
      );
  };
}

export function deleteProjectUser(projectId: number, userId: number): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: DELETING_PROJECT_USER,
      projectId,
    });

    return FavoritesClient.deleteProjectUser(projectId, userId)
      .then(
      project => {
        dispatch<ProjectsAction>({
          type: DELETED_PROJECT_USER,
          projectId: project.projectId,
          project
        });

        return project;
      },
      err => dispatch<ProjectsAction>({
        type: DELETE_PROJECT_USER_FAILED,
        projectId
      })
      );
  };
}

export function addFolder(projectId: number, request: TrykApi.Catalog.Favorites.IEditFolder): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: ADDING_FOLDER,
      projectId
    });

    return FavoritesClient.createFolder(projectId, request)
      .then(
      folder => {
        dispatch<ProjectsAction>({
          type: ADDED_FOLDER,
          projectId,
          folder,
          folderId: folder.folderId
        });

        return folder;
      },
      err => dispatch<ProjectsAction>({
        type: ADD_FOLDER_FAILED
      })
      );
  };
}

export function deleteFolder(folderId: number): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: DELETING_FOLDER,
      folderId
    });

    return FavoritesClient.deleteFolder(folderId)
      .then(
      folder => {
        dispatch<ProjectsAction>({
          type: DELETED_FOLDER,
          projectId: folder.projectId,
          folderId
        });

        return folder;
      },
      err => dispatch<ProjectsAction>({
        type: DELETE_FOLDER_FAILED,
        folderId
      })
      );
  };
}

export function clearProjects(): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    dispatch<ProjectsAction>({
      type: CLEAR_PROJECTS
    });

    dispatch(clearComments());
    dispatch(clearProjectItems());
    dispatch(paginatedActions.clear());

    return true;
  };
}

export function incrementFolderItemCount(folderId: number): ProjectsAction {
  return {
    type: INCREMENT_ITEM_COUNT,
    folderId
  };
}

export function decrementFolderItemCount(folderId: number): ProjectsAction {
  return {
    type: DECREMENT_ITEM_COUNT,
    folderId
  };
}

function loading(): ProjectsAction {
  return {
    type: LOADING_PROJECTS
  };
}

function loadFailed(): ProjectsAction {
  return {
    type: LOAD_PROJECTS_FAILED
  };
}

function loaded(projects: TrykApi.Catalog.Favorites.IProject[]): ProjectsAction {
  return {
    type: LOADED_PROJECTS,
    projects
  };
}
