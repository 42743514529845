import cloneDeep from 'lodash-es/cloneDeep';

import {
  LOGGING_OUT,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS
} from '../Actions/Logout';

import { UserAction } from '../Actions';

import UserState, { initialState as initialUser } from '../State';

export default function logout(state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case LOGGING_OUT:
      return {
        ...state,
        status: {
          ...state.status,
          isLoggingOut: true,
          didLogoutError: false
        }
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          isLoggingOut: false,
          didLogoutError: true
        }
      };
    case LOGOUT_SUCCESS:
      return cloneDeep(initialUser);
    default:
      return state;
  }
}
