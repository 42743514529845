import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';
import { clearShippingOptions } from 'Store/Basket/Actions';
import { clearProjects } from 'Store/Projects/Actions';

import { UserAction } from './index';

import { actions as orderHistoryActions } from '../OrderHistory/Paginator';

export const LOGGING_OUT = 'USER_LOGGING_OUT';
export const LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export default function logout(): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    dispatch(loggingOut());

    const request = CatalogClient.User.logout();

    request.then(
      x => {
        dispatch(orderHistoryActions.clear());
        dispatch(clearShippingOptions());
        dispatch(clearProjects());
        dispatch(logoutSuccess());
      },
      e => dispatch(logoutFailed())
    );

    return request;
  };
}

function loggingOut(): UserAction {
  return {
    type: LOGGING_OUT
  };
}

function logoutFailed(): UserAction {
  return {
    type: LOGOUT_FAILED
  };
}

function logoutSuccess(): UserAction {
  return {
    type: LOGOUT_SUCCESS
  };
}
