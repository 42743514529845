import _has from "lodash-es/has";
import _uniqBy from "lodash-es/uniqBy";

import {InstallTemplatesState} from "./Reducer";

export function getInstallsByBrand(
  state: InstallTemplatesState,
  brandId: number
): TrykApi.Catalog.IInstallTemplate[] {
  const brandKey = brandId.toString();
  if (!_has(state.brandMap, brandKey)) {
    return [];
  }

  const brandInstalls = state.brandMap[brandKey].map(
    x => state.itemsById[x.toString()]
  );

  return brandInstalls;
}

export function getInstallSizesByBrand(
  state: InstallTemplatesState,
  brandId: number
): TrykApi.Catalog.ISoftSurfaceTileSize[] {
  const installSizes = state.tileSizesByBrand[brandId];

  return installSizes;
}

export function mapAsDesignInstalls(
  templates: TrykApi.Catalog.IInstallTemplate[],
  defaultInstall: TrykApi.Catalog.IDesignInstall
): TrykApi.Catalog.IDesignInstall[] {
  const result = templates.map<TrykApi.Catalog.IDesignInstall>((t, idx) => ({
    installId: t.installId,
    isDefault: defaultInstall
      ? t.installId === defaultInstall.installId
      : idx === 0,
    displayTileSize: t.displayTileSize,
    name: t.name,
    tileSizes: mapAsTileSizes(t.tiles)
  }));

  return result;
}

function mapAsTileSizes(
  tiles: TrykApi.Catalog.IInstallTemplateTile[]
): TrykApi.Catalog.ITileSize[] {
  return _uniqBy(
    tiles.map<TrykApi.Catalog.ITileSize>(t => ({
      widthInches: t.tileWidthInches,
      heightInches: t.tileHeightInches
    })),
    x => `${x.widthInches},${x.heightInches}`
  );
}
