import { ThunkAction } from 'redux-thunk';

import { Favorites as FavoritesClient } from 'Clients/Catalog';

import RootState from 'Store/Root';

import { ProjectItemsAction } from './Action';

export const LOADING_PROJECT_ITEM = 'LOADING_PROJECT_ITEM';
export const LOADED_PROJECT_ITEM = 'LOADED_PROJECT_ITEM';
export const LOAD_PROJECT_ITEM_FAILED = 'LOAD_PROJECT_ITEM_FAILED';

export function loadProjectItem(itemId: number, reload: boolean = false): ThunkAction<Promise<any>, RootState, ProjectItemsAction> {
  return (dispatch, getState) => {
    const state = getState();

    if (!reload && state.projectItems.itemsById[itemId]) {
      return Promise.resolve(state.projectItems.itemsById[itemId]);
    }

    dispatch({
      type: LOADING_PROJECT_ITEM,
      itemId
    });

    return FavoritesClient.getItem(itemId)
      .then(
      item => {
        dispatch({
          type: LOADED_PROJECT_ITEM,
          itemId,
          item
        });

        return item;
      },
      err => dispatch({
        type: LOAD_PROJECT_ITEM_FAILED,
        itemId
      })
      );
  };
}
