import _isEmpty from 'lodash-es/isEmpty';

import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';
import preloadAction from 'Store/Preload/Actions';
import {
  loadCustomColorway,
  loadFixedColorways
} from 'Store/Colorways/Actions';
import { QueryArgs } from 'Types/Designs';
import { serializeRgbs } from 'Utils/Rgb';

import {
  DesignData,
  DesignAction
} from './Action';

export const LOADING_DESIGN = 'LOADING_DESIGN';
export const LOADED_DESIGN = 'LOADED_DESIGN';
export const LOAD_DESIGN_FAILED = 'LOAD_DESIGN_FAILED';

export function loadDesign(designCode: string, force: boolean = false): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();
    const { itemsByCode, loadingByCode } = state.designs;

    // Normalize design code to store key format.
    const key = designCode.toUpperCase();

    if (itemsByCode[key]) {
      return Promise.resolve({
        overview: itemsByCode[key].overview,
        installs: itemsByCode[key].installs
      });
    } else if (!force && loadingByCode[key]) {
      return Promise.reject(
        new Error(`Design ${designCode} is already loading.`)
      );
    }

    dispatch(loadingDesign(key));

    return Promise.all([
      CatalogClient.Product.getDesign(designCode),
      CatalogClient.Product.getInstalls(designCode),
      dispatch(loadFixedColorways(designCode)),
      dispatch(preloadAction())
    ]).then(
      x => {
        const data: DesignData = {
          overview: x[0],
          installs: x[1]
        };

        dispatch(designLoaded(key, data));

        return data;
      },
      err => dispatch(loadDesignFailed(key))
    );
  };
}

export function loadFromQuery(args: QueryArgs, force: boolean = false): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const promises: Promise<any>[] = [
      dispatch(loadDesign(args.designCode, force))
    ];

    if (!_isEmpty(args.yarnCodes) || !_isEmpty(args.rgbs) || !_isEmpty(args.clutColorwayId)) {
      promises.push(
        dispatch(loadCustomColorway({
          designCode: args.designCode,
          yarns: args.yarnCodes.join(','),
          refColor: args.refColorCode,
          refLibs: args.refLibs,
          rgbs: serializeRgbs(args.rgbs),
          clutColorwayId: args.clutColorwayId
        }, force))
      );
    }

    return Promise.all(promises);
  };
}

function loadingDesign(designCode: string): DesignAction {
  return {
    type: LOADING_DESIGN,
    designCode: designCode
  };
}

function loadDesignFailed(designCode: string): DesignAction {
  return {
    type: LOAD_DESIGN_FAILED,
    designCode: designCode
  };
}

function designLoaded(designCode: string, data: DesignData): DesignAction {
  return {
    type: LOADED_DESIGN,
    designCode: designCode,
    data: data
  };
}
