import { createSelector } from 'reselect';

import { LinkItem } from 'Types/Common';

import {
  view3dRotatedUrlSelector
} from './Urls';

import paramsSelector from './Params';
import { overviewSelector } from './PatternInfo';
import viewModeSelector from './ViewMode';

const rotationToggleSelector = createSelector(
  paramsSelector,
  overviewSelector,
  viewModeSelector,
  view3dRotatedUrlSelector,
  (params, overview, viewMode, view3dRotatedUrl): LinkItem => {
    if (!params.room || viewMode !== '3d') {
      return null;
    } else if ((!overview || !overview.sectionIds.some(x => x === 7)) && !params.customRug) {
      return null;
    }

    return {
      label: 'Rotate 90°',
      href: view3dRotatedUrl
    };
  }
);

export default rotationToggleSelector;
