import {
  LOADED_NEW_DESIGNS,
  LOADING_NEW_DESIGNS,
  LOAD_NEW_DESIGNS_FAILED,
  NewDesignsAction
} from './Actions';

export interface NewDesignsState {
  items: { [id: number]: TrykApi.Catalog.ISearchResult };
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

const initialState: NewDesignsState = {
  items: {},
  loading: false,
  loaded: false,
  failed: false
};

export default function newDesignsReducer(state = initialState, action: NewDesignsAction): NewDesignsState {
  switch (action.type) {
    case LOADED_NEW_DESIGNS:
      return {
        items: action.items,
        loaded: true,
        loading: false,
        failed: false
      };
    case LOADING_NEW_DESIGNS:
      return {
        items: {},
        loading: true,
        loaded: false,
        failed: false
      };
    case LOAD_NEW_DESIGNS_FAILED:
      return {
        items: {},
        loading: false,
        loaded: false,
        failed: true
      };
    default:
      return state;
  }
}
