import { connect } from 'react-redux';

import RootState from 'Store/Root';

import {
  ExposedProps,
  MapStateToProps
} from './Props';

import SentryContextManager from './ContextManager';

const mapStateToProps = (state: RootState, ownProps: ExposedProps): MapStateToProps => ({
  account: state.user.account.data
});

export default connect(mapStateToProps)(
  SentryContextManager
);
