import {ThunkAction} from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';

import RootState from 'Store/Root';

import {
  getColorwayMapKey,
  getCustomColorwayMapKey
} from './Reducer/Utils';

export const LOADING_FIXED_COLORWAYS = 'LOADING_FIXED_COLORWAYS';
export const LOADED_FIXED_COLORWAYS = 'LOADED_FIXED_COLORWAYS';
export const LOAD_FIXED_COLORWAYS_FAILED = 'LOAD_FIXED_COLORWAYS_FAILED';

export const LOADING_CUSTOM_COLORWAY = 'LOADING_CUSTOM_COLORWAY';
export const LOADED_CUSTOM_COLORWAY = 'LOADED_CUSTOM_COLORWAY';
export const LOAD_CUSTOM_COLORWAY_FAILED = 'LOAD_CUSTOM_COLORWAY_FAILED';


export type CustomColorwayArgs = {
  designCode: string;
  yarns: string;
  rgbs: string;
  refColor?: string;
  refLibs?: string;
  clutColorwayId?: string;
};


export type FixedColorwayData = {
  items: TrykApi.Catalog.IColorway[],
  palettes: {
    [key: string]: TrykApi.Catalog.IRgb[]
  }
};

export type ColorwayAction = (
  & {
    type: string;
    item?: TrykApi.Catalog.IColorway;
    data?: FixedColorwayData;
  }
  & Partial<CustomColorwayArgs>
);

function loadingFixedColorways(designCode: string): ColorwayAction {
  return {
    type: LOADING_FIXED_COLORWAYS,
    designCode
  };
}

function loadFixedColorwaysFailed(designCode: string): ColorwayAction {
  return {
    type: LOAD_FIXED_COLORWAYS_FAILED,
    designCode
  };
}

function fixedColorwaysLoaded(designCode: string, data: FixedColorwayData): ColorwayAction {
  return {
    type: LOADED_FIXED_COLORWAYS,
    designCode,
    data
  };
}

export function loadFixedColorways(designCode: string): ThunkAction<Promise<any>, RootState, any> {
  return (dispatch, getState) => {
    const state = getState(),
      map = state.colorways[getColorwayMapKey(designCode)];

    if (map && map.fixed.loaded) {
      return Promise.resolve(true);
    }

    dispatch(loadingFixedColorways(designCode));

    return Promise.all([
      CatalogClient.Product.getColorways(designCode),
      CatalogClient.Product.getAllPalettes(designCode)
    ]).then(
      x => dispatch(fixedColorwaysLoaded(designCode, {items: x[0], palettes: x[1]})),
      e => dispatch(loadFixedColorwaysFailed(designCode))
    );
  };
}

function loadingCustomColorway(args: CustomColorwayArgs): ColorwayAction {
  return {
    type: LOADING_CUSTOM_COLORWAY,
    ...args
  };
}

function loadCustomColorwayFailed(args: CustomColorwayArgs): ColorwayAction {
  return {
    type: LOAD_CUSTOM_COLORWAY_FAILED,
    ...args
  };
}

function customColorwayLoaded(args: CustomColorwayArgs, item: TrykApi.Catalog.IColorway): ColorwayAction {
  return {
    type: LOADED_CUSTOM_COLORWAY,
    item: item,
    ...args
  };
}

export function loadCustomColorway(input: CustomColorwayArgs, force: boolean = false): ThunkAction<Promise<any>, RootState, any> {
  return async (dispatch, getState) => {
    const args = {
      ...input,
      designCode: input.designCode.toUpperCase()
    };

    const state = getState(),
      colorwayMap = state.colorways[getColorwayMapKey(args.designCode)],
      customState = colorwayMap && colorwayMap.customs[getCustomColorwayMapKey(args)];

    if (customState && customState.loaded) {
      return Promise.resolve(customState.item);
    } else if (!force && customState && customState.loading) {
      return Promise.reject(new Error(`Custom colorway already loading for design ${args.designCode}.`));
    }

    try {
      dispatch(loadingCustomColorway(args));
      const result = await CatalogClient.Product.getCustomColorway(args);
      dispatch(customColorwayLoaded(args, result));
      return result;
    } catch {
      dispatch(loadCustomColorwayFailed(args));
    }
  };
}

